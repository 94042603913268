export interface Option {
  id: string;
  value: string;
  label: string;
}

export enum MEMBER_FILTERS {
  CLUB_NAMES = 'clubs',
  DISTRICT_ROLES = 'districtRoles',
  MEMBER_TYPES = 'memberTypes',
  CLUB_IDS = 'clubIds',
}

export type Filter = {
  districtRoles: Option[];
  memberTypes: Option[];
  clubs: Option[];
};

export type MemberFilter = {
  orgId: string;
  name: string;
  email: string;
  filters: Filter;
};

export type DistrictRosterFilter = {
  orgId: string;
  name: string;
  email: string;
  filters: Filter;
};

export interface FilterProps<T, U> {
  applyFilter: (selectedOptions: Option[], name: T) => void;
  initialFilterValues: U;
  multiSelectOptions: U;
  resetFilter: (filterName?: string) => void;
}

export type MemberFilterProps = FilterProps<MEMBER_FILTERS, MemberFilter>;

export type SearchFormValues = {
  orgId: string;
  name: string;
  email: string;
};

export type MembershipStatusOption = {
  value: string;
  label: string;
  id: string;
};
