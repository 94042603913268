/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { useTranslation } from '@external/react-i18next';

interface Props {}

const DistrictConferenceHeader: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-wrap items-center flex-1 flex font-bold text-small">
        <div className="ml-1 mr-2 text-gray-400">
          {t('conferences.headers.rotary-year', 'Rotary Year')}
        </div>
        <div className="ml-2 flex-3 text-gray-400">
          {t('conferences.headers.dates', 'Dates')}
        </div>
        <div className="flex-3 text-gray-400 mr-1">
          {t('conferences.headers.location', 'Location')}
        </div>
        <div className="flex-2 text-gray-400 mr-2">
          {t('conferences.headers.president-rep', 'President Rep')}
        </div>
        <div className="flex-2 text-gray-400 ml-2">
          {t(
            'conferences.headers.participating-districts',
            'Participating Districts'
          )}
        </div>
        <div className="flex-3">&nbsp;</div>
      </div>
    </>
  );
};

export default DistrictConferenceHeader;
