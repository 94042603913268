import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import { useAdditionalParticipatingDistricts } from '@domui-hooks/conference/useAdditionalParticipatingDistricts';
import Conference from '@domui-utils/workflow/conference';

type ParticipatingDistrictsType = {
  id?: string | null;
  districtId: string;
  isPrimary: boolean;
  nfKey?: string | null;
};

// Custom hook to handle API calls
export const useCreateConference = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);
  const {
    selectParticipatingDistricts,
  } = useAdditionalParticipatingDistricts();

  const createConference = async (
    districtId: string,
    requestBody: DynamicData
  ) => {
    let status = false;
    setLoading(true);
    setError(null);
    try {
      if (districtId) {
        if (requestBody?.participatingDistricts?.length > 0) {
          const getParticipatingDistricts = requestBody?.participatingDistricts?.map(
            (district: ParticipatingDistrictsType) => ({
              id: '',
              districtId: district?.id,
              nfKey: '',
              isPrimary: false,
            })
          );

          const selectedDistricts = {
            participatingDistricts: getParticipatingDistricts,
          };

          const newRequestBody = { ...requestBody };
          delete newRequestBody?.participatingDistricts;

          const wfService = new Conference();
          const response = await wfService.createConference(
            districtId,
            newRequestBody
          );
          status = true;
          const conferenceId = response?.id;
          const participatingDistrictsPayload = {
            conferenceId,
            selectedDistricts,
          };
          await selectParticipatingDistricts(participatingDistrictsPayload);
          setLoading(false);
        } else {
          const newRequestBody = { ...requestBody };
          delete newRequestBody?.participatingDistricts;
          const wfService = new Conference();
          await wfService.createConference(districtId, newRequestBody);
          status = true;
          setLoading(false);
        }
      }
    } catch (error) {
      setError(error as DomuiError);
      status = false;
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return status;
  };

  return { loading, error, createConference };
};
