import React, {
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import PageSteps from '@components/PageSteps';
import IndividualDetails from '@domui-presenters/web/components/Forms/IndividualDetails';

import AssignDistrictExecutiveSecretary from '.';
import ConfirmDistrictExecSecretary from './ConfirmDistrictExecSecretary';
import ReplaceDistrictExecSecretary from './ReplaceDistrictExecSecretary';

import { mapMemberValuesToFormInput } from '@domain/clubs';
import {
  IdentificationParameters,
  MemberDetailsInput,
  MemberDetailsOutput,
} from '@domui-domain/clubs';
import { OperationType } from '@domui-domain/districts';
import { DISTRICT_EXECUTIVE_SECRETARY } from '@domui-domain/districts/constants';

import {
  buildOfficersPageUrl,
  DistrictContext,
  useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useErrorHandling } from '@use-cases/notifications';

import { useCreateIndividual } from 'src/domui/hooks/useCreateIndividual';

import { useAddMemberToClubInfo } from '@domui-hooks/useAddMemberToClubInfo';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { constructFullName } from '@utils/constructFullName';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
  isLoading: boolean;
}

const AddDistrictExecSecretary: React.FC<Props> = ({
  districtId: id,
  riDistrictId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [context] = useContext(DistrictContext);
  const { operationType } = context;

  enum AddOfficerSteps {
    INFO,
    IDENTIFY,
    ASSIGN_CONFIRMATION,
    REPLACE_CONFIRMATION,
  }

  const [step, setStep] = useState<AddOfficerSteps>(AddOfficerSteps.IDENTIFY);
  const isFirstStep = step === AddOfficerSteps.IDENTIFY;
  const roleName = DISTRICT_EXECUTIVE_SECRETARY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  const [selectedMember, selectMember] = useState<undefined | string | false>(
    undefined
  );

  const {
    data,
    loading,
    error,
    addMemberToClubInfo,
  } = useAddMemberToClubInfo();

  useEffect(() => {
    if (selectedMember) {
      addMemberToClubInfo(selectedMember);
    }
  }, [selectedMember]);

  useErrorHandling(error?.message, !!error, 'member-details.error');

  const fullName = constructFullName({
    prefix: data?.prefix,
    firstName: data?.firstName,
    middleName: data?.middleName,
    lastName: data?.lastName,
    suffix: data?.suffix,
  });

  const individual = {
    individual: {
      riIndividualId: data?.riIndividualId,
      name: fullName,
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
      email: data?.primaryEmail?.email,
      primaryPhone: data?.primaryPhone?.phone,
      primaryEmail: {
        type: data?.primaryEmail?.type,
        address: data?.primaryEmail?.email,
      },
      primaryAddress: {
        lineOne: data?.primaryAddress?.lineOne,
        type: data?.primaryAddress?.type,
        lineTwo: data?.primaryAddress?.lineTwo,
        lineThree: data?.primaryAddress?.lineThree,
        city: data?.primaryAddress?.city,
        country: data?.primaryAddress?.country,
        countryId: data?.primaryAddress?.countryId,
        state: data?.primaryAddress?.state,
        stateId: data?.primaryAddress?.stateId,
        postalCode: data?.primaryAddress?.postalCode,
      },
      primaryLanguage: data?.language,
      yearOfBirth: data?.dob ? new Date(data.dob).getFullYear() : undefined,
      dateOfBirth: data?.dob,
      gender: data?.gender,
      suffix: data?.suffix,
      prefix: data?.prefix,
      localizedName: data?.localizedName,
    },
    membershipList: [
      {
        type: data?.ActiveMembership?.type,
        terminationDate: data?.ActiveMembership?.terminationDate,
        clubId: data?.ActiveMembership?.clubId,
        clubType: data?.ActiveMembership?.clubType,
      },
    ],
  };

  const initialData = mapMemberValuesToFormInput(individual);

  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();

  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [individualId, setIndividualId] = useState<string>('');
  const [individualEmail, setIndividualEmail] = useState<string>('');
  const [individualName, setIndividualName] = useState<string>('');
  const [isNewIndividual, setFlagForNewIndividual] = useState<boolean>(false);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const {
    loading: createIndividualLoading,
    error: createIndividualError,
    createIndividual,
  } = useCreateIndividual();

  useErrorHandling(
    error?.message,
    !!createIndividualError,
    'create-invidividual.error'
  );

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
    setGlobal(false);
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (isLoading || loading || createIndividualLoading) {
    return <Loading />;
  }

  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-4 mb-0';

  const handleSubmit = async (membershipData: MemberDetailsOutput) => {
    let individualId = null;
    if (!selectedMember) {
      const result = await createIndividual(membershipData);

      individualId = String(result.data?.individualId);
      setIndividualId(individualId);

      if (result.data && operationType === OperationType.ASSIGN_TO_MEMBER) {
        setStep(AddOfficerSteps.ASSIGN_CONFIRMATION);
      } else if (
        result.data &&
        operationType === OperationType.REPLACE_OFFICER
      ) {
        setStep(AddOfficerSteps.REPLACE_CONFIRMATION);
      }
    }
  };

  const setExistingMemberData = (
    id: string,
    email: string | undefined,
    name: string | undefined
  ) => {
    setIndividualId(id);
    setIndividualEmail(email || '');
    setIndividualName(name || '');
  };

  switch (step) {
    case AddOfficerSteps.IDENTIFY:
      return (
        <PageSteps
          step={1}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <AssignDistrictExecutiveSecretary
            submitHandler={(
              id: string | false | undefined,
              email: string | undefined,
              name: string | undefined
            ) => {
              setMemberDataReviewed(null);
              selectMember(id);
              if (!id) {
                setFlagForNewIndividual(true);
                setStep(AddOfficerSteps.INFO);
              } else if (
                id &&
                operationType === OperationType.ASSIGN_TO_MEMBER
              ) {
                setExistingMemberData(id, email, name);
                setStep(AddOfficerSteps.ASSIGN_CONFIRMATION);
              } else if (
                id &&
                operationType === OperationType.REPLACE_OFFICER
              ) {
                setExistingMemberData(id, email, name);
                setStep(AddOfficerSteps.REPLACE_CONFIRMATION);
              }
            }}
            filters={filters}
            setFilters={setFilters}
            handleFormFieldsTouched={handleFormFieldsTouched}
            riDistrictId={id}
            roleName={roleName}
          >
            <BackButton />
          </AssignDistrictExecutiveSecretary>
        </PageSteps>
      );
    case AddOfficerSteps.INFO:
      return (
        <PageSteps
          step={2}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <IndividualDetails
            data={memberDataReviewed || initialData}
            initialData={initialData}
            roleName={roleName}
            submitHandler={(formData, dataToUpdate) => {
              setMemberDataReviewed(formData);
              handleSubmit(dataToUpdate);
            }}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </IndividualDetails>
        </PageSteps>
      );
    case AddOfficerSteps.ASSIGN_CONFIRMATION:
      return (
        <PageSteps
          step={isNewIndividual ? 3 : 2}
          total={isNewIndividual ? 3 : 2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <ConfirmDistrictExecSecretary
            districtId={id}
            riDistrictId={riDistrictId}
            officerId={individualId}
            officerEmail={memberDataReviewed?.email || individualEmail}
            officerName={memberDataReviewed?.localizedName || individualName}
          />
        </PageSteps>
      );
    case AddOfficerSteps.REPLACE_CONFIRMATION:
      return (
        <PageSteps
          step={isNewIndividual ? 3 : 2}
          total={isNewIndividual ? 3 : 2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <ReplaceDistrictExecSecretary
            districtId={id}
            riDistrictId={riDistrictId}
            officerId={individualId}
            officerEmail={memberDataReviewed?.email || individualEmail}
            officerName={memberDataReviewed?.localizedName || individualName}
          />
        </PageSteps>
      );
    default:
      return null;
  }
};

export default AddDistrictExecSecretary;
