import React, { useContext } from 'react';

import { ContextMenu } from '@components/ContextMenu';
import MemberRow from '@components/MemberRow';

import DistrictDetailsList from './DistrictDetailsList';

import {
  ASSISTANT_GOVERNOR,
  ASSISTANT_GOVERNOR_ROLE_ID,
  OperationType,
} from '@domui-domain/districts';

import { localizedNavigate } from '@utils/localized-navigate';

// import { CommitteeMemberData } from '@domui-domain/districts';
import { DynamicData } from '@domui-domain/type';
import {
  buildMembersPageUrl,
  defaultContextState,
  DistrictContext,
  filterDistrictLeadershipByRoles,
  // getActiveAssociatedClubs,
  // getActiveMemberships,
  getMembersContextMenuItems,
} from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import {
  // DistrictLeadership,
  Role,
} from '@typings/graphql';

interface Props {
  member: DynamicData;
  districtId: string;
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[];
  areRolesToAssignPresent: boolean;
  isAssignedInFutureRY?: boolean;
  haveMemberships?: { honorary: boolean; notHonorary: boolean };
  isRoleFilterApplied?: boolean;
  roleSelected?: string[];
}

const DistrictMemberItem: React.FC<Props> = ({
  isRoleFilterApplied,
  member,
  districtId,
  DORoles,
  areRolesToAssignPresent,
  isAssignedInFutureRY,
  haveMemberships,
  roleSelected,
}) => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(DistrictContext);

  const {
    committeeManager: { isManager },
    managedRYs: {
      current: currentYearAssignment,
      future: futureYearAssignment,
    },
    selectedInfo: { term },
    termsToAssign,
  } = context;

  const getAssignToAGStep = isManager.currentTerm && isManager.nextTerm ? 1 : 2;

  const getThisDistrictLeadership = member?.districtLeadership.filter(
    (leadership: { roleId: string }) =>
      DORoles.some(role => role.id === leadership.roleId)
  );

  const getUnassignFrom: DynamicData = {
    ...member,
    thisDistrictLeadership: getThisDistrictLeadership,
  };

  const handleClickMenu = (
    committeeAppointmentsURL: string,
    operationType: OperationType
  ) => {
    const updatedState: DynamicData = {
      operationType,
      selectedInfo: {
        ...defaultContextState.selectedInfo,
      },
    };

    if (operationType === OperationType.ASSIGN_TO_ROLE) {
      updatedState.selectedInfo.assignee = member;
    } else if (
      [
        OperationType.UNASSIGN_ROLE_FROM_OFFICER,
        OperationType.REPLACE_ROLE,
      ].includes(operationType)
    ) {
      updatedState.selectedInfo.unassignFrom = getUnassignFrom;
    }

    if (operationType === OperationType.REPLACE_ROLE) {
      const filteredDistrictLeadership = filterDistrictLeadershipByRoles(
        member?.districtLeadership
      );
      if (filteredDistrictLeadership.length === 1) {
        updatedState.selectedInfo.role = {
          id: filteredDistrictLeadership[0].roleId,
          name: filteredDistrictLeadership[0].role,
          termYears: filteredDistrictLeadership[0].termYears,
        };
      }
    }

    if (operationType === OperationType.ASSIGN_TO_AG) {
      updatedState.step = getAssignToAGStep;
      updatedState.selectedInfo.role = {
        name: ASSISTANT_GOVERNOR,
        id: ASSISTANT_GOVERNOR_ROLE_ID,
      };
      updatedState.selectedInfo.assignee = member;
    }

    setContext(prevState => ({
      ...prevState,
      step: updatedState.step || 1,
      operationType: updatedState.operationType,
      selectedInfo: {
        ...prevState.selectedInfo,
        ...updatedState.selectedInfo,
        term: prevState.termsToAssign[0],
      },
    }));

    localizedNavigate(committeeAppointmentsURL, {
      state: {
        backUrl: buildMembersPageUrl(districtId),
      },
    });
  };

  const contextMenu = getMembersContextMenuItems(
    t,
    isManager.nextTerm && !isManager.currentTerm,
    !isManager.nextTerm && !isManager.currentTerm,
    isManager.nextTerm && isManager.currentTerm,
    districtId,
    handleClickMenu,
    DORoles,
    areRolesToAssignPresent,
    currentYearAssignment,
    futureYearAssignment,
    member,
    getUnassignFrom,
    getAssignToAGStep,
    term || termsToAssign[0],
    isAssignedInFutureRY
  );

  const {
    // id,
    // email,
    // photoUri,
    // localizedName,
    nameWithPrefixSuffix,
    // phoneNumber,
    districtLeadership,
    // membership: activeMemberships,
    //  sharingPermissionsResult,
  } = member || {};

  // const activeAssociatedClubs = getActiveAssociatedClubs(
  //   getActiveMemberships(activeMemberships),
  //   activeMemberships
  // );

  const contextMenuItems = contextMenu();

  const isContextMenuItemsExist = !!contextMenuItems.length;

  return (
    <MemberRow
      id={member?.id}
      email={member?.email}
      fullName={member?.nameWithPrefixSuffix}
      localizedName={member?.localizedName || ''}
      // photoUri={photoUri || ''}
      phoneNumber={member?.phoneNumber}
      associatedClubsInfo={member?.associatedClubsInfo}
      clubAffiliation={
        <DistrictDetailsList
          thisDistrictLeadership={districtLeadership}
          isRoleFilterApplied={isRoleFilterApplied}
          roleSelected={roleSelected}
        />
      }
      actionButton={
        isContextMenuItemsExist && (
          <ContextMenu
            title={nameWithPrefixSuffix || ''}
            items={contextMenuItems}
          />
        )
      }
      actionButtonMobile={
        isContextMenuItemsExist && (
          <ContextMenu
            title={nameWithPrefixSuffix || ''}
            items={contextMenuItems}
            isRightIndent={false}
            optionsToggleButton
          />
        )
      }
      haveMemberships={haveMemberships}
      activeMemberships={member?.activeMemberships}
      profilePreviousLinkPath={buildMembersPageUrl(districtId)}
    />
  );
};

export default DistrictMemberItem;
