import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

type SelectedDistrictsArgs = {
  conferenceId: string;
  selectedDistricts: DynamicData;
};

class Conference extends Workflow {
  constructor() {
    super('conferencewf');
  }

  async getAllConferencesByDistrictPrimary(districtId: string) {
    this.setAction('allConferencesByDistrictPrimary');
    this.setData({
      orgId: districtId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch conferences by district info.');
    }
  }

  async additionalParticipatingDistricts(payload: SelectedDistrictsArgs) {
    const { conferenceId, selectedDistricts } = payload;
    this.setAction('additionalParticipatingDistricts');
    this.setData({
      id: conferenceId,
      putData: selectedDistricts,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async createConference(districtId: string, requestBody: DynamicData) {
    this.setAction('createConference');
    this.setData({
      id: districtId,
      putData: requestBody,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async viewConference(districtId: string) {
    this.setAction('viewConference');
    this.setData({
      conferenceId: districtId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateConference(conferenceId: string, requestBody: DynamicData) {
    this.setAction('updateConference');
    this.setData({
      conferenceId,
      putData: requestBody,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async deleteConference(conferenceId: string) {
    this.setAction('deleteConference');
    this.setData({
      conferenceId,
    });
    try {
      const res = await this.deleteCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateAdditionalParticipatingDistricts(payload: SelectedDistrictsArgs) {
    const { conferenceId, selectedDistricts } = payload;
    this.setAction('updateAdditionalParticipatingDistricts');
    this.setData({
      conferenceId,
      putData: selectedDistricts,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchAdditionalParticipatingDistricts(conferenceId: string) {
    this.setAction('fetchAdditionalParticipatingDistricts');
    this.setData({
      conferenceId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default Conference;
