/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import Clubs from './Clubs';
import ContactsView from './Contacts/ContactsView';
import Members from './Members';
import Officers from './Officers';

import { useErrorHandling } from '@use-cases/notifications';

import { getDistrictDetailsPath } from '@utils/districts';

import {
  CLUBS_TYPES,
  MEMBERSHIP_STATUSES,
  OFFICERS_ROLES,
} from '@domui-domain/districts/constants';
import { DynamicData } from '@domui-domain/type';
import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchClubsStatistics } from '@domui-hooks/useFetchClubsStatistics';
import { useFetchDistrictOfficers } from '@domui-hooks/useFetchDistrictOfficers';
import { usePeopleGraphDetails } from '@domui-hooks/usePeopleGraphDetails';
import Metadata from '@domui-utils/workflow/metadata';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
  hasPermissionsToEditContactInfo?: boolean;
  districtData: DynamicData;
  districtDataLoading: boolean;
}

const DistrictDetails: React.FC<Props> = ({
  districtId,
  riDistrictId,
  hasPermissionsToEditContactInfo = false,
  districtData,
  districtDataLoading,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const { user } = useAppConfig();
  const dominoDistrictId = districtData?.id;

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);
  const {
    fetchClubsStatistics,
    data,
    loading: clubsLoading,
  } = useFetchClubsStatistics();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: peopleGraphDetailsData,
    loading: peopleGraphDetailsLoading,
    error: peopleGraphDetailsError,
    peopleGraphDetails,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } = usePeopleGraphDetails();
  useErrorHandling(
    t('people-graph.error', 'Unable to load people graph details'),
    !!peopleGraphDetailsError,
    'people-graph.error'
  );
  const {
    data: districtOfficersData,
    loading: districtOfficersLoading,
    error: districtOfficersError,
    fetchDistrictOfficers,
  } = useFetchDistrictOfficers();

  const getRotaryYear = () => {
    return (moment().format('MM-DD') < '07-01'
      ? moment().year()
      : moment().year() + 1
    ).toString();
  };

  const peopleGraphPayload = {
    rotaryYear: getRotaryYear(),
    membershipTypes: MEMBERSHIP_STATUSES,
    riDistrictId,
  };
  const districtOfficersPayload = {
    page: 1,
    pageSize: 100,
    roles: OFFICERS_ROLES,
    rotaryYear: getRotaryYear(),
    riDistrictId,
  };

  useEffect(() => {
    if (riDistrictId && user?.isLoggedIn && dominoDistrictId) {
      fetchDistrictOfficers(dominoDistrictId, districtOfficersPayload);
    }
  }, [riDistrictId, user?.isLoggedIn]);

  useEffect(() => {
    if (riDistrictId && user?.isLoggedIn && dominoDistrictId) {
      peopleGraphDetails(dominoDistrictId, peopleGraphPayload);
    }
  }, [riDistrictId, user?.isLoggedIn, dominoDistrictId]);

  useErrorHandling(
    t('district-officers.error', 'Unable to fetch district officer details'),
    !!districtOfficersError,
    'district-officers.error'
  );

  //   {
  //   "page":1,
  //   "pageSize": 10,

  //   "roles": [
  //   "District Governor (DG)",
  //       "Special Representative",
  //       "District Governor-elect (DGE)",
  //       "District Governor-nominee (DGN)",
  //       "District Executive Secretary"
  // ],
  //   "rotaryYear": "2025",
  //   "riDistrictId": 0
  // }

  useEffect(() => {
    if (user?.isLoggedIn && districtId) {
      fetchClubsStatistics(districtId, {
        startDate: '',
        endDate: '',
        rotaryYear: getRotaryYear(),
        status: 'Active',
        clubTypes: CLUBS_TYPES,
      });
    }
  }, [districtId, user?.isLoggedIn]);

  if (
    districtDataLoading ||
    !peopleGraphDetailsData ||
    districtOfficersLoading ||
    !districtOfficersData
  ) {
    return <Loading />;
  }
  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-details',
          '{{prefix}} District details {{suffix}}',
          { prefix, suffix }
        )}
      />
      {districtDataLoading || clubsLoading || peopleGraphDetailsLoading ? (
        <Loading />
      ) : (
        <div className="pt-4 tablet:flex">
          <div className="desktop:flex-2">
            <ContactsView
              districtDetails={districtData}
              hasPermissionsToEditContactInfo={hasPermissionsToEditContactInfo}
              districtId={districtId}
            />
            <Clubs clubsStatistics={data || []} districtId={districtId} />
          </div>
          <div className="desktop:flex-1">
            <Members
              membersStatistics={
                peopleGraphDetailsData?.peopleGraphDetails || []
              }
              districtId={districtId}
            />
            <Officers
              officers={districtOfficersData?.districtOfficersDetails || []}
              districtId={districtId}
              profilePreviousLinkPath={getDistrictDetailsPath(districtId)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DistrictDetails;
