import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

// Custom hook to handle API calls
export const DistrictCommitteeAppointments = () => {
  const [data, setData] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);

  const districtCommitteeAppointments = async (
    districtId: string,
    payloadData: DynamicData
  ) => {
    try {
      if (districtId) {
        setLoading(true);
        setError(null);
        const wfService = new Organization();
        const data = await wfService.DistrictCommitteeAppointments(
          districtId,
          payloadData
        );
        const fetchData = {
          totalCount: 0,
          districtCommitteeAppointments: [],
        };
        const response = data?.status === 404 ? fetchData : data;
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, districtCommitteeAppointments };
};
