import { useEffect, useMemo } from 'react';

import { ASSISTANT_GOVERNOR, IsManager } from '@domui-domain/districts';

import { useDistrictMembersSearch } from 'src/domui/hooks/useDistrictMembersSearch';

import { Maybe } from '@typings/operations';

export const useFetchIsAgInfoByEmail = (
  email: Maybe<string> | undefined,
  districtId: string,
  isManager: IsManager
) => {
  const {
    data: currentYearData,
    loading: currentYearLoading,
    districtMembersSearch: fetchCurrentYearMembers,
  } = useDistrictMembersSearch();

  const {
    data: nextYearData,
    loading: nextYearLoading,
    districtMembersSearch: fetchNextYearMembers,
  } = useDistrictMembersSearch();

  const getRequestPayload = () => ({
    orgId: districtId,
    email,
    filters: {
      districtRoles: [],
      memberTypes: [],
      clubs: [],
    },
    rolesToExclude: [ASSISTANT_GOVERNOR],
  });

  const isAssignedToAg = (data?: any) => data?.totalCount === 0;

  useEffect(() => {
    if (email) {
      if (isManager.nowTerm) {
        const payload = getRequestPayload();
        fetchCurrentYearMembers(1, 10, payload);
      }

      if (isManager.afterNowTerm) {
        const payload = getRequestPayload();
        fetchNextYearMembers(1, 10, payload);
      }
    }
  }, [email, isManager, districtId]);

  useEffect(() => {}, [currentYearData, nextYearData]);

  return useMemo(() => {
    const result = {
      currentYearAssigned: isAssignedToAg(currentYearData),
      nextYearAssigned: isAssignedToAg(nextYearData),
      loading: currentYearLoading || nextYearLoading,
    };
    return result;
  }, [currentYearData, nextYearData, currentYearLoading, nextYearLoading]);
};
