import { useEffect } from 'react';

import { useFetchDistrictOfficers } from '@domui-hooks/useFetchDistrictOfficers';
import { DynamicData } from 'src/domui/domain/type';

export const useFetchDistrictOfficersData = (
  districtId: string,
  riDistrictId?: number | null,
  rotaryYear?: string
) => {
  const {
    data: districtOfficersData,
    loading: districtOfficersLoading,
    error: districtOfficersError,
    fetchDistrictOfficers,
  } = useFetchDistrictOfficers();
  const districtOfficersPayload = {
    page: 1,
    pageSize: 100,
    rotaryYear,
    riDistrictId,
  };

  useEffect(() => {
    if (districtId && rotaryYear && riDistrictId) {
      fetchDistrictOfficers(districtId, districtOfficersPayload);
    }
  }, [districtId, rotaryYear, riDistrictId]);

  return {
    officersData:
      (districtOfficersData?.districtOfficersDetails as DynamicData) || [],
    officersLoading: districtOfficersLoading,
    districtOfficersError,
  };
};
