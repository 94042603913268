import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Conference from '@domui-utils/workflow/conference';

type ParticipatingDistrictsArgs = {
  conferenceId: string;
  selectedDistricts: DynamicData;
};

// Custom hook to handle API calls
export const useAdditionalParticipatingDistricts = () => {
  const [data] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const selectParticipatingDistricts = async (
    payload: ParticipatingDistrictsArgs
  ) => {
    try {
      setLoading(true);
      setError(null);
      const wfService = new Conference();
      await wfService.additionalParticipatingDistricts(payload);
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, selectParticipatingDistricts };
};
