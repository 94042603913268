// useFetchAccessLevelData.ts
import { useState } from 'react';

import {
  createPayload,
  fetchAccessLevels,
} from '../helpers/DistrictMembersAccessHelper';

import { DomuiError, DynamicData } from '@domui-domain/type';

export const useFetchDistrictMembersAccess = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DynamicData>(null);
  const [error, setError] = useState<DomuiError>(null);

  const fetchAccessLevelsData = async (
    requestorId: string,
    dominoDistrictId: string
  ) => {
    setLoading(true);
    setError(null); // Clear error before new request
    const payload = createPayload(dominoDistrictId);
    try {
      const combinedData = await fetchAccessLevels(
        requestorId,

        payload
      );
      setData(combinedData);
      return combinedData; // Return the combined data
    } catch (err) {
      setError(err as DomuiError);
      throw err; // Re-throw the error to handle it in the calling function
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    fetchAccessLevels: fetchAccessLevelsData,
  };
};
