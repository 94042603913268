import React, { useEffect } from 'react';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import ActionButtons from '../components/ActionButtons';
import ActionButtonsMobile from '../components/ActionButtonsMobile';
import DistrictConferenceSection from '../components/DistrictConferenceSection';

import { useNotifications } from '@use-cases/notifications';

import { mapConferencesAccessLevel } from '@domui-domain/districts';
import { GetAccessLevelsQuery } from '@domui-domain/districts/mappers/types';
import { RecordList } from '@domui-domain/type';
import { useFetchAllConferencesByDistrictPrimary } from '@domui-hooks/conference/useAllConferencesByDistrictPrimary';
import { useDeleteConference } from '@domui-hooks/conference/useDeleteConference';
import { useFetchConferenceList } from '@domui-hooks/iam/useFetchConferenceList';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import {
  getSplitConferences,
  isCreateConferenceBtn,
} from '@domui-use-cases/districts/conferences/index';
import { GetConferencesResults } from '@domui-use-cases/districts/conferences/type';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props {
  districtId: string;
  hasPermissionToConference: boolean;
  accessLevelData: GetAccessLevelsQuery;
}

const DistrictConferences: React.FC<Props> = ({
  districtId,
  hasPermissionToConference,
  accessLevelData: districtAccessLevelByYears,
}) => {
  const { addError, addSuccess } = useNotifications();
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;

  const { deleteConference } = useDeleteConference();
  const {
    data: conferenceData,
    loading: conferenceLoading,
    fetchAllConferencesByDistrictPrimary,
  } = useFetchAllConferencesByDistrictPrimary();

  const {
    data: accessLevelDataForConferences,
    loading: accessLvlLoading,
    fetchConferencePermission,
  } = useFetchConferenceList();

  const payload = {
    data: [
      {
        target: `/conferences?associatedDistrictId=${districtId}`,
        arguments: {
          type: 'DistrictConferences',
        },
      },
    ],
  };

  useEffect(() => {
    fetchConferencePermission(requestorId, payload);
  }, [requestorId]);

  useEffect(() => {
    if (hasPermissionToConference) {
      fetchAllConferencesByDistrictPrimary(districtId);
    }
  }, [hasPermissionToConference]);

  if (!hasPermissionToConference) {
    return <NotFound default />;
  }

  if (conferenceLoading || accessLvlLoading || !conferenceData) {
    return <Loading />;
  }

  const conferences: RecordList = conferenceData || [];

  const { pastConferences, upcomingConferences } = getSplitConferences(
    conferences
  );

  const conferenceAccessLvls = mapConferencesAccessLevel(
    accessLevelDataForConferences
  );

  const isAddConferenceButton = isCreateConferenceBtn(
    conferences as GetConferencesResults,
    districtAccessLevelByYears
  );

  const fetchConferences = async (id: string) => {
    try {
      await deleteConference(id);
      fetchAllConferencesByDistrictPrimary(districtId);
      addSuccess(
        t('conferences.deletion-successful', 'Conference successfully deleted')
      );
    } catch (error) {
      addError(t('conferences.deletion-error', 'Could not delete conference'));
    }
  };

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-conferences',
          '{{prefix}} District conferences {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="conference-page">
        <DistrictConferenceSection
          districtId={districtId}
          conferences={upcomingConferences}
          title={t('conferences.upcoming-title', 'Upcoming Conferences')}
          isUpcomingConference
          conferenceAccessLvls={conferenceAccessLvls}
          // Create Conference button is displayed for current or future DG if there is no conferences in their managment year
          isAddConferenceShown={isAddConferenceButton}
          renderActionsButton={ActionButtons}
          renderActionsButtonMobile={ActionButtonsMobile}
          fetchAllConferences={(id: string) => fetchConferences(id)}
        />
        <DistrictConferenceSection
          districtId={districtId}
          conferenceAccessLvls={conferenceAccessLvls}
          conferences={pastConferences}
          title={t('conferences.past-title', 'Past Conferences')}
          renderActionsButton={ActionButtons}
          renderActionsButtonMobile={ActionButtonsMobile}
          fetchAllConferences={(id: string) => fetchConferences(id)}
        />
      </div>
    </>
  );
};

export default DistrictConferences;
