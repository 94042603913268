import React from 'react';

import { isUndefined } from 'lodash';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';

import { CREATE_CONFERENCE_INITIAL_VALUES } from '@domain/districts/conferences/constants';

import { findDefaultTimeZone } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { isEditLevel } from '@utils/access-helpers';
import { localizedNavigate } from '@utils/localized-navigate';

import { mapFormResultToCreateConferenceInput } from '@domui-domain/districts/mappers';
import { CreateConferenceFormResult } from '@domui-domain/districts/types';
import { useCreateConference } from '@domui-hooks/conference/useCreateConference';
import ConferenceForm from '@domui-presenters/web/components/ConferenceForm';
import { State } from '@domui-presenters/web/components/ConferenceForm/types';
import {
  useFetchCountryStates,
  useFetchPRCountryStates,
} from '@domui-repositories/dominoCountry';
import {
  getConferencePage,
  // getFutureManagementYear,
  getIsManagerByYear,
} from '@domui-use-cases/districts/conferences';
import { getFutureManagementYear } from '@domui-use-cases/districts/conferences/getManagementYear';
import { GetAccessLevelsQuery } from '@domui-use-cases/districts/conferences/type';
import { normalizeCountries } from '@domui-use-cases/districts/normalizers/normalizeCountries';

import { useTranslation } from '@external/react-i18next';

export type CountriesQuery = {
  id: string;
  shortCode: string;
  countryName: string;
  RequireState: boolean;
};

export type GetTimeZonesQuery = {
  id: string;
  value: string;
  timeZoneName: string;
  location: string;
  offset: string;
};

interface Props {
  districtId: string;
  initialValues?: CreateConferenceFormResult;
  accessLevelData?: GetAccessLevelsQuery;
  canCreateConference: boolean;
  countriesData?: CountriesQuery[];
  countriesLoading: boolean;
  timeZones?: GetTimeZonesQuery[];
  timeZonesLoading: boolean;
}

const DistrictCreateConference: React.FC<Props> = ({
  districtId,
  initialValues,
  accessLevelData,
  canCreateConference,
  countriesData,
  countriesLoading,
  timeZones,
  timeZonesLoading,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();

  const {
    loading: loadingStates,
    data: countryStatesData,
    fetchCountryStates,
  } = useFetchCountryStates();
  const {
    loading: loadingPRStates,
    data: countryPRStatesData,
    fetchPRCountryStates,
  } = useFetchPRCountryStates();

  const {
    loading: loadingCreateConference,
    error: createConferenceError,
    createConference,
  } = useCreateConference();

  useErrorHandling(
    createConferenceError?.description
      ? createConferenceError.description
      : t('create-conference.error-request', 'An error occurred.'),
    !!createConferenceError,
    'create-conference.error-request'
  );

  const isManagerInCurrentOrFutureTerm = accessLevelData?.data?.some(
    ({ level }) => isEditLevel(level)
  );

  if (loadingCreateConference || timeZonesLoading || countriesLoading) {
    return <Loading />;
  }

  if (
    !isUndefined(canCreateConference) &&
    !(isManagerInCurrentOrFutureTerm && canCreateConference)
  ) {
    return <NotFound default />;
  }

  const is2023YManager = getIsManagerByYear(accessLevelData, 2023);

  const formattedCountries = normalizeCountries(countriesData);
  const goBack = () => localizedNavigate(getConferencePage(districtId));

  const createConferenceSubmit = async (
    formData: CreateConferenceFormResult
  ) => {
    try {
      const requestBody = mapFormResultToCreateConferenceInput(
        formData,
        formattedCountries,
        timeZones,
        districtId
      );

      const status = await createConference(districtId, requestBody);
      if (status) {
        const goBack = () => localizedNavigate(getConferencePage(districtId));
        addSuccess(t('create-conference.success', 'Success.'), {
          id: 'form.success',
        });
        goBack();
      }
    } catch (error) {
      addError(
        t(
          'create-conference.error-request',
          'An error occurred with create conference form'
        )
      );
    }
  };

  const formValues = initialValues || {
    ...CREATE_CONFERENCE_INITIAL_VALUES,
    dateTime: {
      ...CREATE_CONFERENCE_INITIAL_VALUES.dateTime,
      timeZone: findDefaultTimeZone(timeZones || []),
    },
  };

  return (
    <ConferenceForm
      initialValues={
        { ...formValues, districtId } as CreateConferenceFormResult
      }
      handleSubmit={createConferenceSubmit}
      countriesData={formattedCountries}
      countriesLoading={countriesLoading}
      loadingStates={loadingStates}
      countryStatesData={(countryStatesData || []) as State[]}
      timeZones={timeZones || []}
      fetchCountryStates={fetchCountryStates}
      loadingPRStates={loadingPRStates}
      fetchPRCountryStates={fetchPRCountryStates}
      countryPRStatesData={(countryPRStatesData || []) as State[]}
      handleBackRedirect={goBack}
      backButtonPath={getConferencePage(districtId)}
      is2023YManager={is2023YManager}
      futureManagementYear={getFutureManagementYear(accessLevelData)}
      formTitle={t(
        'create-conference.add-district-conference',
        'Add a District Conference'
      )}
    />
  );
};

export default DistrictCreateConference;
