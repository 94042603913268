import React from 'react';

import BlockWrapper from './BlockWrapper';
import DetailsChart from './DetailsChart/DetailsChart';

import { localizedNavigate } from '@utils/localized-navigate';

import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import { getMembersLinkClass } from '@domui-use-cases/districts/helpers/getMembersLinkClass';
import {
  getLabelValue,
  getMembershipLabels,
} from 'src/domui/use-cases/districts';

import { useTranslation } from '@external/react-i18next';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  membersStatistics: any;
  districtId: string;
};

const Members: React.FC<Props> = ({ membersStatistics, districtId }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartData = membersStatistics.map(({ membershipType, count }: any) => ({
    name: membershipType,
    count,
  }));

  return (
    <BlockWrapper
      title={t('district-details.members-heading', 'People')}
      handleClick={() =>
        localizedNavigate(`/domui/district/${districtId}/members`)
      }
    >
      {membersStatistics.length > 0 && (
        <DetailsChart
          chartData={chartData}
          getLinkHref={(value: string) =>
            `/domui/district/${districtId}/members?${MEMBER_FILTERS.MEMBER_TYPES}=${value}`
          }
          getLinkName={(index = 0, value = '') =>
            `${getLabelValue(getMembershipLabels(t), value)?.label} (${
              chartData[index].count
            })`
          }
          getLinkClass={(value: string) =>
            `leading-relaxed ${getMembersLinkClass(value)}`
          }
          graphHeight={200}
        />
      )}
    </BlockWrapper>
  );
};

export default Members;
