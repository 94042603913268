import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const useUpdateClubAssignmentsForAssistantGovernors = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);
  const [data] = useState<DynamicData>();

  const clubAssignmentsForAssistantGovernors = async (payload: DynamicData) => {
    let status = false;

    setLoading(true);
    setError(null);
    try {
      if (payload) {
        const wfService = new Member();
        await wfService.UpdateClubAssignmentsForAssistantGovernors(payload);
        status = true;
        setLoading(false);
      }
    } catch (error) {
      status = false;
      setError(error as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return status;
  };

  return { data, loading, error, clubAssignmentsForAssistantGovernors };
};
