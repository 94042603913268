import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { some } from 'lodash';
import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import MembersList from '@domui-presenters/web/pages/Districts/Members/MembersList';
import PageSteps from '@components/PageSteps';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Filters from '@domui-presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import Heading from '@domui-presenters/web/components/DistrictSelectMemberStep/Heading';
import MemberItem from './MemberItem';
import { processMembers } from '@domui-presenters/web/pages/Districts/Members/DistrictMembers';
import {
  CommitteeAppointment,
  CommitteeMemberData,
  DistrictMember,
  mapAndFormatDOToCommitteeMemberData as formatDES,
  isDES,
  OperationType,
  Step,
} from '@domui-domain/districts';
import {
  DistrictContext,
  getBackUrl,
  getMemberTypesOptions,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';
import { useLocalStorage } from '@repositories/storage/hooks';
import { localizedNavigate } from '@utils/localized-navigate';
import { useTranslation } from '@external/react-i18next';
import { useClubOptions } from '@domui-use-cases/districts/hooks/useClubOptions';
import { useDistrictOptions } from '@domui-use-cases/districts/hooks/useDistrictOptions';
import { DistrictOfficer, MembershipType } from '@typings/operations';
import AddNewOfficerButton from '@components/AddNewOfficer/AddNewOfficerButton';
import { useFetchPreviousOfficers } from '@domui-hooks/useFetchPreviousOfficers';
import { useErrorHandling } from '@use-cases/notifications';

interface PreviousOfficer {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  prefix?: string;
  suffix?: string;
  localizedName?: string;
  photoUri?: string;
  primaryEmail?: { email: string };
  primaryPhone?: { phone: string };
  leadershipsFromThisClub?: {
    id: string;
    startDate: string;
    endDate: string;
    role: string;
  }[];
  membershipsFromThisClub?: {
    type: string;
    admissionDate: string;
    terminationDate?: string;
    __typename?: string;
  }[];
}

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  stepCount?: number;
};

const PAGE_SIZE = 10;
const DEFAULT_STEP_COUNT = 2;

const TAB_DISTRICT_MEMBERS = 'District Members';
const TAB_PREVIOUS_DES = 'Previous District Executive Secretary';

export type SelectMemberTabs =
  | typeof TAB_DISTRICT_MEMBERS
  | typeof TAB_PREVIOUS_DES;

const SelectMemberStep: React.FC<Props> = ({
  districtId,
  riDistrictId,
  stepCount = DEFAULT_STEP_COUNT,
}) => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(DistrictContext);
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_DISTRICT_MEMBERS,
    scrollTo: 0,
  });

  const { clearStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    selectedValues: searchValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId);

  const {
    districtMembersSearchData: data,
    loading,
  } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    searchValues
  );

  const {
    data: previousDESData,
    loading: previousDESDataLoading,
    error,
    fetchPreviousOfficers: fetchPreviousDES,
  } = useFetchPreviousOfficers();

  const {
    operationType,
    selectedInfo: { role, unassignFrom },
    step,
  } = context;

  useEffect(() => {
    if (isDES(role?.name) && riDistrictId) {
      const previousOfficersPayload = {
        roleId: role?.id,
        clubId: districtId,
      };
      fetchPreviousDES(currentPage, PAGE_SIZE, previousOfficersPayload);
    }
  }, [role, currentPage]);

  useErrorHandling(error?.message, !!error, 'Fetch-PreviousDES.error');

  const { options: roleOptions } = useClubOptions(districtId);
  const { options: districtOptions } = useDistrictOptions(districtId);

  const memberTypesOptions = getMemberTypesOptions(t);
  const multiSelectOptions = {
    clubs: roleOptions,
    memberTypes: memberTypesOptions,
    districtRoles: districtOptions,
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: () => handleStepBack(),
  });

  if (loading || previousDESDataLoading) {
    return <Loading />;
  }

  if (!data || !data?.districtMembersDetails) {
    return null;
  }

  const members = data?.districtMembersDetails;
  const processedMembers = processMembers(members);
  const totalCount = data?.totalCount || 0;

  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleSelectAssignee = (
    assignee: DistrictMember | CommitteeMemberData
  ) => {
    window.localStorage.removeItem('isNextStepVisited');
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step + 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        assignee,
      },
    }));
    window.scrollTo(0, 0);
  };

  const handleStepBack = () => {
    window.localStorage.setItem('isNextStepVisited', 'true');
    if (step === 1) {
      clearStorage();
      localizedNavigate(getBackUrl(operationType, districtId));
      return;
    }
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step - 1) as Step,
    }));
    window.scrollTo(0, 0);
  };

  const { id, photoUri, nameWithPrefixSuffix } = unassignFrom || {};

  const isReplace = operationType === OperationType.REPLACE_OFFICER;
  const isAssign = operationType === OperationType.ASSIGN_TO_ROLE;

  const isAssignToMember = operationType === OperationType.ASSIGN_TO_MEMBER;

  const isAssignOrReplace = isReplace || isAssign;
  const areTabsMultiple = isDES(role?.name);

  const isRotaractorFilterApplied = some(
    filterValues.filters.memberTypes,
    memberType => memberType.value === MembershipType.Rotaractor
  );

  const isMemberFilterApplied = some(
    filterValues.filters.memberTypes,
    memberType => memberType.value === MembershipType.Member
  );

  const isFilterApplied: { [key in MembershipType]?: boolean } = {
    [MembershipType.Rotaractor]: isRotaractorFilterApplied,
    [MembershipType.Member]: isMemberFilterApplied,
  };

  const membersTab = {
    name: TAB_DISTRICT_MEMBERS,
    title: `${t(
      'district-management.district-members',
      'District members'
    )} (${totalCount})`,
    component: (
      <MembersList
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={totalCount}
        members={(processedMembers as DistrictMember[]) || []}
        memberItem={member => (
          <MemberItem
            member={member}
            selectHandler={handleSelectAssignee}
            isFilterApplied={isFilterApplied}
          />
        )}
        areTabsMultiple={areTabsMultiple}
        filters={
          <Filters
            initialFilterValues={searchValues}
            multiSelectOptions={multiSelectOptions}
            applyFilter={handleApplyFilters}
            resetFilter={handleResetFilters}
            filtersListToShow={[
              MEMBER_FILTERS.CLUB_IDS,
              MEMBER_FILTERS.MEMBER_TYPES,
            ]}
          />
        }
        showFiltersInContextMenu
        headerClassName={classNames('-m-2', { 'pr-14': areTabsMultiple })}
      />
    ),
  };

  const PreviousOfficersData = (
    previousDESData?.PreviousOfficers || []
  ).flatMap((officer: PreviousOfficer) => {
    const leaderships = officer.leadershipsFromThisClub || [];
    const memberships = officer.membershipsFromThisClub || [];

    return leaderships.map((leadership, index) => ({
      id: leadership.id,
      startDate: leadership.startDate,
      endDate: leadership.endDate,
      role: role?.name,
      roleId: role?.id,
      individual: {
        id: officer.id,
        firstName: officer.firstName,
        lastName: officer.lastName,
        middleName: officer.middleName,
        prefix: officer.prefix,
        suffix: officer.suffix,
        localizedName: officer.localizedName,
        photoUri: officer.photoUri,
        primaryEmail: officer.primaryEmail?.email,
        primaryPhone: officer.primaryPhone?.phone,
        membership: {
          type: memberships[index]?.type,
          admissionDate: memberships[index]?.admissionDate,
          terminationDate: memberships[index]?.terminationDate,
          __typename: memberships[index]?.__typename,
        },
      },
    }));
  });

  const DESList = formatDES(PreviousOfficersData as DistrictOfficer[]);

  const desTab = {
    name: TAB_PREVIOUS_DES,
    title: `${t(
      'district-management.district-previous-des',
      'Previous District Executive Secretary'
    )} (${previousDESData?.totalCount || 0})`,
    component: (
      <ResultsList
        darkBackground
        areTabsMultiple={areTabsMultiple}
        headerClassName={classNames(
          'px-8 tablet:px-5 tablet:bg-gray-100 -m-2',
          { 'px-14': areTabsMultiple }
        )}
        className="-mx-8 tablet:mx-0"
      >
        {DESList.map((des: CommitteeMemberData) => (
          <ResultsListItem key={des.id}>
            <MemberItem
              member={des}
              selectHandler={handleSelectAssignee}
              isDES
            />
          </ResultsListItem>
        ))}
      </ResultsList>
    ),
  };

  const tabs = isDES(role?.name) ? [membersTab, desTab] : [membersTab];

  const isSearchDisabled =
    isDES(role?.name) && activeTab.name === TAB_PREVIOUS_DES;

  let notification = '';
  if (isDES(role?.name)) {
    notification = t(
      'select-assign.des-subtitle',
      'Select a District Executive Secretary from previously assigned individuals, or add a new District Executive Secretary.'
    );
  }

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      to={isAssignOrReplace ? getBackUrl(operationType, districtId) : undefined}
      backHandler={isAssignOrReplace ? undefined : handleStepBack}
      step={step}
      total={stepCount}
      navClassName="mt-6 mb-0 text-small font-bold"
    >
      <Heading
        roleTitle={role?.name || ''}
        notification={notification}
        title={
          (isReplace
            ? t('select-member-to-replace.title', 'Select a Member to Replace')
            : null) ||
          (isAssignToMember && isDES(role?.name)
            ? t('select-des.title', 'Select District Executive Secretary')
            : t('select-member.title', 'Select a Member'))
        }
        isSubTitleHidden={isDES(role?.name) && isAssignToMember}
        subTitle={
          isReplace ? (
            <IndividualListColumn
              fullName={nameWithPrefixSuffix || ''}
              photoUri={photoUri || ''}
              id={id || ''}
              roleTitle={`${t('global.as', 'as')} ${role?.name}`}
              associatedClubsInfo={[]}
            />
          ) : null
        }
      />
      <AddNewOfficerButton
        roleName={role?.name || ''}
        isAddNewOfficer={isDES(role?.name)}
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
        disabled={isSearchDisabled}
      />
      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={setActiveTab}
      />
    </PageSteps>
  );
};

export default SelectMemberStep;
