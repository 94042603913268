// Libs
import { Dispatch, SetStateAction, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';

// Utils
import { localizedNavigate } from '@utils/localized-navigate';

// Hooks
import { useLocalStorage } from '@domui-repositories/storage/hooks/useLocalStorage';
import { useParams } from '@reach/router';

// Constants
import { BACK_URL } from '@domain/storage';

// Types
import { CommitteeAppointment, MemberURLParams } from '@domui-domain/districts';
import { FORM_STATUSES } from '@domain/storage';

export const useStorageNavigation = (
  contextData: CommitteeAppointment,
  setContextData: Dispatch<SetStateAction<CommitteeAppointment>>,
  redirectUrls: {
    backUrl: string;
  },
  URLParamsObj?: MemberURLParams,
  isNewTab?: boolean
) => {
  const { localStorageId } = useParams();

  const { getStorageData, clearStorage, updateStorage } = useLocalStorage<
    CommitteeAppointment
  >();

  const { operationType } = contextData;

  const isFormStatusFinished = (status?: string) =>
    status === FORM_STATUSES.FINISHED;

  useEffect(() => {
    const { data: storageData, status: storageStatus } = getStorageData() || {};

    if (isFormStatusFinished(storageStatus)) {
      return;
    }

    // if Opened in New Tab -> save backUrl into storage
    // it could be used instead of backUrlFromState in district flow
    if (isNewTab && !isEmpty(URLParamsObj)) {
      updateStorage({ backUrl: redirectUrls.backUrl }, BACK_URL);
    } else if (isNewTab && isEmpty(URLParamsObj)) {
      clearStorage(BACK_URL);
    }

    if (storageData?.operationType && !operationType) {
      const { dateToRemoveRole } = storageData.selectedInfo;
      // we have storage values and there is no operationType
      // that means we should get values from storage and continue previous flow
      setContextData({
        ...storageData,
        selectedInfo: {
          ...storageData.selectedInfo,
          // we need to restore previous value, as string is not valid Date
          dateToRemoveRole: dateToRemoveRole
            ? moment(dateToRemoveRole).toDate()
            : null,
        },
      });
    } else if (!storageData && operationType && localStorageId) {
      // clear storage - if there any other values exists,
      // they should be removed as we do not need them anymore
      clearStorage();
      // Set initial data as new flow started
      updateStorage(contextData, localStorageId);
      // redirect to new url with new localStorageId
    } else if (
      ((!operationType && !storageData) ||
        typeof storageData?.operationType === 'undefined') &&
      !isNewTab
    ) {
      // if we have expired data from localStorage,
      // there will not be set operationType in context or storage === null,
      // user will be returned to AG Landing Page
      localizedNavigate(redirectUrls.backUrl);
    }
  }, [localStorageId, operationType, redirectUrls]);

  useEffect(() => {
    // Make sure that we have storage filled when visiting page for the first time
    const { data: storageData, status: storageStatus } = getStorageData() || {};
    if (isFormStatusFinished(storageStatus)) {
      !isNewTab && localizedNavigate(redirectUrls.backUrl);
      return () => {
        if (storageData && !isEqual(storageData, contextData)) {
          setContextData({ ...storageData });
        }
      };
    }
  }, []);

  useEffect(() => {
    if (operationType) {
      const { data: storageData, status: storageStatus } =
        getStorageData() || {};
      // if data from storage is not equal to data from context
      // then we should update data inside storage
      if (
        !isFormStatusFinished(storageStatus) &&
        !isEqual(storageData, contextData)
      ) {
        updateStorage({
          ...contextData,
        });
      }
    }
  }, [operationType, contextData]);
};
