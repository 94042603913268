// Libs
import React from 'react';

import moment from 'moment';
// Components
import Modal from 'react-modal';

import { Button } from '@components/Button';
import Icon from '@components/Icon';

import { GetConferencesResult } from '@domui-use-cases/districts/conferences/type';
// Utils
import tailwind from '@styles/tailwind.js';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface Props {
  conference: GetConferencesResult | null;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const formatDate = (date: string) =>
  moment(date).format('D\u00a0MMM\u00a0YYYY');

const DeleteConferenceModal: React.FC<Props> = ({
  conference,
  isOpen = false,
  onConfirm: handleConfirm,
  onCancel: handleCancel,
}) => {
  const { t } = useTranslation();

  const { startDate = '', endDate = '' } = conference ?? {};

  const getMessage = (startDate: string | null, endDate: string | null) => {
    if (startDate && endDate) {
      return t(
        'conferences.delete-conference.are-you-sure.startEndDate',
        'Are you sure you want to remove the upcoming conference on {{startDate}} – {{endDate}}?',
        {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        }
      );
    }
    if (startDate) {
      return t(
        'conferences.delete-conference.are-you-sure.startDate',
        'Are you sure you want to remove the upcoming conference on {{startDate}}?',
        {
          startDate: formatDate(startDate),
        }
      );
    }
    return t(
      'conferences.delete-conference.are-you-sure',
      'Are you sure you want to remove the upcoming conference?'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Delete Conference modal"
      className="delete-conference-modal-mobile tablet:delete-conference-modal-desktop"
      ariaHideApp={false}
    >
      <div className="flex items-center justify-end">
        <Icon
          name="ui/close"
          color={tailwind.theme.colors.gray['300']}
          className="cursor-pointer"
          clickHandler={handleCancel}
        />
      </div>
      <div className="pt-5 tablet:pt-8 tablet:pr-6">
        <h2 className="text-xl leading-xl">{getMessage(startDate, endDate)}</h2>
        <div className="tablet:mt-4">
          {t('global.modals.delete.confirm-text', 'This cannot be undone.')}
        </div>
        <div className="mt-15 tablet:mr-6 flex flex-col tablet:flex-row items-center">
          <Button
            className="flex-1 mb-4 tablet:mr-8 tablet:mb-0"
            isMinWidth={false}
            clickHandler={handleConfirm}
          >
            {t('conferences.delete-conference', 'Remove conference')}
          </Button>
          <Button
            secondary
            isMinWidth={false}
            className="flex-1"
            clickHandler={handleCancel}
          >
            {t('action-Button.cancel', 'Cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConferenceModal;
