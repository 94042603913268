import React, { useContext } from 'react';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import {
  buildMembersPageUrl,
  buildOfficersPageUrl,
  DistrictContext,
  getQueryParamsFromURL,
  // useSetContextManagedRYs,
  useSetMemberDataFromURL,
} from '@domui-use-cases/districts';

import { useStorageNavigation } from '@domui-repositories/storage/hooks';

import { unflattenObject } from '@domui-utils/objectUtils';

import { MemberURLParams } from '@domui-domain/districts';

/** Declare global window variable
 * Used in Confirmation Steps to show Modal on browser navigation */
declare global {
  interface Window {
    stopBrowserNavigate: boolean;
  }
}

if (typeof window !== 'undefined') {
  window.stopBrowserNavigate = false;
}

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const CommitteeAppointmentManager: React.FC<Props> = ({
  districtId,
  riDistrictId,
}) => {
  let URLParamsObj = unflattenObject(
    getQueryParamsFromURL() as MemberURLParams | {}
  );

  const [context, setContext] = useContext(DistrictContext);
  const { step } = context;

  const { step: stepParam, isDOPage } = URLParamsObj;
  const stepNumber = parseInt(stepParam, 10);

  if (stepParam) {
    URLParamsObj = {
      ...URLParamsObj,
      step: stepNumber,
    };
  }

  useStorageNavigation(
    context,
    setContext,
    {
      backUrl: isDOPage
        ? buildOfficersPageUrl(districtId)
        : buildMembersPageUrl(districtId),
    },
    URLParamsObj,
    true
  );

  const paramsToExclude = [
    'operationType',
    'step',
    'id',
    'role',
    'termYears',
    'terms',
  ];

  useSetMemberDataFromURL(
    context,
    setContext,
    paramsToExclude,
    URLParamsObj as MemberURLParams
  );

  const currentStep = stepNumber > step ? stepNumber : step;

  if (currentStep === 1) {
    return <Step1 districtId={districtId} riDistrictId={riDistrictId} />;
  }
  if (currentStep === 2) {
    return <Step2 districtId={districtId} riDistrictId={riDistrictId} />;
  }
  if (currentStep === 3) {
    return <Step3 districtId={districtId} riDistrictId={riDistrictId} />;
  }
  return null;
};

export default CommitteeAppointmentManager;
