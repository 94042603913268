// apiService.ts
import { DynamicData } from '@domui-domain/type';
import { getRotaryYear } from '@domui-utils/datetime';
import Authorizer from '@domui-utils/workflow/authorizer';

export const createPayload = (dominoDistrictId: string) => {
  return {
    data: [
      {
        target: `/districts/${dominoDistrictId}/leadership`,
        arguments: {
          targetTermYear: [
            getRotaryYear(),
            (parseInt(getRotaryYear(), 10) + 1).toString(),
          ],
        },
      },
    ],
  };
};

export const fetchAccessLevels = async (
  requestorId: string,
  payload: DynamicData
): Promise<DynamicData> => {
  const wfService = new Authorizer();
  const targetTermYears = payload?.data[0].arguments.targetTermYear;

  const responses = await Promise.all(
    targetTermYears.map((year: string) => {
      const newPayload = {
        data: [
          {
            target: payload?.data[0].target,
            arguments: {
              targetTermYear: year,
            },
          },
        ],
      };
      return wfService.fetchDistrictPermission(requestorId, newPayload);
    })
  );

  // Combine the results from all API calls
  const combinedData = {
    data: responses.map(response => response),
  };

  return combinedData;
};
