import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Conference from '@domui-utils/workflow/conference';

// Custom hook to handle API calls
export const useViewConference = () => {
  const [data, setData] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const viewConference = async (districtId: string) => {
    try {
      if (districtId) {
        setLoading(true);
        setError(null);
        const wfService = new Conference();
        const response = await wfService.viewConference(districtId);
        setData(response);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, viewConference };
};
