import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';

import Select from '@components/Formik/Select';
import TextArea from '@components/Formik/TextArea';
import LinkPrevious from '@components/LinkPrevious';
import Title from '@components/Title';
import DetailSection from '@presenters/web/components/DetailSection';
import FormButtons from '@presenters/web/components/FormButtons';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';

import AddressSection from './AddressSection';
import DateTimeSection from './DateTimeSection';
import ParticipatingDistricts from './ParticipatingDistricts';
import PresRepSection from './PresPepSection';
import RemoteInfoSection from './RemoteInfoSection';
import validationSchema from './validationSchema';

import { LOCATION } from '@domain/districts';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import {
  getConferenceFormLabels,
  getConferenceHeading,
  getConferenceTypeSelectOptions,
  isRemoteInfoRequired,
} from '@use-cases/districts/conferences';
import { useErrorHandling } from '@use-cases/notifications';

import { getRotaryYear } from '@utils/datetime';

import LanguageSelect from '@domui-components/Formik/Select/LanguageSelect';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';

import { useTranslation } from '@external/react-i18next';

import {
  ConferenceType,
  Country,
  CreateConferenceFormResult,
  State,
  TimeZone,
} from './types';

const COMMENTS_BOX_MAX_CHARS = 250;
const REQUEST_PRES_REP_URL =
  'https://rotary.qualtrics.com/jfe/form/SV_0HxLSnZ5P0UyMnk';

interface Props {
  initialValues: CreateConferenceFormResult;
  countriesData: Country[];
  countriesLoading: boolean;
  countryStatesData: State[];
  loadingPRStates: boolean;
  fetchPRCountryStates: (options?: string) => void;
  countryPRStatesData: State[];
  loadingStates: boolean;
  backButtonPath: string;
  formTitle: string;
  timeZones: TimeZone[];
  fetchCountryStates: (options?: string) => void;
  handleSubmit: (formData: CreateConferenceFormResult) => Promise<void>;
  handleBackRedirect: () => void;
  is2023YManager: boolean;
  futureManagementYear?: string | null;
}

const ConferenceForm: React.FC<Props> = ({
  initialValues,
  countriesData = [],
  countriesLoading,
  loadingStates,
  countryStatesData,
  backButtonPath,
  formTitle,
  timeZones = [],
  fetchCountryStates,
  handleBackRedirect,
  handleSubmit,
  is2023YManager,
  futureManagementYear,
  loadingPRStates,
  fetchPRCountryStates,
  countryPRStatesData,
}) => {
  const { t } = useTranslation();
  const {
    venueTypeLabel,
    languageLabel,
    lineOneLabel,
    languagePlaceholder,
    countryPlaceholder,
    phoneNumberLabel,
    statePlaceholder,
    accommodationNameLabel,
    presRepLineOneLabel,
    phoneCountryIdLabel,
    locationTitle,
    languageTitle,
    dateTimeTitle,
    commentsTitle,
    districtsTitle,
    presRepTitle,
  } = getConferenceFormLabels(t);

  const {
    data: languages,
    loading: languagesLoading,
    error: languagesError,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);

  useErrorHandling(
    languagesError?.message,
    !!languagesError,
    'fetch-languages.error'
  );

  // const [
  //   // isValidatingParticipants,
  //   // setIsValidatingParticipantDistricts,
  // ] = useState(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isStepMax: true,
  });

  // const setIsValidatingParticipants = (isValidating: boolean) =>
  //   setIsValidatingParticipantDistricts(isValidating);

  const handleOnSave = (formValues: CreateConferenceFormResult) => {
    handleSubmit(formValues);
    setGlobal(false);
  };

  const handleOnContinue = () => {
    globalHide();
    handleBackRedirect();
  };

  const handleCancel = (showPopup: boolean) => {
    return showPopup ? show(true) : handleBackRedirect();
  };

  // const initialParticipantsIds = initialValues.participatingDistricts?.map(
  //   ({ id }) => id
  // );

  const RY = Number(getRotaryYear());
  const minDate = futureManagementYear
    ? moment(`${Number(futureManagementYear) - 1}-07-01`).toDate()
    : moment().toDate();
  const maxDate = futureManagementYear
    ? moment(`${futureManagementYear}-06-30`).toDate()
    : moment(`${RY}-06-30`).toDate();

  // const conferenceRY = getRotaryYear(moment(minDate).format('YYYY-MM-DD'));

  return (
    <div className="flex">
      <div className="tablet:w-1/2 w-full mt-8">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(t)}
          validateOnBlur
          validateOnChange
        >
          {({
            isSubmitting,
            values,
            isValid,
            dirty,
            errors,
            // isValidating
          }) => {
            const isSaveButtonDisabled =
              !(isValid && !isEqual(values, initialValues)) ||
              // isValidating ||
              isSubmitting;
            // isValidatingParticipants;
            const {
              venueType: conferenceType,
              dateTime,
              isPresRepRequested,
            } = values;
            const { endTime, startTime } = dateTime;

            const isOnlineConference = conferenceType === ConferenceType.Online;
            const errorMessage =
              errors?.dateTime?.startDate !== 'Required'
                ? errors?.dateTime?.startDate
                : null;

            return (
              <>
                <LinkPrevious
                  path={backButtonPath}
                  label={t('create-conference.back-link', 'Back')}
                  showModal={() => handleCancel(dirty)}
                />
                <Title className="conference-form-title tablet:m-0 mt-0625 mb-6 p-0">
                  {formTitle}
                </Title>
                <Form>
                  <DetailSection>
                    <Select
                      name="venueType"
                      label={venueTypeLabel}
                      options={getConferenceTypeSelectOptions()}
                    />
                  </DetailSection>
                  <DateTimeSection
                    title={dateTimeTitle}
                    isTimeZoneRequired={Boolean(endTime || startTime)}
                    timeZones={timeZones}
                    errorMessage={errorMessage}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  <DetailSection
                    title={locationTitle || getConferenceHeading(t, LOCATION)}
                  >
                    {!isOnlineConference && (
                      <AddressSection
                        suffix="conferenceAddress"
                        countryId={values.conferenceAddress.country}
                        placeholders={{ countryPlaceholder, statePlaceholder }}
                        labels={{ lineOneLabel }}
                        countriesData={countriesData}
                        countriesLoading={countriesLoading}
                        loadingStates={loadingStates}
                        countryStatesData={countryStatesData}
                        fetchCountryStates={fetchCountryStates}
                      />
                    )}
                    {isRemoteInfoRequired(values.venueType) && (
                      <RemoteInfoSection
                        fieldNames={{
                          url: 'onlineLocation',
                          detailsForJoiningRemotely: 'detailForJoiningRemotely',
                        }}
                      />
                    )}
                  </DetailSection>
                  <DetailSection title={languageTitle}>
                    <LanguageSelect
                      name="language"
                      label={languageLabel}
                      placeholder={languagePlaceholder}
                      languages={languages}
                      languagesLoading={languagesLoading}
                    />
                  </DetailSection>
                  <DetailSection title={districtsTitle}>
                    <ParticipatingDistricts
                      districtId={values.districtId}
                      selectedParticipants={values.participatingDistricts || []}
                      // initialParticipantsIds={initialParticipantsIds}
                      // conferenceRY={conferenceRY}
                      // isValidating={setIsValidatingParticipants}
                    />
                  </DetailSection>
                  <DetailSection
                    dataTestId="detail-section-23-y-manager"
                    noBottomIndentTitle={is2023YManager}
                    title={presRepTitle}
                  >
                    {is2023YManager && (
                      <p className="my-2">
                        {t(
                          'pres-rep-text-for-23-y-manager',
                          'If you request a President Rep please follow <a href="{{url}}">this link</a>',
                          {
                            url: REQUEST_PRES_REP_URL,
                          }
                        )}
                      </p>
                    )}
                    <PresRepSection
                      isAccommodationPhone={Boolean(
                        values?.accomodationPhone?.number
                      )}
                      isOnline={isOnlineConference}
                      isPresRepRequested={isPresRepRequested}
                      suffix="prAddress"
                      fieldNames={{ venueName: 'prAccommodation' }}
                      countryId={values?.prAddress?.country}
                      placeholders={{ countryPlaceholder, statePlaceholder }}
                      labels={{
                        venueNameLabel: accommodationNameLabel,
                        lineOneLabel: presRepLineOneLabel,
                        countryId: phoneCountryIdLabel,
                        number: phoneNumberLabel,
                      }}
                      countriesData={countriesData}
                      countriesLoading={countriesLoading}
                      loadingStates={loadingPRStates}
                      countryStatesData={countryPRStatesData}
                      fetchCountryStates={fetchPRCountryStates}
                    />
                  </DetailSection>
                  <DetailSection title={commentsTitle}>
                    <div>
                      <TextArea
                        areaclasses="h-30 py-4 px-2"
                        name="comments"
                        label={commentsTitle}
                        characters={COMMENTS_BOX_MAX_CHARS}
                      />
                    </div>
                  </DetailSection>
                  <FormButtons
                    cancel={() => handleCancel(dirty)}
                    cancelBtnText={t('create-conference.cancel', 'Cancel')}
                    saveBtnText={t(
                      'create-conference.save-and-finish',
                      'Save & Finish'
                    )}
                    isSaveDisabled={isSaveButtonDisabled}
                    isCancelDisabled={isSubmitting}
                  />
                </Form>
                <SaveChangesModal
                  isOpen={isShowing}
                  onClose={globalHide}
                  onSave={() => handleOnSave(values)}
                  onContinue={handleOnContinue}
                  isSaveBtnDisabled={!isValid}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ConferenceForm;
