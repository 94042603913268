import React from 'react';

// Components
import TextArea from '@components/Formik/TextArea';
import TextField from '@components/Formik/TextField';
// Types
import {
  RemoteInfoFields,
  RemoteInfoLabels,
  RemoteInfoPlaceholders,
} from '@presenters/web/components/ConferenceForm/types';

import { useTranslation } from '@external/react-i18next';

interface Props {
  labels?: RemoteInfoLabels;
  placeholders?: RemoteInfoPlaceholders;
  fieldNames: RemoteInfoFields;
}

const RemoteInfoSection: React.FC<Props> = ({
  labels,
  placeholders,
  fieldNames,
}) => {
  const { t } = useTranslation();
  const defaultLabels = {
    urlLabel: t('create-conference.online-location.label', 'URL'),
    detailsForJoiningRemotelyLabel: t(
      'create-conference.details-joining-remotely.label',
      'Details for joining remotely'
    ),
  };

  const defaultPlaceholders = {
    urlPlaceholder: t('create-conference.online-location.placeholder', ''),
    detailsForJoiningRemotelyPlaceholder: t(
      'create-conference.details-joining-remotely.placeholder',
      ''
    ),
  };

  const {
    urlLabel: defaultUrlLabel,
    detailsForJoiningRemotelyLabel: defaultDetailsForJoiningRemotelyLabel,
  } = defaultLabels;

  const { urlPlaceholder: defaultUrlPlaceholder } = defaultPlaceholders;

  const {
    urlLabel = defaultUrlLabel,
    detailsForJoiningRemotelyLabel = defaultDetailsForJoiningRemotelyLabel,
  } = labels || {};

  const { urlPlaceholder = defaultUrlPlaceholder } = placeholders || {};

  return (
    <>
      <TextField
        name={fieldNames.url}
        label={urlLabel}
        placeholder={urlPlaceholder}
      />
      <TextArea
        areaclasses="h-30 py-4 px-2"
        name={fieldNames.detailsForJoiningRemotely}
        label={detailsForJoiningRemotelyLabel}
        characters={250}
      />
    </>
  );
};

export default RemoteInfoSection;
