/* eslint-disable react/jsx-no-useless-fragment */
// Libs
import React from 'react';

// Components
import ActionButtons from '@components/ActionButtons';

// import { isEditLevel } from '@utils/access-helpers';
// Types
import { localizedNavigate } from '@utils/localized-navigate';

import { RenderActionsButton } from '@domui-domain/districts';
import {
  getActionsConfig,
  getConferenceViewPage,
  // getCreateOrUpdateFeedbackPage,
  getEditConferencePath,
} from '@domui-use-cases/districts';

// Hooks
import { useTranslation } from '@external/react-i18next';

const ActionButtonsMobile: RenderActionsButton = (
  districtId,
  conference,
  onDelete,
  conferenceAccessLvls
  // conferenceFeedbackAccessLvls
) => {
  const { t } = useTranslation();

  const {
    id: conferenceId,
    // relatedRotaryYear,
    createdByRiDistrictId,
  } = conference;

  const {
    isMyDistrict,
    canEditConference,
    canRemoveConference,
  } = getActionsConfig(conference, String(districtId), conferenceAccessLvls);

  // const renderCreateFeedbackButton = () => {
  //   if (isEditLevel(conferenceFeedbackAccessLvls?.[conference?.id])) {
  //     return (
  //       <ActionButtons
  //         removeBtnLabel={t('conference.feedback-link', 'Feedback')}
  //         onRemove={() =>
  //           localizedNavigate(
  //             getCreateOrUpdateFeedbackPage(
  //               {
  //                 districtId,
  //                 id: conferenceId,
  //                 relatedRotaryYear,
  //               },
  //               'DG'
  //             )
  //           )
  //         }
  //         isColumn
  //         wrapperClassNames="w-full flex flex-col"
  //       />
  //     );
  //   }

  //   return null;
  // };

  return (
    <>
      {canEditConference ? (
        <ActionButtons
          editBtnLabel={t('conference.edit-link', 'Edit')}
          removeBtnLabel={t('conference.remove-link', 'Remove')}
          onEdit={() =>
            localizedNavigate(
              getEditConferencePath(String(districtId), conferenceId)
            )
          }
          onRemove={() => onDelete(conference)}
          isRemoveDisabled={!canRemoveConference}
          isColumn
          wrapperClassNames="w-full flex flex-col mt-4"
        />
      ) : (
        <>
          {!isMyDistrict && (
            <span className="italic">
              {t(
                'conference.created-by',
                'Created by {{ createdByRiDistrictId }}',
                { createdByRiDistrictId }
              )}
            </span>
          )}
          <ActionButtons
            viewBtnLabel={t('conference.view-link', 'View')}
            onView={() =>
              localizedNavigate(
                getConferenceViewPage(String(districtId), conferenceId)
              )
            }
            isColumn
            wrapperClassNames="w-full flex flex-col mt-4"
          />
          {/* {renderCreateFeedbackButton()} */}
        </>
      )}
    </>
  );
};

export default ActionButtonsMobile;
