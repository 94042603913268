import React, { useEffect } from 'react';

import { reject } from 'lodash';

import ParticipantsSelector from './ParticipantsSelector/ParticipantsSelector';

import { useFetchAllDistricts } from '@domui-hooks/useFetchAllDistricts';

import { useTranslation } from '@external/react-i18next';

import { ParticipantDistrictType, ParticipantOption } from './types';

type Props = {
  districtId: string;
  // conferenceRY: string;
  selectedParticipants: ParticipantOption[];
  // initialParticipantsIds?: string[];
  isValidating?: (isValidating: boolean) => void;
};

const ParticipatingDistricts: React.FC<Props> = ({
  districtId,
  // conferenceRY,
  selectedParticipants,
  // initialParticipantsIds,
  isValidating,
}) => {
  const { t } = useTranslation();

  const { data, loading, fetchAllDistricts } = useFetchAllDistricts();

  useEffect(() => {
    fetchAllDistricts();
  }, []);

  if (loading) return null;

  const districtsOptions =
    data
      ?.filter(
        ({ id, riDistrictId }: ParticipantDistrictType) => id && riDistrictId
      )
      .map(({ id, riDistrictId }: ParticipantDistrictType) => ({
        id,
        value: riDistrictId,
      })) || [];

  // Exclude native district from options
  const notNativeDistricts = reject(districtsOptions, [
    'id',
    districtId,
  ]) as ParticipantOption[];

  const sortedOptions = [...notNativeDistricts].sort(
    ({ value: firstValue }, { value: secondValue }) => firstValue - secondValue
  );

  return (
    <>
      <div className="mb-2">
        {t(
          'create-conference.participants.description',
          'Your district is added by default to this Conference'
        )}
      </div>

      <div className="text-xs text-gray-600 font-bold">
        {t('create-conference.participants.label', 'Additional District(s)')}
      </div>
      <ParticipantsSelector
        name="participatingDistricts"
        selectedParticipants={selectedParticipants}
        options={sortedOptions}
        // initialParticipantsIds={initialParticipantsIds}
        // conferenceRY={conferenceRY}
        isValidating={isValidating}
        // districtId={districtId}
      />
    </>
  );
};

export default ParticipatingDistricts;
