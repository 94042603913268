/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';
import DistrictOfficerRolesList from './DistrictOfficerRolesList';
import DistrictOfficersHeader, {
  LocationStateType,
} from './DistrictOfficersHeader';

import { CommitteeAppointment, OFFICERS_ROLES } from '@domui-domain/districts';

import { DistrictContext } from '@domui-use-cases/districts';

import { getRotaryYear } from '@utils/datetime';

import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchDistrictOfficers } from '@domui-hooks/useFetchDistrictOfficers';
import { DistrictCommitteeAppointments } from '@domui-hooks/useDistrictCommitteeAppointments';
import { getDistrictIdFromPath } from '@domui-use-cases/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
}

const DistrictOfficers: React.FC<Props> = ({
  districtId,
  riDistrictId,
  location,
}) => {
  const { user } = useAppConfig();
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const [
    {
      committeeManager: { isManager },
      selectedInfo: { term },
    },
    setContext,
  ] = useContext(DistrictContext);
  const dominoDistrictId = getDistrictIdFromPath(location?.pathname);

  const getAllRoles = true;

  const {
    data: districtOfficersData,
    loading: districtOfficersLoading,
    fetchDistrictOfficers,
  } = useFetchDistrictOfficers();
  const districtOfficersPayload = {
    page: 1,
    pageSize: 100,
    roles: OFFICERS_ROLES,
    rotaryYear: term?.endDate || getRotaryYear(),
    riDistrictId,
  };

  const districtCommitteeAppointmentsPayload = {
    getAllRoles,
    rotaryYear: term?.endDate || getRotaryYear(),
  };

  const {
    data: districtCommitteeAppointmentData,
    loading: districtCommitteeAppointmentLoading,
    districtCommitteeAppointments,
  } = DistrictCommitteeAppointments();

  useEffect(() => {
    if (riDistrictId && user?.isLoggedIn && dominoDistrictId && term) {
      fetchDistrictOfficers(dominoDistrictId, districtOfficersPayload);
      districtCommitteeAppointments(
        dominoDistrictId,
        districtCommitteeAppointmentsPayload
      );
    }
  }, [riDistrictId, user?.isLoggedIn, term, dominoDistrictId]);

  if (
    !districtOfficersData ||
    districtOfficersLoading ||
    districtCommitteeAppointmentLoading
  ) {
    return <Loading />;
  }

  const handleSelectYear = (rotaryYear: string) => {
    const year = parseInt(rotaryYear, 10);
    const newTerm = { startDate: String(year - 1), endDate: rotaryYear };

    setContext((prevState: CommitteeAppointment) => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: newTerm,
      },
    }));
  };
  const leadershipData = districtOfficersData?.districtOfficersDetails
    .map((officer: { districtLeadership: any }) => officer.districtLeadership)
    .flat();
  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-officers',
          '{{prefix}}District officers {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        <DistrictOfficersHeader
          districtId={districtId}
          selectedYear={term?.endDate}
          handleYearChanged={handleSelectYear}
          locationState={location?.state as LocationStateType}
        />
        <div className="-mx-8 tablet:mx-0">
          <DistrictOfficerRolesList
            title={t(
              'district-officers.district-governors',
              'District Governors'
            )}
            leadershipData={leadershipData}
            officers={districtOfficersData?.districtOfficersDetails}
          />
          <DistrictOfficerRolesList
            title={t(
              'district-officers.committee-appointments',
              'Committee Appointments'
            )}
            notice={t(
              'district-officers.committee-appointments.required-roles-notice',
              '* Represents the Role that is required by Policy'
            )}
            roles={
              districtCommitteeAppointmentData?.committeeAppointments || []
            }
          />
        </div>
      </div>
    </>
  );
};

export default DistrictOfficers;
