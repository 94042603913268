import React, { useContext, useState } from 'react';

import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';
import MultipleRoles from '@domui-presenters/web/pages/Districts/CommitteeAppointments/MultipleRoles';

import ReplaceOfficerStepConfirm from './ReplaceOfficerStepConfirm';

import {
  CommitteeAppointment,
  CommitteeMemberData,
  isAG,
  Step,
} from '@domui-domain/districts';

import {
  DistrictContext,
  extractRoleInfo,
  getBackUrl,
  use3yrRoleConfirmation,
  useCAReplaceRemoveContext,
  // useSetContextManagedRYs,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useModal } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useManageDistrictLeadership } from '@domui-repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { pushLocalisedUrl } from '@utils/browserHistory';
import {
  getCurrentDate,
  // getRotaryYear,
  isCurrentRY,
} from '@domui-utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useLogger } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const ReplaceRole: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const [context, setContext] = useContext(DistrictContext);
  const {
    step,
    selectedInfo: { unassignFrom, assignee, role, term, dateToRemoveRole },
    managedRYs: { current: currentYearAssignment },
    operationType,
  } = context;

  // const {
  //   currentOfficersLoading,
  //   futureOfficersLoading,
  // } = useSetContextManagedRYs(districtId, riDistrictId);

  const { updateStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    updateDLForFutureRY,
    updateDLForCurrentRY,
    assignDL,
    isLoading,
    assignRoleToMemberError,
    replaceOfficerError,
    removeFromAssignedRoleError,
  } = useManageDistrictLeadership({ districtId, riDistrictId });

  const backUrl = getBackUrl(operationType, districtId);

  const {
    isCurrentTerm,
    isOneRole,
    resetContextValues,
    selectedLeadership,
    districtLeadership,
  } = useCAReplaceRemoveContext();

  const {
    isCommunicationConfirmed,
    renderConfirmation,
  } = use3yrRoleConfirmation();

  const { addLog } = useLogger();

  const stepsForMultipleRoles = isAG(role?.id) ? 4 : 3;
  const STEP_COUNT = (!isOneRole && stepsForMultipleRoles) || 2;

  const isStepMax = step === STEP_COUNT;

  useErrorHandling(
    assignRoleToMemberError?.description
      ? t('Assign-RoleToMember.error', assignRoleToMemberError?.description)
      : 'An error occurred while Assigning Role To Member.',
    !!assignRoleToMemberError,
    'Assign-RoleToMember.error'
  );

  useErrorHandling(
    replaceOfficerError?.description
      ? t('Assign-RoleToMember.error', replaceOfficerError?.description)
      : 'An error occurred while replacing an officer.',
    !!replaceOfficerError,
    'Replace-Officer.error'
  );

  useErrorHandling(
    removeFromAssignedRoleError?.description
      ? t('Assign-RoleToMember.error', removeFromAssignedRoleError?.description)
      : 'An error occurred while removing from assigned role.',
    !!removeFromAssignedRoleError,
    'Remove-FromAssignedRole.error'
  );

  const onCancelHandler = () => {
    resetContextValues(updateStorage);
    window.localStorage.removeItem('isNextStepVisited');
    if (step === 1) {
      localizedNavigate(backUrl);
      return;
    }
    window.stopBrowserNavigate = false;
    localizedNavigate(backUrl);
  };

  const isNextStepVisited = JSON.parse(
    window.localStorage.getItem('isNextStepVisited') || 'false'
  );

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax,
    isNextStepVisited,
    onNavigate: onCancelHandler,
  });

  const submitBtnLabel = isOneRole
    ? t('action-button.finish', 'Finish')
    : t('action-button.continue', 'Continue');

  const logError = () =>
    addLog({
      level: LogLevel.Error,
      message: `
            An error occurred when replacing the officer. The values provided are
            assigneeId: ${assignee?.id},
            officerToBeReplacedId: ${unassignFrom?.id},
            selectedRole: ${role?.name},
            selectedTerm: ${term?.startDate}-${term?.endDate}
            dateToRemoveRole: ${dateToRemoveRole}
          `,
    });

  const handleAPICallFailure = () =>
    addError(
      t(
        'replace-officer.confirmation.error-request',
        'An error occurred when replacing the officer'
      )
    );

  const handleAPICallSuccess = () => {
    addSuccess(
      t(
        'replace-officer-detail-success',
        'Success! {{officerToBeReplacedName}} has been replaced by {{officerAssigneeName}} as {{role}}.',
        {
          officerAssigneeName: assignee?.nameWithPrefixSuffix,
          officerToBeReplacedName: unassignFrom?.nameWithPrefixSuffix,
          role: role?.name,
        }
      )
    );
    onCancelHandler();
  };

  const areValuesSet = unassignFrom && assignee && role && term;
  const selectedLeadershipDateToRemove =
    dateToRemoveRole && moment(dateToRemoveRole).format('YYYY-MM-DD');

  const replaceDOForCurrentRY = async (individualId: string) => {
    const selectedLeadershipStartDate = moment(selectedLeadership?.startDate);
    const endDateForSelectedLeadership = moment(dateToRemoveRole);
    let assignDlRes = null;
    let updateDLForCurrentRYRes = null;

    const isEqualStartDateAndDateToRemove = selectedLeadershipStartDate.isSame(
      endDateForSelectedLeadership,
      'D'
    );

    const isEqualDateToRemoveAndCurrentDate =
      isEqualStartDateAndDateToRemove &&
      !endDateForSelectedLeadership.isSame(moment(getCurrentDate()), 'D');

    if (!isEqualDateToRemoveAndCurrentDate) {
      endDateForSelectedLeadership.subtract(1, 'd');
    }

    if (areValuesSet && dateToRemoveRole) {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      if (isEqualDateToRemoveAndCurrentDate) {
        await updateDLForFutureRY(
          selectedLeadership!.id,
          endDateForSelectedLeadership
        );
        assignDlRes = await assignDL(
          individualId,
          role!.id,
          endDateForSelectedLeadership
        );
        if (!assignDlRes) {
          return;
        }
      } else {
        updateDLForCurrentRYRes = await updateDLForCurrentRY(
          selectedLeadership!.id,
          individualId,
          dateToRemoveRole
        );
        if (!updateDLForCurrentRYRes) {
          return;
        }
      }
      handleAPICallSuccess();
    } else {
      logError();
    }
  };

  const replaceDOForFutureRY = async (individualId: string) => {
    if (areValuesSet) {
      const leadershipId = selectedLeadership!.id;
      const endDateForSelectedLeadership = moment(dateToRemoveRole);
      let updateDLForCurrentRYRes = null;
      let updateDLForFutureRYRes = null;
      let assignDlRes = null;

      if (
        selectedLeadershipDateToRemove &&
        isCurrentRY(selectedLeadershipDateToRemove)
      ) {
        updateDLForCurrentRYRes = await updateDLForCurrentRY(
          leadershipId,
          individualId,
          dateToRemoveRole
        );
        if (!updateDLForCurrentRYRes) {
          return;
        }
      } else {
        updateDLForFutureRYRes = await updateDLForFutureRY(
          leadershipId,
          endDateForSelectedLeadership
        );
        assignDlRes = await assignDL(
          individualId,
          role!.id,
          endDateForSelectedLeadership
        );
        if (!updateDLForFutureRYRes || !assignDlRes) {
          return;
        }
      }

      handleAPICallSuccess();
    } else {
      logError();
    }
  };

  const handleSubmit = async () => {
    if (!isStepMax) {
      window.localStorage.setItem('isNextStepVisited', 'true');

      !isStepMax && window.localStorage.setItem('isNextStepVisited', 'true');
      setContext(prevState => ({
        ...prevState,
        step: (step + 1) as Step,
      }));
      window.scrollTo(0, 0);
      return;
    }
    try {
      await (isCurrentTerm ? replaceDOForCurrentRY : replaceDOForFutureRY)(
        assignee!.id
      );
    } catch {
      handleAPICallFailure();
    } finally {
      setGlobal(false);
      pushLocalisedUrl(backUrl);
    }
  };

  const goToPrevStep = () => {
    window.localStorage.setItem('isNextStepVisited', 'true');
    setContext(prevState => ({
      ...prevState,
      step: (step - 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        dateToRemoveRole: null,
      },
    }));
    setGlobal(false);
    window.scrollTo(0, 0);
  };

  const handleSelectDate = (date: Date) => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        dateToRemoveRole: date,
      },
    }));
  };

  const handleChangeRole = (roleId: string) => {
    const selectedRole = districtLeadership?.find(
      leadership => leadership.roleId === roleId
    );
    if (selectedRole) {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          role: {
            id: selectedRole.roleId,
            name: selectedRole.role,
            termYears: selectedRole.termYears,
          },
        },
      }));
    }
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const modalBackHandler = () => {
    setIsBack(true);
    show(true);
  };

  const isSubmitDisabled =
    role === null ||
    (role.termYears === 3 && isStepMax && !isCommunicationConfirmed);

  if (!unassignFrom || !operationType) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageSteps
        className="p-0"
        backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
        backHandler={isStepMax ? modalBackHandler : onCancelHandler}
        step={step}
        total={STEP_COUNT}
      >
        {isOneRole || step === 3 ? (
          <ReplaceOfficerStepConfirm
            assignee={assignee as CommitteeMemberData}
            unassignFrom={{
              ...(unassignFrom as CommitteeMemberData),
            }}
            handleSelectDate={isCurrentTerm ? handleSelectDate : undefined}
            defaultDate={dateToRemoveRole as Date}
            roleInfo={extractRoleInfo(t, role, selectedLeadership)}
          />
        ) : (
          <MultipleRoles
            member={unassignFrom as CommitteeMemberData}
            roleId={role?.id}
            title={t(
              'select-role-to-replace.title',
              'Select a Role to Replace'
            )}
            legend={t(
              'select-role-to-replace.legend',
              'Select a Role to Replace'
            )}
            handleChangeRole={handleChangeRole}
            // currentYear={getRotaryYear()}
            disableTodayRoles
            currentYearAssignment={currentYearAssignment}
            // districtId={districtId}
            // riDistrictId={riDistrictId}
          />
        )}
        {isStepMax && renderConfirmation()}
        <ActionButtons
          submitBtnLabel={submitBtnLabel}
          cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
          onCancel={isStepMax ? modalOnCancelHandler : onCancelHandler}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={handleSubmit}
        />
      </PageSteps>
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={isBack ? goToPrevStep : onCancelHandler}
      />
    </>
  );
};

export default ReplaceRole;
