// Libs
import React from 'react';

import { orderBy } from 'lodash';
import moment from 'moment';

// Components
import Calendar from '@components/Formik/Calendar';
import DetailSection from '@presenters/web/components/DetailSection';
import SelectTime from '@presenters/web/components/Formik/SelectTime';

import TimeZoneSelect from './TimeZoneSelector/TimeZoneSelector';

// Constants
import { DATES_AND_TIMES } from '@domain/districts/conferences/constants';

import { getConferenceHeading } from '@use-cases/districts/conferences';

import { getRotaryYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

// Types
import {
  DateTypeLabels,
  DateTypeOptions,
  DateTypePlaceholders,
  Maybe,
  TimeZone,
} from './types';

const FORMIK_SUFFIX = 'dateTime';

interface Props {
  title?: string;
  fieldNames?: DateTypeOptions;
  labels?: DateTypeLabels;
  placeholders?: DateTypePlaceholders;
  suffix?: string;
  minDate?: Date;
  maxDate?: Date;
  isTimeZoneRequired: boolean;
  timeZones: TimeZone[];
  errorMessage?: Maybe<string>;
}

const DateTimeSection: React.FC<Props> = ({
  title,
  fieldNames,
  placeholders,
  labels,
  suffix = FORMIK_SUFFIX,
  minDate = moment().toDate(),
  maxDate = moment(`${getRotaryYear()}-06-30`).toDate(),
  isTimeZoneRequired = false,
  timeZones,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const defaultTitle = getConferenceHeading(t, DATES_AND_TIMES);
  const mainTitle = title || defaultTitle;

  const tzOptions = timeZones.map(({ id, value, offset, timeZoneName }) => ({
    label: `${value}, ${offset}`,
    value: id,
    displayValue: value,
    subtitle: timeZoneName,
  }));

  const defaultFieldNames = {
    startDate: `${suffix}.startDate`,
    startTime: `${suffix}.startTime`,
    endDate: `${suffix}.endDate`,
    endTime: `${suffix}.endTime`,
  };

  const defaultPlaceholders = {
    startDatePlaceholder: t(
      'create-conference.start-date.placeholder',
      '--Select--'
    ),
    startTimePlaceholder: t(
      'create-conference.start-time.placeholder',
      '--Select--'
    ),
    endDatePlaceholder: t(
      'create-conference.end-date.placeholder',
      '--Select--'
    ),
    endTimePlaceholder: t(
      'create-conference.end-time.placeholder',
      '--Select--'
    ),
  };

  const defaultLabels = {
    startDateLabel: t('create-conference.start-date-label', 'Start Date'),
    startTimeLabel: t('create-conference.start-time-label', 'Start Time'),
    endDateLabel: t('create-conference.end-date-label', 'End Date'),
    endTimeLabel: t('create-conference.end-time-label', 'End Time'),
  };

  const {
    startDate: defaultStartDate,
    startTime: defaultStartTime,
    endDate: defaultEndDate,
    endTime: defaultEndTime,
  } = defaultFieldNames;

  const {
    startDatePlaceholder: defaultStartDatePlaceholder,
    startTimePlaceholder: defaultStartTimePlaceholder,
    endDatePlaceholder: defaultEndDatePlaceholder,
    endTimePlaceholder: defaultEndTimePlaceholder,
  } = defaultPlaceholders;

  const {
    startDateLabel: defaultStartDateLabel,
    startTimeLabel: defaultStartTimeLabel,
    endDateLabel: defaultEndDateLabel,
    endTimeLabel: defaultEndTimeLabel,
  } = defaultLabels;

  const {
    startDate = defaultStartDate,
    startTime = defaultStartTime,
    endDate = defaultEndDate,
    endTime = defaultEndTime,
  } = fieldNames || {};

  const {
    startDatePlaceholder = defaultStartDatePlaceholder,
    startTimePlaceholder = defaultStartTimePlaceholder,
    endDatePlaceholder = defaultEndDatePlaceholder,
    endTimePlaceholder = defaultEndTimePlaceholder,
  } = placeholders || {};

  const {
    startDateLabel = defaultStartDateLabel,
    startTimeLabel = defaultStartTimeLabel,
    endDateLabel = defaultEndDateLabel,
    endTimeLabel = defaultEndTimeLabel,
  } = labels || {};

  return (
    <DetailSection title={mainTitle}>
      <div className="w-32">
        <TimeZoneSelect
          name={`${suffix}.timeZone`}
          label={t('create-conference.timeZone', 'Time Zone')}
          timeZones={orderBy(tzOptions, 'label', 'asc')}
          required={isTimeZoneRequired}
        />
      </div>
      <div className="flex tablet:flex-row flex-col">
        <div className="flex-3 pr-4 tablet:pb-0 pb-3">
          <Calendar
            name={startDate}
            label={startDateLabel}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={startDatePlaceholder}
            forceSetTouched
            showError={false}
            required
          />
        </div>
        <div className="tablet:pb-0 pb-3">
          <SelectTime
            name={startTime}
            label={startTimeLabel}
            placeholder={startTimePlaceholder}
            required={isTimeZoneRequired}
          />
        </div>
      </div>
      <div className="flex tablet:flex-row flex-col">
        <div className="flex-3 pr-4 tablet:pb-0 pb-3">
          <Calendar
            name={endDate}
            label={endDateLabel}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={endDatePlaceholder}
            forceSetTouched
            showError={false}
            required
          />
        </div>
        <div>
          <SelectTime
            name={endTime}
            label={endTimeLabel}
            placeholder={endTimePlaceholder}
            required={isTimeZoneRequired}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="text-xs text-red-600 mb-2">{errorMessage}</div>
      )}
      <div className="text-xs">
        {t(
          'create-conference.date-time-description',
          'If you need to select a date outside your Rotary Year, please contact <a href="mailto:{{email}}">Presidential Services</a>',
          { email: 'presidential.services@rotary.org' }
        )}
      </div>
    </DetailSection>
  );
};

export default DateTimeSection;
