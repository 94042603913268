import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { head } from 'lodash';

import { Button } from '@components/Button';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import DeleteConferenceModal from './DeleteConferenceModal';
import DistrictConferenceHeader from '../ConferencesList/DistrictConferenceHeader';
import DistrictConferenceItem from '../DistrictConferenceItem';

import { localizedNavigate } from '@utils/localized-navigate';

import { RenderActionsButton } from '@domui-domain/districts';
import { mapConferencesFeedbackAccessLevels } from '@domui-domain/districts/mappers';
import { DynamicData } from '@domui-domain/type';
import { useFetchConferenceFeedback } from '@domui-hooks/iam/useFetchConferenceFeedback';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import { createConferencePage } from '@domui-use-cases/districts';
import {
  ACCESS_LEVEL_TARGETS,
  ConferencesAccessLvl,
  GetConferencesResult,
  GetConferencesResults,
} from '@domui-use-cases/districts/conferences/type';

import { useTranslation } from '@external/react-i18next';

interface Props {
  conferences: GetConferencesResults;
  title: string;
  isUpcomingConference?: boolean;
  isPresRepConferencesHeader?: boolean;
  districtId?: string;
  isAddConferenceShown?: boolean;
  renderActionsButton: RenderActionsButton;
  renderActionsButtonMobile: RenderActionsButton;
  alignActionsRight?: boolean;
  conferenceAccessLvls?: ConferencesAccessLvl[] | null;
  fetchAllConferences: (id: string) => void;
}

const DistrictConferenceSection: React.FC<Props> = ({
  conferences,
  title,
  isUpcomingConference = false,
  isPresRepConferencesHeader,
  isAddConferenceShown = false,
  renderActionsButton,
  renderActionsButtonMobile,
  districtId,
  alignActionsRight = false,
  conferenceAccessLvls,
  fetchAllConferences,
}) => {
  const { t } = useTranslation();
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(
    false
  );
  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;

  const [
    selectedConference,
    setSelectedConference,
  ] = useState<GetConferencesResult | null>(null);

  const {
    data: feedbackAccessLevels,
    fetchConferenceFeedbackPermission,
  } = useFetchConferenceFeedback();

  const areConferences = conferences?.length > 0;
  useEffect(() => {
    const presRepDistrictId = head(conferences)?.districtId;

    if (!isUpcomingConference && areConferences) {
      fetchConferenceFeedbackPermission(requestorId, {
        data: conferences?.map((item: DynamicData) => ({
          target: `/conferences/${item?.id}/feedback`,
          arguments: {
            type: ACCESS_LEVEL_TARGETS.DISTRICT_CONFERENCE_FEEDBACK,
            targetDistrictId: districtId || presRepDistrictId,
          },
        })),
      });
    }
  }, [isUpcomingConference, areConferences]);

  const showCount =
    (isUpcomingConference && areConferences) || !isUpcomingConference;

  const selectConference = (conference: GetConferencesResult) => {
    setSelectedConference(conference);
    setIsDeletionModalOpen(true);
  };

  const cancelDeleteConference = () => {
    setSelectedConference(null);
    setIsDeletionModalOpen(false);
  };

  const handleDeleteConference = async () => {
    const conferenceId = selectedConference as GetConferencesResult;
    fetchAllConferences(conferenceId?.id);
  };

  const conferenceAccessLvlsFeedback = mapConferencesFeedbackAccessLevels(
    feedbackAccessLevels
  );

  return (
    <div
      className={classNames('conference-section', {
        'upcoming-conference': isUpcomingConference,
      })}
    >
      <h4
        className={
          isPresRepConferencesHeader
            ? 'alternate pl-5'
            : 'conference-section-header'
        }
      >
        {title} {showCount && `(${conferences?.length})`}
      </h4>

      <ResultsList>
        {showCount && (
          <ResultsListItem
            spacingY={4}
            borderBottomClass=""
            className="table-header"
          >
            <DistrictConferenceHeader />
          </ResultsListItem>
        )}
        {conferences.map(conference => (
          <ResultsListItem
            spacingY={4}
            key={conference?.id}
            className="table-header"
          >
            <DistrictConferenceItem
              conference={conference}
              onDelete={selectConference}
              renderActionsButton={renderActionsButton}
              renderActionsButtonMobile={renderActionsButtonMobile}
              alignActionsRight={alignActionsRight}
              conferenceAccessLvls={conferenceAccessLvls}
              conferenceFeedbackAccessLvls={conferenceAccessLvlsFeedback}
            />
          </ResultsListItem>
        ))}
        {isAddConferenceShown && (
          <div className="conference-section-add-button">
            <Button
              secondary
              className="mb-4"
              icon="ui/plus"
              clickHandler={() =>
                districtId &&
                localizedNavigate(createConferencePage(districtId))
              }
            >
              {t('conferences.add-conference', 'Add Conference')}
            </Button>
          </div>
        )}
      </ResultsList>
      <DeleteConferenceModal
        conference={selectedConference}
        isOpen={isDeletionModalOpen}
        onConfirm={handleDeleteConference}
        onCancel={cancelDeleteConference}
      />
    </div>
  );
};

export default DistrictConferenceSection;
