import React, { useContext, useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import toInteger from 'lodash/toInteger';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import { DORoles as DORolesType } from '@domain/districts';

import { getFormattedStartRY } from '@use-cases/districts';

import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import { DynamicData } from '@domui-domain/type';
import { useDominoOfficerRoles } from '@domui-hooks/useDominoOfficerRoles';
import Filters from '@domui-presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';
import { getMemberTypesOptions } from '@domui-presenters/web/pages/Districts/filters/getFiltersOptions';
import ContainerWithLinks from '@domui-presenters/web/pages/Districts/Members/ContainerWithLinks';
import DistrictMemberItem from '@domui-presenters/web/pages/Districts/Members/DistrictMemberItem';
import MembersList from '@domui-presenters/web/pages/Districts/Members/MembersList';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import {
  buildAGsPageUrl,
  buildOfficersPageUrl,
  DistrictContext,
  get3yrTermRoles,
  getAssignedFuture3yrTermRoleIds,
  getDOUnassignedRoles,
  getFormattedRoles,
  isRoleAssignedInFutureRY,
  mapLeadershipRoles,
} from '@domui-use-cases/districts';
import { useClubOptions } from '@domui-use-cases/districts/hooks/useClubOptions';
import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';
import { useDistrictOptions } from '@domui-use-cases/districts/hooks/useDistrictOptions';
import { DISTRICT_OFFICER_GOVERNOR_ROLES } from 'src/domui/domain/districts';
import { useFetchDistrictOfficers } from 'src/domui/hooks/useFetchDistrictOfficers';
import { useFetchDistrictOfficersData } from 'src/domui/repositories/districts/hooks/useFetchDistrictOfficersData';
import { getRotaryYear } from 'src/domui/utils/datetime';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/graphql';
import { Role } from '@typings/resolvers';

// type DistrictMember = {
//   id: string;
//   clubId: string;
//   clubName: string;
//   riClubId: string;
//   clubType: string;
//   type: string;
//   districtId: string;
// riDistrictId: string;
//   admissionDate: string;
//   terminationDate: string | null;
//   state: string;
//   country: string;
// };

interface Props extends RouteComponentProps {
  districtId: string;
  // isRotaractClub?: boolean;
  riDistrictId?: number;
  pageSize?: number;
}
const PAGE_SIZE = 10;

const getMemberItem = (
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[],
  nonGovernorsCurrent: DORolesType,
  nonGovernorsNext: DORolesType,
  districtId: string,
  assignedFuture3yrTermRoleIds: string[],
  isRoleFilterApplied?: boolean,
  rolesSelected?: string[]
) => (member: DynamicData) => {
  const activeMemberships = member?.membership;
  const associatedClubsInfo = member?.membership;

  const filteredActiveMemberships = activeMemberships.filter(
    ({ clubId }: { clubId: string }) =>
      associatedClubsInfo.find(
        (club: { clubId: string }) => club.clubId === clubId
      )
  );
  const getIsAssigned3yrTermRoleInFutureRY = ({
    districtLeadership,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) => {
    return (
      districtLeadership?.length === 1 &&
      isRoleAssignedInFutureRY(
        assignedFuture3yrTermRoleIds,
        districtLeadership?.[0].roleId
      )
    );
  };

  const haveMemberships = {
    honorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Honorary ||
        type === MembershipType.RotaractHonorary
    ),
    notHonorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Member || type === MembershipType.Rotaractor
    ),
  };

  return (
    <DistrictMemberItem
      roleSelected={rolesSelected}
      isRoleFilterApplied={isRoleFilterApplied}
      member={member}
      DORoles={DORoles.filter(role => role.isOfficer)}
      areRolesToAssignPresent={
        nonGovernorsCurrent.length > 0 || nonGovernorsNext.length > 0
      }
      districtId={districtId}
      isAssignedInFutureRY={getIsAssigned3yrTermRoleInFutureRY(member)}
      haveMemberships={haveMemberships}
    />
  );
};
export const processMembers = (members: DynamicData) => {
  return members?.map((member: DynamicData) => ({
    ...member,
    nameWithPrefixSuffix: `${member?.firstName ?? ''} ${member?.lastName ??
      ''}`,
    name: getFullName(
      member as {
        prefix: string;
        firstName: string;
        middleName: string;
        lastName: string;
        suffix: string;
      }
    ),

    email: member?.primaryEmail?.isPrimary ? member?.primaryEmail?.email : '',
    phoneNumber: member?.primaryPhone?.isPrimary
      ? member?.primaryPhone?.phone
      : null,
    associatedClubsInfo:
      member?.membership?.map(
        (club: {
          clubId: String;
          clubName: String;
          clubType: String;
          country: String;
          state: String;
        }) => ({
          __typename: 'Club',
          clubId: club?.clubId ?? '',
          clubName: club?.clubName ?? '',
          clubType: (club?.clubType ?? '').replace(/ /g, '_'),
          physicalLocation: {
            __typename: 'BaseAddress',
            country: club?.country ?? '',
            state: club?.state ?? '',
            city: null,
            internationalProvince: null,
          },
        })
      ) ?? [],
    activeMemberships:
      member?.membership?.map(
        (club: {
          type: String;
          clubName: String;
          clubId: String;
          admissionDate: String;
          terminationDate: String;
        }) => ({
          type: club?.type ?? '',
          clubName: club?.clubName ?? '',
          clubId: club?.clubId ?? '',
          admissionDate: club?.admissionDate ?? '',
          terminationDate: club?.terminationDate ?? '',
        })
      ) ?? [],
    activeLeaderships:
      member?.districtLeadership?.map((leadership: { role: String }) => {
        return {
          type: 'Leadership',
          role: leadership?.role ?? '',
          clubName: member?.membership?.clubName ?? '',
          clubId: member?.membership?.clubId ?? '',
        };
      }) ?? [],

    thisDistrictLeadership: [],
  }));
};

const DistrictMembers: React.FC<Props> = ({
  districtId,
  // location,
  // isRotaractClub = false,
  riDistrictId,
  pageSize = PAGE_SIZE,
}) => {
  const [context] = useContext(DistrictContext);
  const {
    data: leadershipRolesData,
    loading: leadershipRolesLoading,
  } = useDominoOfficerRoles();

  const {
    committeeManager: { isManager },
    selectedInfo: { term },
    termsToAssign,
  } = context;
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const memberTypesOptions = getMemberTypesOptions(t);

  // const canAddMembers = false; // isEditLevel(operationsAccess.memberships);

  const { options: roleOptions } = useClubOptions(districtId);
  const { options: districtOptions } = useDistrictOptions(districtId);

  const multiSelectOptions = {
    clubs: roleOptions,
    memberTypes: memberTypesOptions,
    districtRoles: districtOptions,
  };

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: future3yrTermOfficersData,
    loading: districtOfficersLoading,
    fetchDistrictOfficers,
  } = useFetchDistrictOfficers();

  // const {
  //   data: organizationTypeRoleMappingData,
  //   loading: isOrgTypeRelationLoading,
  //   roleMappingerror,
  //   fetchOrganizationTypeRoleMapping,
  // } = useFetchOrganizationTypeRoleMappingByOrganizationId();

  // useEffect(() => {
  //   if (districtId) {
  //     fetchOrganizationTypeRoleMapping(districtId);
  //   }
  // }, [districtId]);

  const isManagerTwoYears = isManager.currentTerm && isManager.nextTerm;
  const isElect = !isManager.currentTerm && isManager.nextTerm;

  const getManagerTermYear = (endDate: string | undefined) =>
    toInteger(endDate) - 1;

  const mapPayload = {
    organizationType: ['District'],
    validOn: getFormattedStartRY(getManagerTermYear(term?.endDate)),
    officerManagement: 'District',
    rolesToInclude: DISTRICT_OFFICER_GOVERNOR_ROLES,
  };

  const mapNextYearPayload = {
    organizationType: ['District'],
    validOn: isManagerTwoYears
      ? getFormattedStartRY(getManagerTermYear(termsToAssign[1].endDate))
      : getManagerTermYear(term?.endDate) + 1,
    officerManagement: 'District',
    rolesToInclude: DISTRICT_OFFICER_GOVERNOR_ROLES,
  };

  const leadershipRoles = {
    roles: leadershipRolesData?.response
      ? mapLeadershipRoles(mapPayload, leadershipRolesData?.response)
      : [],
  };
  const nextYearleadershipRoles = {
    roles: leadershipRolesData?.response
      ? mapLeadershipRoles(mapNextYearPayload, leadershipRolesData?.response)
      : [],
  };
  useEffect(() => {
    const threeYrTermRoles = get3yrTermRoles(
      getFormattedRoles(leadershipRoles)
    );
    const districtOfficersPayload = {
      page: 1,
      pageSize: 100,
      roles: threeYrTermRoles,
      rotaryYear: String(Number(getRotaryYear()) + 1),
      riDistrictId,
    };
    if (threeYrTermRoles.length && riDistrictId) {
      fetchDistrictOfficers(districtId, districtOfficersPayload);
    }
  }, [districtId, riDistrictId]);

  const {
    selectedValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId);
  const isRoleFilterApplied = Boolean(
    filterValues?.filters?.districtRoles?.length > 0
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const officersData: DynamicData = {
    // if user is current or future District Manager (isElect),
    // we will have officers data for particular year  in `selectedTerm`,
    // since `term?.endDate` in this case is current or next year`s date, accordingly
    selectedTerm: useFetchDistrictOfficersData(
      districtId,
      riDistrictId,
      term?.endDate
    ),
    nextTerm: useFetchDistrictOfficersData(
      districtId,
      riDistrictId,
      isManagerTwoYears ? termsToAssign[1].endDate : ''
    ),
  };
  const rolesSelected = filterValues?.filters?.districtRoles?.map(
    ({ value }) => value
  );

  const { districtMembersSearchData, loading } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    selectedValues
  );
  if (loading || leadershipRolesLoading || districtOfficersLoading) {
    return <Loading />;
  }

  if (
    !districtMembersSearchData ||
    !districtMembersSearchData?.districtMembersDetails
  ) {
    return null;
  }
  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const totalCount = districtMembersSearchData?.totalCount || 0;
  const total = `(${totalCount})`;

  // added for running application for now will be removed later
  const DORoles = [
    ...(leadershipRoles?.roles || []),
    ...(nextYearleadershipRoles?.roles || []),
  ];
  const [, nonGovernorsCurrent] = getDOUnassignedRoles({
    roles: getFormattedRoles({ roles: leadershipRoles?.roles || [] }),
    officers: officersData.selectedTerm.officersData,
    year: term?.endDate || '',
  });
  const [, nonGovernorsNext] =
    isManagerTwoYears || isElect
      ? getDOUnassignedRoles({
          roles: getFormattedRoles({
            roles: nextYearleadershipRoles?.roles || [],
          }),
          ...(isElect
            ? {
                officers: [...officersData.selectedTerm.officersData],
                year: term?.endDate || '',
              }
            : {
                officers: [...officersData.nextTerm.officersData],
                year: termsToAssign[1].endDate,
              }),
        })
      : [[], []];
  const assignedFuture3yrTermRoleIds = getAssignedFuture3yrTermRoleIds(
    future3yrTermOfficersData,
    term
  );
  const members = districtMembersSearchData.districtMembersDetails;

  const processedMembers = processMembers(members);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <ContainerWithLinks
        isDistrictOfficer
        isAssistantGovernor
        assistantGovernorsUrl={buildAGsPageUrl(districtId)}
        officersUrl={buildOfficersPageUrl(districtId)}
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <MembersList
        pageSize={pageSize}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={districtMembersSearchData.totalCount}
        members={processedMembers || []}
        tableTitle={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('district-management.district-members', 'District members')}{' '}
            {total}
          </h2>
        }
        filters={
          <Filters
            {...{
              multiSelectOptions: { ...multiSelectOptions },
              initialFilterValues: { ...selectedValues },
              applyFilter: handleApplyFilters,
              resetFilter: handleResetFilters,
              filtersListToShow: [
                MEMBER_FILTERS.CLUB_NAMES,
                MEMBER_FILTERS.MEMBER_TYPES,
                MEMBER_FILTERS.DISTRICT_ROLES,
              ],
            }}
          />
        }
        memberItem={getMemberItem(
          DORoles,
          nonGovernorsCurrent,
          nonGovernorsNext,
          districtId,
          assignedFuture3yrTermRoleIds,
          isRoleFilterApplied,
          rolesSelected
        )}
        showFiltersInContextMenu
        contextMenuTitle={t(
          'district.filters.members',
          'Filter District members'
        )}
        areFiltersEmpty={!total}
      />
    </>
  );
};

export default DistrictMembers;
