import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Conference from '@domui-utils/workflow/conference';

// Custom hook to handle API calls
export const useDeleteConference = () => {
  const [data] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const deleteConference = async (conferenceId: string) => {
    let status = false;
    try {
      if (conferenceId) {
        setLoading(true);
        setError(null);
        const wfService = new Conference();
        await wfService.deleteConference(conferenceId);
        status = true;
        setLoading(false);
      }
      setLoading(false);
      status = false;
    } catch (err) {
      status = false;
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      status = false;
      setLoading(false);
    }
    return status;
  };

  return { data, loading, error, deleteConference };
};
