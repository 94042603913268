import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
// import NotFound from '@components/Routing/NotFound';

// import { CRUDAction, CRUDResource } from '@domain/abilities';

import { findDefaultTimeZone } from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { CREATE_CONFERENCE_INITIAL_VALUES } from '@domui-domain/districts/conferences/constants';
import {
  getInitialConferenceFields,
  mapFormResultToCreateConferenceInput,
} from '@domui-domain/districts/mappers';
import { GetConferenceDetailsResult } from '@domui-domain/districts/mappers/types';
import { CreateConferenceFormResult } from '@domui-domain/districts/types';
import { useFetchAdditionalParticipatingDistricts } from '@domui-hooks/conference/useFetchAdditionalParticipatingDistricts';
import { useUpdateConference } from '@domui-hooks/conference/useUpdateConference';
import { useViewConference } from '@domui-hooks/conference/useViewConference';
import ConferenceForm from '@domui-presenters/web/components/ConferenceForm';
import {
  useFetchCountryStates,
  useFetchPRCountryStates,
} from '@domui-repositories/dominoCountry';
import {
  getConferencePage,
  // getFutureManagementYear,
  getIsManagerByYear,
} from '@domui-use-cases/districts/conferences';
import { getFutureManagementYear } from '@domui-use-cases/districts/conferences/getManagementYear';
import { GetAccessLevelsQuery } from '@domui-use-cases/districts/conferences/type';
import { normalizeCountries } from '@domui-use-cases/districts/normalizers/normalizeCountries';

import { useTranslation } from '@external/react-i18next';

export type CountriesQuery = {
  id: string;
  shortCode: string;
  countryName: string;
  RequireState: boolean;
};

export type GetTimeZonesQuery = {
  id: string;
  value: string;
  timeZoneName: string;
  location: string;
  offset: string;
};

export type AdditionalParticipatingDistrictsType = {
  conferenceAdditionalParticipatingDistrictPk: string;
  conferencePk: string;
  organizationPk: string;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  status: string;
  nfKey: string;
  isPrimary: boolean;
};

export interface Props {
  districtId: string;
  conferenceId: string;
  initialValues?: CreateConferenceFormResult;
  accessLevelData?: GetAccessLevelsQuery;
  // canEditConferenceFn:
  //   | ((
  //       action: CRUDAction,
  //       resource: CRUDResource,
  //       id?: string | undefined
  //     ) => boolean)
  //   | (() => undefined);
  countriesData?: CountriesQuery;
  countriesLoading: boolean;
  timeZones?: GetTimeZonesQuery[];
  timeZonesLoading: boolean;
  // isIndividualLoading: boolean;
}

const DistrictEditConference: React.FC<RouteComponentProps & Props> = ({
  districtId,
  conferenceId,
  initialValues,
  accessLevelData,
  // canEditConferenceFn,
  countriesData,
  countriesLoading,
  timeZones,
  timeZonesLoading,
  // isIndividualLoading,
}) => {
  const { addSuccess, addError } = useNotifications();
  const { t } = useTranslation();

  const {
    loading: loadingStates,
    data: countryStatesData,
    fetchCountryStates,
  } = useFetchCountryStates();
  const {
    loading: loadingPRStates,
    data: countryPRStatesData,
    fetchPRCountryStates,
  } = useFetchPRCountryStates();

  const {
    data: additionalParticipatingDistricts,
    loading: AdditionalParticipatingDistrictsLoading,
    getAdditionalParticipatingDistricts,
  } = useFetchAdditionalParticipatingDistricts();

  useEffect(() => {
    getAdditionalParticipatingDistricts(conferenceId);
  }, []);

  const {
    status,
    loading: loadingUpdateConference,
    error: updateConferenceError,
    updateConference,
  } = useUpdateConference();

  const { data, loading, viewConference } = useViewConference();

  useEffect(() => {
    viewConference(conferenceId);
  }, []);

  const formattedCountries = normalizeCountries(countriesData);
  const currentConference = data;

  // const canEditConference = canEditConferenceFn(
  //   CRUDAction.Update,
  //   CRUDResource.Conference,
  //   conferenceId
  // );

  // if (!canEditConference) {
  //   return <NotFound default />;
  // }

  if (
    AdditionalParticipatingDistrictsLoading ||
    loading ||
    loadingUpdateConference ||
    timeZonesLoading
  ) {
    return <Loading />;
  }

  const is2023YManager = getIsManagerByYear(accessLevelData, 2023);

  const handleSubmit = (originalTimeZone: string) => async (
    formData: CreateConferenceFormResult
  ) => {
    try {
      const requestBody = mapFormResultToCreateConferenceInput(
        formData,
        formattedCountries,
        timeZones,
        originalTimeZone
      );
      await updateConference(
        conferenceId,
        requestBody,
        additionalParticipatingDistricts as AdditionalParticipatingDistrictsType[]
      );
      if (status) {
        await viewConference(conferenceId);
        addSuccess(t('update-conference.success', 'Success.'), {
          id: 'form.success',
        });
      } else {
        addError(
          updateConferenceError?.description
            ? updateConferenceError?.description
            : t(
                'update-conference.error',
                'An error occurred while updating conference'
              )
        );
      }
    } catch (e) {
      addError(
        t(
          'update-conference.error',
          'An error occurred while updating conference'
        )
      );
    } finally {
      localizedNavigate(getConferencePage(districtId));
    }
  };

  const handleBackRedirect = () => {
    localizedNavigate(getConferencePage(districtId));
  };

  const defaultTimeZone = findDefaultTimeZone(timeZones || []);

  const defaultValues = initialValues || {
    ...CREATE_CONFERENCE_INITIAL_VALUES,
    dateTime: {
      ...CREATE_CONFERENCE_INITIAL_VALUES.dateTime,
      timeZone: defaultTimeZone,
    },
  };

  const states = countryStatesData?.states || [];
  const prStates = countryPRStatesData?.states || [];

  const formValues = currentConference
    ? getInitialConferenceFields(
        currentConference as GetConferenceDetailsResult,
        formattedCountries,
        defaultTimeZone,
        timeZones,
        !!states.length,
        !!prStates.length
      )
    : { ...defaultValues, districtId };

  const originalTimeZone = formValues.dateTime.timeZone || '';

  return (
    <ConferenceForm
      initialValues={formValues as CreateConferenceFormResult}
      countriesData={formattedCountries}
      countriesLoading={countriesLoading}
      loadingStates={loadingStates}
      countryStatesData={states}
      timeZones={timeZones || []}
      fetchCountryStates={fetchCountryStates}
      handleBackRedirect={handleBackRedirect}
      handleSubmit={handleSubmit(originalTimeZone)}
      backButtonPath={getConferencePage(districtId)}
      is2023YManager={is2023YManager}
      futureManagementYear={getFutureManagementYear(accessLevelData)}
      formTitle={t(
        'edit-district-conference.title',
        'Edit the District Conference'
      )}
      loadingPRStates={loadingPRStates}
      fetchPRCountryStates={fetchPRCountryStates}
      countryPRStatesData={prStates}
    />
  );
};

export default DistrictEditConference;
