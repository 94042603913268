// Libs
import React from 'react';

// Components
import AddressFields from './AddressFields';

// Types
import {
  AddressLabels,
  AddressOptions,
  AddressPlaceholder,
  Country,
  Maybe,
  PhoneLabels,
  State,
} from './types';

const FORMIK_SUFFIX = 'address';
export interface AddressSectionProps {
  // title?: string;
  countryId?: string | null;
  fieldNames?: AddressOptions;
  labels?: AddressLabels & Maybe<PhoneLabels>;
  placeholders?: AddressPlaceholder;
  suffix?: string;
  countriesData: Country[];
  countriesLoading: boolean;
  countryStatesData: State[];
  loadingStates: boolean;
  fetchCountryStates: (options?: string) => void;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  countryId,
  fieldNames,
  placeholders,
  labels,
  suffix = FORMIK_SUFFIX,
  countriesData,
  countriesLoading,
  loadingStates,
  countryStatesData,
  fetchCountryStates,
}) => {
  return (
    <AddressFields
      {...{
        countryId,
        fieldNames,
        placeholders,
        labels,
        suffix,
        countriesData,
        countriesLoading,
        loadingStates,
        countryStatesData,
        fetchCountryStates,
      }}
    />
  );
};

export default AddressSection;
