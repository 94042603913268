import { useState } from 'react';

import { isEqual } from 'lodash';

import { SearchFormValues } from '@domui-components/Formik/SearchMembersForm/types';
import {
  Filter,
  MEMBER_FILTERS,
  MemberFilter,
  // MembershipStatusOption,
} from '@domui-presenters/web/components/DistrictMembersFilters/types';
import { DynamicData } from 'src/domui/domain/type';
import { INITIAL_FILTERS } from 'src/domui/presenters/web/components/DistrictMembersFilters/constants';
import { getMemberTypesOptions } from '@domui-presenters/web/pages/Districts/filters/getFiltersOptions';
import { useTranslation } from '@external/react-i18next';
import { getDefaultFilters } from '@domui-use-cases/districts';

export interface Option {
  id: string;
  value: string;
  label: string;
}

export const useDistrictMembersFilters = (clubId: string) => {
  const INITIAL_FILTER_VALUES = {
    orgId: clubId,
    name: '',
    email: '',
    filters: {
      districtRoles: [],
      memberTypes: [],
      clubs: [],
    },
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();

  const memberTypesOptions = getMemberTypesOptions(t);

  // Set predefined filters if queryParams are set or initial values
  const defaultSelectedFilters = getDefaultFilters(
    INITIAL_FILTERS,
    memberTypesOptions
  );
  const [selectedValues, setSelectedValues] = useState<Filter>(
    defaultSelectedFilters as Filter
  );

  const [filterValues, setFilterValues] = useState<MemberFilter>(
    INITIAL_FILTER_VALUES
  );

  const handleResetFilters = (filterName?: string): void => {
    if (
      filterName &&
      Array.isArray(
        filterValues.filters[filterName as keyof typeof filterValues.filters]
      ) &&
      (filterValues.filters[
        filterName as keyof typeof filterValues.filters
      ] as []).length === 0
    ) {
      return;
    }
    setCurrentPage(1);
    // Check if filterName exists in Enum object as value
    // and reset state for this filterName
    if (
      filterName &&
      Object.values(MEMBER_FILTERS).includes(filterName as MEMBER_FILTERS)
    ) {
      setFilterValues({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          [filterName]: [],
        },
      });
      setSelectedValues(prev => ({
        ...prev,
        [filterName]: [],
      }));
    } else {
      setFilterValues(INITIAL_FILTER_VALUES);
      setSelectedValues({
        districtRoles: [],
        memberTypes: [],
        clubs: [],
      });
    }
  };

  const handleSearch = ({ orgId, name, email }: SearchFormValues) => {
    setCurrentPage(1);
    setFilterValues({
      orgId,
      name,
      email,
      filters: {
        districtRoles: [],
        memberTypes: [],
        clubs: [],
      },
    });
  };

  const handleApplyFilters = (values: Option[], name: MEMBER_FILTERS) => {
    const value = values.map(item => item.value);

    const updatedFilters: DynamicData = {
      orgId: clubId,
      name: '',
      email: '',
      filters: {
        clubs: filterValues.filters.clubs || [],
        memberTypes: filterValues.filters.memberTypes || [],
        districtRoles: filterValues.filters.districtRoles || [],
      },
    };

    if (name === MEMBER_FILTERS.CLUB_NAMES) {
      updatedFilters.filters.clubs = value;
      setSelectedValues(prev => ({
        ...prev,
        clubs: values,
      }));
    } else if (name === MEMBER_FILTERS.MEMBER_TYPES) {
      updatedFilters.filters.memberTypes = value;
      setSelectedValues(prev => ({
        ...prev,
        memberTypes: values,
      }));
    } else if (name === MEMBER_FILTERS.DISTRICT_ROLES) {
      updatedFilters.filters.districtRoles = value;
      setSelectedValues(prev => ({
        ...prev,
        districtRoles: values,
      }));
    }

    if (!isEqual(filterValues, updatedFilters)) {
      setCurrentPage(1);
      setFilterValues(updatedFilters as MemberFilter);
    }
  };

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    selectedValues,
    setSelectedValues,
    filterValues,
    setFilterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  };
};
