import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
// import { GetAccessLevelsQuery } from '@domui-use-cases/districts';
import Authorizer from '@domui-utils/workflow/authorizer';

// Custom hook to handle API calls
export const useFetchConferenceList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DynamicData>(null);

  const [error, setError] = useState<DomuiError>(null);

  const fetchConferencePermission = async (
    requestor: string,
    data: DynamicData
  ) => {
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Authorizer();
      const response = await wfService.fetchConferenceList(requestor, data);
      setData(response);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchConferencePermission };
};
