import { find, isEmpty } from 'lodash';

import {
  CommitteeMemberData,
  DistrictMember,
  isAG,
  TermToAssign,
} from '@domui-domain/districts';

import { isDateBetween } from '@use-cases/districts';

import {
  ActiveMembership,
  DistrictLeadership,
  MembershipType,
} from '@typings/graphql';
import { Club } from '@typings/operations';

export const isOfficer = (
  person: DistrictMember | CommitteeMemberData
): person is CommitteeMemberData =>
  '__typename' in person && person.__typename === 'CommitteeMemberData';

export const isMember = (
  person: DistrictMember | CommitteeMemberData
): person is DistrictMember => !isOfficer(person);

export const getActiveMembership = (
  member: DistrictMember | CommitteeMemberData
) => {
  if (isMember(member)) {
    return find(
      member.activeMemberships,
      ({ type, terminationDate }) =>
        type === MembershipType.Member && !terminationDate
    );
  }

  return undefined;
};

export const getActiveMembershipsOnAddDistrictOfficer = (
  member: DistrictMember | CommitteeMemberData,
  filters?: { [key in MembershipType]?: boolean },
  isEnabledFilters?: boolean
) => {
  if (isMember(member)) {
    if (isEnabledFilters && filters) {
      return member.activeMemberships.filter(
        ({ type, terminationDate }) =>
          ((filters.Rotaractor && type === MembershipType.Rotaractor) ||
            (filters.Member && type === MembershipType.Member)) &&
          !terminationDate
      );
    }

    return member.activeMemberships.filter(
      ({ type, terminationDate }) =>
        (type === MembershipType.Rotaractor ||
          type === MembershipType.Member) &&
        !terminationDate
    );
  }

  return [];
};

export const getActiveAssociatedClub = (
  member: DistrictMember | CommitteeMemberData,
  activeMembership?: ActiveMembership
) =>
  activeMembership
    ? member.associatedClubsInfo.filter(
        ({ clubId }) => clubId === activeMembership.clubId
      )
    : [];

export const getActiveAssociatedClubs = (
  associatedClubsInfo: Club[],
  activeMemberships?: ActiveMembership[]
) =>
  activeMemberships
    ? associatedClubsInfo.filter(club =>
        activeMemberships.find(({ clubId }) => club.clubId === clubId)
      )
    : [];

export const getIsRotaractor = (
  member: DistrictMember | CommitteeMemberData
) => {
  if (isMember(member)) {
    return !isEmpty(
      find(
        member.activeMemberships,
        ({ type, terminationDate }) =>
          type === MembershipType.Rotaractor && !terminationDate
      )
    );
  }

  return false;
};

export const getMemberEmail = (
  member: DistrictMember | CommitteeMemberData
) => {
  const { email } = member;

  if (isMember(member)) {
    return member?.email ? email : null;
  }

  return email;
};

export const getMemberPhone = (
  member: DistrictMember | CommitteeMemberData
) => {
  const { phoneNumber } = member;

  if ('sharingPermissionsResult' in member) {
    return member?.sharingPermissionsResult?.phone ? phoneNumber : null;
  }

  return phoneNumber;
};

export const getAssociatedClubsInfo = (
  member: DistrictMember | CommitteeMemberData,
  activeAssociatedClub: Club[]
) => {
  if (isMember(member)) {
    return activeAssociatedClub;
  }

  return member.associatedClubsInfo;
};

export const getAssociatedClubsInfoOnAddDistrictOfficer = (
  activeAssociatedClub: Club[],
  associatedClubsInfo: Club[],
  member: DistrictMember | CommitteeMemberData
) => {
  if (isMember(member)) {
    return activeAssociatedClub;
  }

  return associatedClubsInfo;
};

export const isCurrentTermLeadership = (
  term: TermToAssign | null,
  endDate: string
) =>
  isDateBetween(
    endDate,
    {
      start: `${term?.startDate}-07-01`,
      end: `${term?.endDate}-06-30`,
    },
    'd'
  );

export const isDisabledAction = (
  term: TermToAssign | null,
  leaderships?: DistrictLeadership[],
  isPreviousAG?: boolean
) =>
  isPreviousAG &&
  Boolean(
    leaderships?.find(
      leadership =>
        isAG(leadership.roleId) &&
        isCurrentTermLeadership(term, leadership.endDate)
    )
  );
