import React, { useContext, useState } from 'react';

import { useLocation } from '@reach/router';
import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';

import UnassignRoleConfirm from './UnassignRoleConfirm';
import MultipleRoles from '../MultipleRoles';

import { CommitteeAppointment, Step } from '@domui-domain/districts';

import {
  DistrictContext,
  extractRoleInfo,
  getBackUrl,
  use3yrRoleConfirmation,
  useCAReplaceRemoveContext,
  // useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useModal } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useManageDistrictLeadership } from '@domui-repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { pushLocalisedUrl } from '@utils/browserHistory';
// import { getRotaryYear } from '@domui-utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const UnnassignRole: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const { t } = useTranslation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const [context, setContext] = useContext(DistrictContext);
  const location = useLocation();
  const { addSuccess } = useNotifications();

  const { updateStorage, getStorageData } = useLocalStorage<
    CommitteeAppointment
  >();

  const {
    step,
    selectedInfo: { unassignFrom, dateToRemoveRole, role },
    operationType,
  } = context;

  const { data: storageData } = getStorageData('backUrl') || {};
  const storageDataBackUrl = storageData?.backUrl;

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const backUrl =
    backUrlFromState ||
    storageDataBackUrl ||
    getBackUrl(operationType, districtId);

  const {
    isLoading,
    updateDLForFutureRY,
    removeFromAssignedRoleError,
  } = useManageDistrictLeadership({
    districtId,
    riDistrictId,
  });

  const {
    isCurrentTerm,
    isOneRole,
    resetContextValues,
    selectedLeadership,
    districtLeadership,
  } = useCAReplaceRemoveContext();

  const {
    isCommunicationConfirmed,
    renderConfirmation,
  } = use3yrRoleConfirmation();

  const isStepTwo = step === 2;

  const STEP_COUNT = isOneRole ? 1 : 2;

  const isStepMax = step === STEP_COUNT;

  useErrorHandling(
    removeFromAssignedRoleError?.description
      ? t('Assign-RoleToMember.error', removeFromAssignedRoleError?.description)
      : 'An error occurred while removing from assigned role.',
    !!removeFromAssignedRoleError,
    'Remove-FromAssignedRole.error'
  );

  const isNextStepVisited = JSON.parse(
    window.localStorage.getItem('isNextStepVisited') || 'false'
  );

  const onCancelHandler = () => {
    resetContextValues(updateStorage);
    window.localStorage.removeItem('isNextStepVisited');
    window.stopBrowserNavigate = false;
    localizedNavigate(backUrl);
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax,
    isNextStepVisited,
    onNavigate: onCancelHandler,
  });

  const selectedLeadershipDateToRemove = moment(dateToRemoveRole);

  const submitBtnLabel = isOneRole
    ? t('action-button.remove', 'Remove')
    : t('action-button.continue', 'Continue');

  const showNotification = (isSuccess?: boolean) =>
    isSuccess &&
    addSuccess(
      t(
        'remove-leadership.success',
        'Removed {{ individualName }} from {{ roleName }} leadership position.',
        {
          individualName: unassignFrom?.nameWithPrefixSuffix,
          roleName: getClubRoleTValue(t, role?.name as string),
        }
      )
    );

  const handleSubmit = async () => {
    if (isOneRole || isStepTwo) {
      try {
        const leadershipId = selectedLeadership?.id || '';

        await updateDLForFutureRY(leadershipId, selectedLeadershipDateToRemove);

        showNotification(true);
        onCancelHandler();
      } catch (error) {
        showNotification();
      }
    } else {
      setContext(prevState => ({
        ...prevState,
        step: (step + 1) as Step,
      }));
    }

    window.localStorage.removeItem('isNextStepVisited');
    setGlobal(false);
    pushLocalisedUrl(backUrl);
    window.scrollTo(0, 0);
  };

  const goToPrevStep = () => {
    if (isStepMax && step === 1) {
      onCancelHandler();
      return;
    }

    if (isStepMax) {
      window.localStorage.setItem('isNextStepVisited', 'true');
      window.scrollTo(0, 0);
    }

    setContext(prevState => ({
      ...prevState,
      step: (step - 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        dateToRemoveRole: null,
      },
    }));

    setGlobal(false);
  };

  const handleSelectDate = (date: Date) => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        dateToRemoveRole: date,
      },
    }));
  };

  const handleChangeRole = (roleId: string) => {
    const selectedRole = districtLeadership?.find(
      leadership => leadership.roleId === roleId
    );
    if (selectedRole) {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          role: {
            id: selectedRole.roleId,
            name: selectedRole.role,
            termYears: selectedRole.termYears,
          },
        },
      }));
    }
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const modalBackHandler = () => {
    setIsBack(true);
    show(true);
  };

  const isSubmitBtnDisabled =
    (!isOneRole && step === 1 && role === null) ||
    (role?.termYears === 3 && isStepMax && !isCommunicationConfirmed);

  if (!unassignFrom || !operationType) {
    return null;
  }

  const isNotOneRoleAndNotFirstStep = !isOneRole && step !== 1;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageSteps
        className="p-0"
        backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
        backHandler={
          isOneRole || isNotOneRoleAndNotFirstStep
            ? modalBackHandler
            : onCancelHandler
        }
        showModal={isStepMax ? () => show(true) : undefined}
        step={step}
        total={STEP_COUNT}
        navClassName="mt-6 mb-0 text-small font-bold"
      >
        {isOneRole || isStepTwo ? (
          <UnassignRoleConfirm
            member={{
              ...unassignFrom,
            }}
            roleInfo={extractRoleInfo(t, role, selectedLeadership)}
            handleSelectDate={isCurrentTerm ? handleSelectDate : undefined}
            defaultDate={dateToRemoveRole as Date}
          />
        ) : (
          <MultipleRoles
            title={t('select-role-to-remove.title', 'Select a Role to Remove')}
            member={unassignFrom}
            legend={t(
              'select-role-to-remove.legend',
              'Select a Role to Remove'
            )}
            roleId={role?.id}
            handleChangeRole={handleChangeRole}
            // currentYear={getRotaryYear()}
            // districtId={districtId}
            // riDistrictId={riDistrictId}
          />
        )}
        {isStepMax && renderConfirmation()}
        <ActionButtons
          submitBtnLabel={submitBtnLabel}
          cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
          onCancel={isStepMax ? modalOnCancelHandler : onCancelHandler}
          isSubmitDisabled={isSubmitBtnDisabled}
          onSubmit={handleSubmit}
        />
      </PageSteps>
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={isBack ? goToPrevStep : onCancelHandler}
      />
    </>
  );
};

export default UnnassignRole;
