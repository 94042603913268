import { DynamicData } from '@domui-domain/type';

export type Country = {
  id: string;
  shortCode: string;
  countryName: string;
  RequireState: boolean;
};

export const normalizeCountries = (countriesData: DynamicData): Country[] => {
  return countriesData?.map(
    ({ id, countryName, shortCode, RequireState }: Country) => ({
      id,
      countryName,
      shortCode,
      RequireState,
    })
  );
};
