import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Loading from '@components/Loading';
import ConfirmDistOfficerForm from '@presenters/web/components/Forms/ConfirmDistOfficerForm';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';

import {
  getActiveMembership,
  isRoleAvailable,
} from '../AssignRoleStepConfirm/utils';

import {
  CommitteeAppointment,
  OperationType,
  PartTimeStartDate,
  Step,
  TermToAssign,
} from '@domui-domain/districts';
import { FORM_STATUSES } from '@domain/storage';

import {
  buildOfficersPageUrl,
  buildRoleName,
  defaultContextState,
  DistrictContext,
  getBackUrl,
  getCurrentTermByTermYears,
  getFutureTermByTermYears,
  getStartDateToAssign,
  getUnassignedTermStartDate,
  isCurrentTerm,
  useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useManageDistrictLeadership } from '@domui-repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { pushLocalisedUrl } from '@utils/browserHistory';
import { getRotaryYear } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useLogger } from '@hooks/logger';

import { LogLevel, Maybe } from '@typings/graphql';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  officerId: string;
  officerEmail?: string | null;
  officerName?: string | null;
};

const ConfirmDistrictExecSecretary: React.FC<Props> = ({
  districtId,
  riDistrictId,
  officerId,
  officerEmail,
  officerName,
}) => {
  const { t } = useTranslation();

  const { addError, addSuccess } = useNotifications();
  const { addLog } = useLogger();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const [context, setContext] = useContext(DistrictContext);

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax: true,
  });

  const { updateStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    selectedInfo: { assignee, role, term },
    operationType,
    committeeManager: { isManager },
    managedRYs: { current, future },
  } = context;

  const {
    assignDL,
    isLoading: assignDistrictLeadershipLoading,
    error,
  } = useManageDistrictLeadership({
    districtId,
    riDistrictId,
  });

  useErrorHandling(error?.description, !!error, 'member-details.error');

  const backUrl = getBackUrl(operationType, districtId);
  const currentRY = getRotaryYear();
  const yearNow = Number(currentRY);
  const activeMembership = assignee && getActiveMembership(assignee);

  const getNowTerms = (selectedTerm: Maybe<TermToAssign>) => {
    const { nowTerm, afterNowTerm } = isManager;
    let termForNowYear = null;

    if (nowTerm && role && isRoleAvailable(role, current)) {
      const termForNow = getCurrentTermByTermYears(
        yearNow,
        role?.termYears || 0
      );

      if (
        moment(termForNow?.startDate).isSameOrAfter(
          selectedTerm?.startDate,
          'year'
        )
      ) {
        termForNowYear = termForNow;
      }
    }

    const termForElect =
      afterNowTerm && role && isRoleAvailable(role, future)
        ? getFutureTermByTermYears(yearNow, role?.termYears || 0)
        : null;

    return { termForNowYear, termForElect };
  };

  const { termForNowYear, termForElect } = getNowTerms(term);

  const termToReassign = termForNowYear || termForElect;

  const resetContextValues = () => {
    const newValues = {
      ...context,
      operationType: null,
      step: 1 as Step,
      managedRYs: { ...defaultContextState.managedRYs },
      selectedInfo: {
        ...context.selectedInfo,
        role: defaultContextState.selectedInfo.role,
        term: context.termsToAssign[0],
        assignee: defaultContextState.selectedInfo.assignee,
      },
    };
    updateStorage({ ...newValues, status: FORM_STATUSES.FINISHED });
  };

  const minDate = moment(`${yearNow - 1}-07-01`).toDate();

  const [partTimeStartDate, setPartTimeStartDate] = useState<PartTimeStartDate>(
    {
      value: null,
      minDate,
    }
  );

  useEffect(() => {
    if (
      !partTimeStartDate.value &&
      (isCurrentTerm(term) || isCurrentTerm(termToReassign))
    ) {
      const startDateRY = `${yearNow - 1}-07-01`;
      const admissionDate = moment(
        activeMembership?.admissionDate || startDateRY
      );

      const today = moment();
      const unassignedTermStartDate =
        role?.terms?.find(term => term.isAssignable)?.startDate ||
        getUnassignedTermStartDate(role?.terms || []);

      const lastAvailableTermStartDate = moment(
        unassignedTermStartDate || startDateRY
      );

      const availableTermOrToday =
        lastAvailableTermStartDate.isSameOrBefore(today, 'd') ||
        !lastAvailableTermStartDate.isValid()
          ? today
          : lastAvailableTermStartDate;

      // if admission date is in current year then make calculations including admissionDate
      if (
        admissionDate.isValid() &&
        (admissionDate.isSameOrBefore(availableTermOrToday, 'd') ||
          admissionDate.isSameOrAfter(availableTermOrToday, 'd'))
      ) {
        const formattedAdmissionToDate = admissionDate.toDate();
        // if admission date is after formattedDate = value should be admission
        if (admissionDate.isSameOrAfter(availableTermOrToday, 'd')) {
          setPartTimeStartDate({
            value: formattedAdmissionToDate,
            minDate: formattedAdmissionToDate,
          });
          return;
        }
        if (
          admissionDate.isSameOrBefore(availableTermOrToday, 'd') &&
          admissionDate.isSameOrAfter(term?.startDate, 'd')
        ) {
          // if we have partial term & admission date is before
          if (admissionDate.isSameOrBefore(unassignedTermStartDate, 'd')) {
            const convertedPartialTermDate = moment(
              unassignedTermStartDate
            ).toDate();
            setPartTimeStartDate({
              value: convertedPartialTermDate,
              minDate: convertedPartialTermDate,
            });
          } else {
            setPartTimeStartDate({
              value: formattedAdmissionToDate,
              minDate: formattedAdmissionToDate,
            });
          }
          return;
        }
      }

      setPartTimeStartDate({
        value: lastAvailableTermStartDate.toDate(),
        minDate: lastAvailableTermStartDate.toDate(),
      });
    }
  }, [term, role, termToReassign, activeMembership]);

  const createDate = (year: number) => new Date(`${year}-06-30`);

  const handleSelectDate = (date: Date) =>
    setPartTimeStartDate(prevState => ({
      ...prevState,
      value: date,
    }));

  const handleSubmit = async () => {
    try {
      if ((officerId || assignee) && role && term) {
        const startDateToAssign = getStartDateToAssign(
          term as TermToAssign,
          termToReassign as TermToAssign,
          false,
          partTimeStartDate
        );
        await assignDL(officerId, role.id, startDateToAssign).then(() => {
          addSuccess(
            t(
              'assign-officer.confirmation.success',
              'Success! {{officerFullName}} has been assigned the role of {{role}}.',
              {
                officerFullName: officerName,
                role: role.name,
              }
            )
          );
          resetContextValues();
          localizedNavigate(buildOfficersPageUrl(districtId));
        });
      } else {
        addLog({
          level: LogLevel.Error,
          message: `
            An error occurred. Please provide
            assigneeId: ${assignee?.id || officerId},
            assigneeEmail: ${assignee?.email || officerEmail},
            selectedRole: ${role},
            selectedTerm: ${term}
          `,
        });
      }
    } catch (error) {
      addError(
        t(
          'assign-officer.confirmation.error-request',
          'An error occurred assigning your role'
        )
      );
    } finally {
      setGlobal(false);
      pushLocalisedUrl(backUrl);
    }
  };

  const onCancelHandler = () => {
    resetContextValues();
    setGlobal(false);
    localizedNavigate(buildOfficersPageUrl(districtId));
  };

  const backHandler = () => {
    if (operationType === OperationType.REASSIGN_TO_ROLE) {
      onCancelHandler();
    } else {
      window.localStorage.setItem('isNextStepVisited', 'true');
      setContext(prevState => ({
        ...prevState,
        step: 1,
      }));
      setGlobal(false);
    }
  };

  const onChangeTerm = (selectedTerm: TermToAssign) => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: selectedTerm,
      },
    }));
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const getDatePickerLabel = (
    termToReassign: Maybe<TermToAssign>,
    partTimeStartDate: PartTimeStartDate,
    isReassign: boolean
  ) => {
    if (
      (termToReassign?.endDate === String(yearNow) &&
        partTimeStartDate.value) ||
      !isReassign
    ) {
      return t('assign-from.startDate.label', 'Start Date');
    }
    return '';
  };

  const endOfYear = createDate(yearNow);
  const { terminationDate = '' } = activeMembership || {};
  const roleName = buildRoleName(t, role);

  const getMaxDate = () => {
    if (terminationDate) {
      if (moment(terminationDate).isAfter(endOfYear)) {
        return endOfYear;
      }
      return new Date(terminationDate);
    }
    return endOfYear;
  };

  if (assignDistrictLeadershipLoading) {
    return <Loading />;
  }

  return (
    <>
      <ConfirmDistOfficerForm
        roleName={roleName || null}
        term={term}
        termYears={role?.termYears || null}
        operationType={operationType}
        termForNowYear={termForNowYear}
        termForElect={termForElect}
        assigneeEmail={assignee?.email || officerEmail || null}
        assigneeName={assignee?.nameWithPrefixSuffix || officerName || null}
        title={t('confirm-selected-role', 'Confirm {{selectedRole}}', {
          selectedRole: roleName,
        })}
        labels={{
          officerLabel: t('assign-from.officer.label', 'Officer'),
          termLabel: t('assign-from.term.label', 'Term'),
          roleLabel: t('assign-from.role.label', 'Role'),
          emailLabel: t('assign-from.email.label', 'Email'),
          datePickerLabel: getDatePickerLabel(
            termToReassign,
            partTimeStartDate,
            false
          ),
        }}
        onChangeTerm={onChangeTerm}
        minDate={partTimeStartDate.minDate}
        maxDate={getMaxDate()}
        selectedDateValue={partTimeStartDate.value || undefined}
        handleSelectDate={
          (partTimeStartDate.value && handleSelectDate) || undefined
        }
      />
      <ActionButtons
        submitBtnLabel={t('action-button.finish', 'Finish')}
        cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
        onCancel={modalOnCancelHandler}
        onSubmit={handleSubmit}
      />
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={isBack ? backHandler : onCancelHandler}
      />
    </>
  );
};

export default ConfirmDistrictExecSecretary;
