import React, { useContext, useEffect, useState } from 'react';

import toInteger from 'lodash/toInteger';

import Loading from '@components/Loading';
import RoleForMember from '@components/RoleForMember';

import { useLocalStorage } from '@repositories/storage/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import {
  CommitteeAppointment,
  Role,
  RoleOption,
  Step,
  TermToAssign,
} from '@domui-domain/districts';
import { useDominoOfficerRoles } from '@domui-hooks/useDominoOfficerRoles';
import { useFetchDistrictOfficers } from '@domui-hooks/useFetchDistrictOfficers';
import { useFetchDistrictOfficersData } from '@domui-repositories/districts/hooks/useFetchDistrictOfficersData';
import {
  DistrictContext,
  get3yrTermRoles,
  getAssignedFuture3yrTermRoleIds,
  getDOUnassignedRoles,
  getFormattedRoles,
  mapLeadershipRoles,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { getRotaryYear } from '@domui-utils/datetime';

import { useTranslation } from '@external/react-i18next';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const AssignRoleStep: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(DistrictContext);
  const {
    termsToAssign,
    selectedInfo: { assignee, term, role },
  } = context;
  const { updateStorage } = useLocalStorage<CommitteeAppointment>();

  const [selectedRole, setSelectedRole] = useState<RoleOption | null>(role);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isResetValue, setResetValue] = useState(false);

  const { data: rolesData, loading: rolesLoading } = useDominoOfficerRoles();

  const {
    data: future3yrTermOfficersData,
    loading: districtOfficersLoading,
    fetchDistrictOfficers,
  } = useFetchDistrictOfficers();

  const { officersData, officersLoading } = useFetchDistrictOfficersData(
    districtId,
    riDistrictId,
    term?.endDate
  );

  const handleGoToPrevStep = () => {
    setContext(prevState => {
      const newValues = {
        ...prevState,
        step: 1 as Step,
        operationType: null,
        selectedInfo: {
          ...prevState.selectedInfo,
          term: prevState.termsToAssign[0],
        },
      };
      updateStorage(newValues);
      return newValues;
    });
    localizedNavigate(`/domui/district/${districtId}/members`);
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: handleGoToPrevStep,
  });

  useEffect(() => {
    const mapPayload = {
      organizationType: ['District'],
      validOn: `${toInteger(term?.endDate) - 1}-07-01`,
      officerManagement: 'District',
    };
    const leadershipRoles = {
      roles: rolesData
        ? mapLeadershipRoles(mapPayload, rolesData?.response)
        : [],
    };
    setRoles(getFormattedRoles(leadershipRoles));
  }, [rolesData?.response]);

  useEffect(() => {
    const threeYrTermRoles = get3yrTermRoles(roles);

    if (threeYrTermRoles.length && riDistrictId) {
      const districtOfficersPayload = {
        page: 1,
        pageSize: 100,
        roles: threeYrTermRoles,
        rotaryYear: String(Number(getRotaryYear()) + 1),
        riDistrictId,
      };
      fetchDistrictOfficers(districtId, districtOfficersPayload);
    }
  }, [roles]);

  const onChangeRole = (value: RoleOption) => {
    setSelectedRole(value);
    setResetValue(false);
  };

  const onChangeTerm = (selectedTerm: TermToAssign) => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: selectedTerm,
        role: null,
      },
    }));
    setSelectedRole(null);
    setResetValue(true);
  };

  const handleGoToNextStep = () => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        role: selectedRole,
      },
      step: 2,
    }));
    window.scrollTo(0, 0);
  };

  if (term === null) {
    return <Loading />;
  }

  const [, nonGovernors] = getDOUnassignedRoles({
    roles,
    officers: officersData,
    year: term?.endDate,
  });

  const assignedFuture3yrTermRoleIds = getAssignedFuture3yrTermRoleIds(
    future3yrTermOfficersData,
    term
  );

  const rolesToAssign = nonGovernors
    .filter(role => !assignedFuture3yrTermRoleIds.includes(role.id))
    .map(({ id, role: name, terms, termYears }) => ({
      id,
      name,
      terms,
      termYears,
    }));

  const isLoading = rolesLoading || officersLoading || districtOfficersLoading;

  return (
    <RoleForMember
      step={{
        value: 1,
        count: 2,
      }}
      title={t('select-a-role-for-a-member', 'Select a Role for a Member')}
      assignee={assignee}
      rolesToAssign={rolesToAssign}
      termsToAssign={termsToAssign}
      selectedTerm={term}
      role={selectedRole}
      isContinueBtnDisabled={!selectedRole || isLoading}
      onChangeRole={onChangeRole}
      onChangeTerm={onChangeTerm}
      handleGoToNextStep={handleGoToNextStep}
      handleGoToPrevStep={handleGoToPrevStep}
      isRoleSelectLoading={isLoading}
      isRoleSelectDisabled={isLoading}
      isResetValue={isResetValue}
    />
  );
};

export default AssignRoleStep;
