import React, { useContext } from 'react';

import Step1 from './Step1';
import Step2 from './Step2';

import { MemberURLParams } from '@domain/districts';

import { useSetMemberDataFromURL } from '@use-cases/districts';

import { useStorageNavigation } from '@repositories/storage/hooks';

import { unflattenObject } from '@utils/objectUtils';

import { DistrictContext } from '@domui-use-cases/districts';
import {
  buildAGsPageUrl,
  getQueryParamsFromURL,
} from '@domui-use-cases/districts/helpers/urlHelpers';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

/** Declare global window variable
 * Used in Confirmation Steps to show Modal on browser navigation */
declare global {
  interface Window {
    stopBrowserNavigate: boolean;
  }
}

if (typeof window !== 'undefined') {
  window.stopBrowserNavigate = false;
}

const AssistantGovernorManager: React.FC<Props> = ({
  districtId,
  riDistrictId,
}) => {
  let URLParamsObj = unflattenObject(
    getQueryParamsFromURL() as MemberURLParams | {}
  );

  const [context, setContext] = useContext(DistrictContext);

  const { step } = context;

  const paramsToExclude = ['operationType', 'step'];
  const { step: stepParam } = URLParamsObj;

  if (stepParam) {
    URLParamsObj = {
      ...URLParamsObj,
      step: parseInt(stepParam, 10),
    };
  }

  useStorageNavigation(
    context,
    setContext,
    {
      backUrl: buildAGsPageUrl(districtId),
    },
    URLParamsObj,
    true
  );

  useSetMemberDataFromURL(
    context,
    setContext,
    paramsToExclude,
    URLParamsObj as MemberURLParams,
    true
  );

  if (step === 1) {
    return <Step1 districtId={districtId} riDistrictId={riDistrictId} />;
  }
  if (step === 2) {
    return <Step2 districtId={districtId} riDistrictId={riDistrictId} />;
  }

  return null;
};

export default AssistantGovernorManager;
