/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import {
  endsWith,
  // isNil,
  toInteger,
} from 'lodash';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
// import NotFound from '@components/Routing/NotFound';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';
import AddDistrictExecSecretary from '@domui-presenters/web/pages/Districts/CommitteeAppointments/AssignDistrictExecutiveSecretary/AddDistrictExecSecretary';
import DistrictConferencesPresRep from '@presenters/web/pages/Districts/Conferences/PresRepConferences/DistrictConferencesPresRep';
import DistrictCreateUpdateFeedback from '@presenters/web/pages/Districts/CreateOrUpdateFeedback';
import DistrictDashboard from '@presenters/web/pages/Districts/Dashboard';
import DistrictFeedback from '@presenters/web/pages/Districts/Feedback';
import DistrictFinance from '@presenters/web/pages/Districts/Finance';
// import DistrictReports from '@presenters/web/pages/Districts/Reports';
import LeadsLandingPage from '@presenters/web/pages/Leads/MMLPage';
import Candidate from '@presenters/web/pages/Leads/MMLPage/AddLeadAsNewDistrictMember/Candidate';
import CandidateDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/CandidateDetails';
import AddDistrictCandidate from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/AddDistrictCandidate';
import IdentifyAdmittedCandidate from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/AdmittedCandidate';

import { CRUDAction, CRUDResource } from '@domain/abilities';
import {
  // ACCESS_LEVEL_TARGETS,
  CommitteeAppointment,
  // PRESIDENT_REPRESENTATIVE_ROLE,
  TermToAssign,
} from '@domain/districts';

// import { useIndividualAbilities } from '@use-cases/abilities';
// import {
//   isDistrictImmediatePastOrFutureOfficer,
//   sortIndividualLeadershipAffiliationType,
// } from '@use-cases/clubs/helpers/isImmediatePastOrFutureOfficer';
import {
  // defaultContextState,
  getDistrictIdFromPath,
  getFilteredTabs,
  getIsAGPAge,
  getIsFeedbackPage,
  getTermByYear,
  // isDateBetween,
} from '@use-cases/districts';

import { isViewLevel } from '@utils/access-helpers';
// import { getFormattedDate } from '@utils/datetime';
import { getQueryParams } from '@utils/query-params';

import AuthenticatedRoute from '@domui-components/Auth/AuthenticatedRoute';
import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';
import { useFetchOrganization } from '@domui-hooks/useFetchOrganization';
import { useFetchTimezones } from '@domui-hooks/useFetchTimezones';
import AssistantGovernors from '@domui-presenters/web/pages/Districts/AssistantGovernors';
import DistrictClubAssignments from '@domui-presenters/web/pages/Districts/ClubAssignments';
import DistrictClubs from '@domui-presenters/web/pages/Districts/Clubs';
import CommitteeAppointmentManager from '@domui-presenters/web/pages/Districts/CommitteeAppointments';
import DistrictConferenceDetails from '@domui-presenters/web/pages/Districts/Conferences/ConferenceDetailsView';
import DistrictCreateConference from '@domui-presenters/web/pages/Districts/Conferences/ConferencesCreate';
import DistrictEditConference from '@domui-presenters/web/pages/Districts/Conferences/ConferencesEdit';
import DistrictConferences from '@domui-presenters/web/pages/Districts/Conferences/ConferencesList';
import DistrictDetails from '@domui-presenters/web/pages/Districts/Details';
import DistrictEditContact from '@domui-presenters/web/pages/Districts/Details/EditContact/EditContact';
import ManageAssistantGovernors from '@domui-presenters/web/pages/Districts/ManageAssistantGovernors';
import DistrictMembers from '@domui-presenters/web/pages/Districts/Members';
import DistrictOfficers from '@domui-presenters/web/pages/Districts/Officers';
import { useTabsPermissions } from '@domui-repositories/districts';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import {
  defaultContextState,
  DistrictContext,
  // getDistrictIdFromPath,
  // getFilteredTabs,
  // getIsAGPAge,
  // getIsFeedbackPage,
  // getTermByYear,
  // isDateBetween,
  // useCalculateAccessLevel,
  // useFetchAccessLevelData,
} from '@domui-use-cases/districts';
import { getDistrictTabs } from '@domui-use-cases/districts/helpers';
import { useCalculateAccessLevel } from '@domui-use-cases/districts/hooks/useCalculateAccessLevel';
import { useFetchDistrictMembersAccess } from '@domui-use-cases/districts/hooks/useFetchDistrictMembersAccess';
import { getRotaryYear } from '@domui-utils/datetime';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { useFetchMemberLeadershipsHistory } from '@hooks/useFetchLeadershipHistory';

const District: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext, location }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;
  const stickyContainerRef = useRef(null);
  const locationPathName = location?.pathname.split('/').pop();
  const RY = getRotaryYear();
  const currentRotaryYear = toInteger(RY);

  // const { can, isLoading: isIndividualLoading } = useIndividualAbilities(
  //   user?.individualId,
  //   locationPathName
  // );
  const [updateFlag, setUpdateFlag] = useState(false);

  const dominoDistrictId = getDistrictIdFromPath(location?.pathname);

  const {
    data: districtData,
    loading: isDistrictLoading,
    error,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (dominoDistrictId) {
      fetchOrganization(dominoDistrictId);
    }
  }, [dominoDistrictId, updateFlag]);

  const {
    data: accessLevelData,
    loading: accessDataLoading,
    error: IAMError,
    fetchAccessLevels,
  } = useFetchDistrictMembersAccess();

  useEffect(() => {
    fetchAccessLevels(requestorId, dominoDistrictId);
  }, [requestorId]);

  //  const [
  //     fetchAccessLevelsForDashboard,
  //     {
  //       data: accessLevelDataForDashboard,
  //       loading: accessDataForDashboardLoading,
  //     },
  //   ] = useFetchAccessLevels();

  // const [
  //   fetchLeadershipHistory,
  //   { data: memberLeadershipInfo, loading: memberLeadershipLoading },
  // ] = useFetchMemberLeadershipsHistory();

  // const [
  //   fetchAccessLevelsForConferences,
  //   {
  //     data: accessLevelDataForConferences,
  //     loading: accessDataForConferencesLoading,
  //   },
  // ] = useFetchAccessLevels();

  // const [
  //   fetchCommitteeRoles,
  //   { data: committeeRoles, loading: committeeRolesLoading },
  // ] = useFetchCommitteeRoles();

  const {
    data: countriesData,
    loading: countriesLoading,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const {
    data: timeZones,
    loading: timeZonesLoading,
    fetchTimezones,
  } = useFetchTimezones();

  useEffect(() => {
    fetchTimezones();
  }, []);

  const {
    data: languagesData,
    loading: languagesLoading,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);

  const districtId = getDistrictIdFromPath(location?.pathname);
  const isAGPage = getIsAGPAge(location);
  const isFeedbackPage = getIsFeedbackPage(location);

  // Set context values
  const [contextCAValues, setContextCAValue] = useState<CommitteeAppointment>({
    ...defaultContextState,
  });

  const [contextAGValues, setContextAGValue] = useState<CommitteeAppointment>({
    ...defaultContextState,
  });

  // const [isDistrictExist, setIsDistrictExist] = useState<boolean>();

  // For District Officers page we need to get termsToAssign and Access Levels based on selected term
  // For other cases these values are defined based on current RY
  const isDOPage = endsWith(location?.pathname, 'officers');
  const { endDate: endDateTerm } = contextCAValues.selectedInfo.term || {};
  const endTermYear =
    isDOPage && endDateTerm ? toInteger(endDateTerm) : currentRotaryYear;

  // const { accessLevelData, accessDataLoading } = useFetchAccessLevelData(
  // const { accessLevelData } = useFetchAccessLevelData(
  //   dominoDistrictId,
  //   isAGPage
  //     ? contextAGValues.selectedInfo.term?.endDate || `${currentRotaryYear}`
  //     : `${endTermYear}`
  // );

  // const conferenceAccessLevel =
  //   accessLevelDataForConferences?.results?.some(({ level }) =>
  //     isViewLevel(level)
  //   ) || false;

  // useEffect(() => {
  //   if (user?.individualId && conferenceAccessLevel) {
  //     fetchCommitteeRoles({
  //       variables: {
  //         individualId: user.individualId,
  //       },
  //     });
  //   }
  // }, [conferenceAccessLevel]);

  const isDistrictDetailsPage = locationPathName?.includes('details');

  useEffect(() => {
    if (user?.isLoggedIn && user?.individualId && districtId) {
      // fetchDistrict({
      //   variables: {
      //     districtId,
      //     userId: user.individualId,
      //   },
      // });
      (isAGPage ? setContextAGValue : setContextCAValue)(prevState => ({
        ...prevState,
        districtId,
      }));
    }
  }, [
    isAGPage,
    isDistrictDetailsPage,
    //   fetchDistrict,
    districtId,
    user?.individualId,
    user?.isLoggedIn,
  ]);

  // Reset context if term is not as default term and refetch access levels
  const isNotDefaultTermSelected = ({
    selectedInfo: { term },
    defaultTerm,
  }: CommitteeAppointment) =>
    term && defaultTerm && term.startDate !== defaultTerm.startDate;

  const resetToDefaultTerm = (
    setContextData: Dispatch<SetStateAction<CommitteeAppointment>>
  ) =>
    setContextData(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: null,
      },
      committeeManager: {
        isManager: { ...defaultContextState.committeeManager.isManager },
      },
    }));

  useEffect(() => {
    // if we choose diff year than we need on the Members
    // then we should restore it
    const isMemberPage = endsWith(location?.pathname, 'members');
    if (isMemberPage) {
      if (isNotDefaultTermSelected(contextCAValues)) {
        resetToDefaultTerm(setContextCAValue);
      }
      if (isNotDefaultTermSelected(contextAGValues)) {
        resetToDefaultTerm(setContextAGValue);
      }
    }
  }, [
    location,
    contextCAValues.selectedInfo.term,
    contextAGValues.selectedInfo.term,
  ]);

  useCalculateAccessLevel(
    accessLevelData,
    isAGPage ? contextAGValues : contextCAValues,
    isAGPage ? setContextAGValue : setContextCAValue,
    currentRotaryYear
  );
  const parsedSearch = getQueryParams();
  const isBackPagePR = parsedSearch.back === 'PR';

  // This workaround is used to know if the district exists in the system.
  // It was introduced in CDA-192 and relates to the response we get from DIS
  // in case of districtId is incorrect and doesn't exist
  // (please see comments in CDA-192 for detailed info:
  // https://jiraprd.rotaryintl.org/browse/CDA-192#:~:text=getAccessLevels%20400%20error%20on%20District%20Details%20page%C2%A0)
  //
  // According to mentioned workaround, to know if the district exists in the system
  // we can use only the access levels response.
  // If District does not exist accessLevelData.results.length is equal to 0.
  // Such an approach handles all cases with logged-in/ not logged-in users,
  // members/non-members of a particular district, and Pres Reps.

  // useEffect(() => {
  //   if (accessLevelData) {
  //     setIsDistrictExist(Boolean(accessLevelData.results.length));
  //   }
  // }, [accessLevelData]);

  // useEffect(() => {
  //   if (
  //     user?.isLoggedIn &&
  //     user?.individualId &&
  //     districtId &&
  //     isDistrictExist
  //   ) {
  //     fetchLeadershipHistory({
  //       variables: {
  //         id: user.individualId,
  //       },
  //     });
  //   }
  // }, [user?.isLoggedIn, districtId, isDistrictExist]);

  // useEffect(() => {
  //   if (user?.isLoggedIn && districtId && isDistrictExist) {
  //     fetchAccessLevelsForDashboard({
  //       variables: {
  //         options: [
  //           {
  //             type: ACCESS_LEVEL_TARGETS.DISTRICT_DASHBOARDS,
  //             id: districtId,
  //             targetTermYearRange: [RY],
  //           },
  //         ],
  //       },
  //     });
  //   }
  // }, [user?.isLoggedIn, districtId, isDistrictExist]);

  // useEffect(() => {
  //   if (user?.isLoggedIn && districtId && isDistrictExist) {
  //     fetchAccessLevelsForConferences({
  //       variables: {
  //         options: [
  //           {
  //             type: ACCESS_LEVEL_TARGETS.DISTRICT_CONFERENCES,
  //             id: districtId,
  //           },
  //         ],
  //       },
  //     });
  //   }
  // }, [user?.isLoggedIn, districtId, isDistrictExist]);

  const {
    committeeManager: { isManager },
  } = contextCAValues;
  const {
    committeeManager: { isManager: isManagerAGPage },
  } = contextAGValues;
  const {
    nowTerm: currentTermAGPage,
    afterNowTerm: nextTermAGPage,
  } = isManagerAGPage;

  const hasPermissionsToEditContactInfo = isManager.nowTerm;

  const hasPermissionToClubAssignments = currentTermAGPage || nextTermAGPage;

  // const noCurrentCommitteeAssignment =
  //   !committeeRolesLoading &&
  //   !committeeRoles?.results.some(
  //     ({ type, startDate, endDate }) =>
  //       type === PRESIDENT_REPRESENTATIVE_ROLE &&
  //       isDateBetween(
  //         getFormattedDate(new Date()),
  //         {
  //           start: startDate,
  //           end: endDate,
  //         },
  //         'd'
  //       )
  //   );

  const canCreateConference = true; // can(CRUDAction.Create, CRUDResource.Conference);

  // const districtLeadershipAffiliation = sortIndividualLeadershipAffiliationType(
  //   memberLeadershipInfo,
  //   ['District']
  // );

  // const isAuthenticatedToLeadsDrupal = isDistrictImmediatePastOrFutureOfficer(
  //   districtLeadershipAffiliation
  // );

  const {
    // reportsData,
    financeData,
    // isPermissionsLoading,
    // hasPermissionToReports,
    hasPermissionToFinance,
    hasPermissionToConference,
    hasPermissionToDashboard,
    hasPermissionToLeads,
    allowedTabs,
  } = useTabsPermissions(
    {
      dashboardsAccess: isViewLevel(null),
      leadsAccess: false,
      conferencesAccess: true,
      financeAccess: false,
    },
    dominoDistrictId
  );

  // if (
  //   memberLeadershipLoading ||
  //   isIndividualLoading ||
  //   isDistrictLoading ||
  //   isPermissionsLoading ||
  //   accessDataLoading ||
  //   accessDataForDashboardLoading ||
  //   accessDataForConferencesLoading ||
  //   committeeRolesLoading
  // ) {
  //   return <Loading />;
  // }

  if (isDistrictLoading) {
    return <Loading />;
  }

  const termsForClubAssignment = [
    currentTermAGPage ? getTermByYear(currentRotaryYear) : null,
    nextTermAGPage ? getTermByYear(currentRotaryYear + 1) : null,
  ].filter(Boolean) as TermToAssign[];

  const riDistrictId = districtData?.name;
  // const getDistrictName = (riDistrictIdParam?: number | null): string =>
  //   riDistrictIdParam?.toString() || '';

  // const isConferencesPR = location?.pathname.endsWith(
  //   'conferences/feedback/PR'
  // );
  const isConferences = location?.href.includes('conferences');
  const isDetails = location?.href.includes('details');

  const isNotFeedbackAndPR =
    !isFeedbackPage && !(isConferences && isDetails && isBackPagePR);

  // if (
  //   // we need an extra check for isNil() to avoid showing the Not Found page
  //   // when access level data is still loading, the variable isDistrictExist === undefined
  //   // and we don't know yet if district exists in the system
  //   !isNil(isDistrictExist) &&
  //   !isDistrictExist &&
  //   !isConferencesPR
  // ) {
  //   return <NotFound default />;
  // }

  return (
    <>
      <div
        ref={stickyContainerRef}
        id="stickyContainer"
        data-testid="stickyContainer"
        className="stickySelect hidden"
      />
      {dominoDistrictId && (
        <Helmet
          titleTemplate={`${t('metadata.title.disctict', 'District')}: %s | ${t(
            'metadata.title.default',
            'My Rotary'
          )}`}
        />
      )}
      <OneColumn className="mb-20">
        {isNotFeedbackAndPR && (
          <Title>
            {t('tab.district-district-title', 'District {{riDistrictId}}', {
              riDistrictId: districtData?.name,
            })}
          </Title>
        )}
        <DistrictContext.Provider
          value={
            isAGPage
              ? // eslint-disable-next-line react/jsx-no-constructed-context-values
                [contextAGValues, setContextAGValue]
              : [contextCAValues, setContextCAValue]
          }
        >
          {isNotFeedbackAndPR && (
            <Submenu
              items={getFilteredTabs(
                getDistrictTabs(t, dominoDistrictId),
                allowedTabs
              )}
              location={location}
            />
          )}
          <Router basepath={`/${pageContext.languagePrefix}domui/district`}>
            <AuthenticatedRoute
              riDistrictId={riDistrictId}
              districtData={districtData}
              districtDataLoading={isDistrictLoading}
              path="/:districtId/details"
              hasPermissionsToEditContactInfo={hasPermissionsToEditContactInfo}
              Component={DistrictDetails}
            />
            {hasPermissionToLeads && (
              <>
                <AuthenticatedRoute
                  path="/:districtId/manage-membership"
                  isDistrict
                  Component={LeadsLandingPage}
                />
                <AuthenticatedRoute
                  path="/:districtId/manage-membership/add-member"
                  districtName={districtData?.name}
                  Component={AddDistrictCandidate}
                />
                <AuthenticatedRoute
                  path="/:districtId/manage-membership/candidate-details"
                  districtId={dominoDistrictId}
                  riDistrictId={riDistrictId}
                  isDistrict
                  Component={CandidateDetails}
                />
                <AuthenticatedRoute
                  path="/:districtId/manage-membership/convert-candidate"
                  districtId={dominoDistrictId}
                  isDistrict
                  Component={Candidate}
                />

                <AuthenticatedRoute
                  path="/:districtId/manage-membership/identify-candidate/:clubId"
                  districtId={dominoDistrictId}
                  riDistrictId={riDistrictId}
                  isDistrict
                  Component={IdentifyAdmittedCandidate}
                />
              </>
            )}
            <AuthenticatedRoute
              path="/:dominoDistrictId/clubs"
              riDistrictId={riDistrictId}
              Component={DistrictClubs}
            />
            <AuthenticatedRoute
              riDistrictId={riDistrictId}
              districtId={dominoDistrictId}
              path="/:dominoDistrictId/members"
              Component={DistrictMembers}
            />
            <AuthenticatedRoute
              path="/:dominoDistrictId/members/assistant-governors"
              riDistrictId={riDistrictId}
              Component={AssistantGovernors}
            />
            <AuthenticatedRoute
              path="/:districtId/members/officers"
              riDistrictId={riDistrictId}
              districtData={districtData}
              Component={DistrictOfficers}
            />
            {hasPermissionToFinance && (
              <AuthenticatedRoute
                financeData={financeData}
                path="/:districtId/finance"
                Component={DistrictFinance}
              />
            )}
            {/* {hasPermissionToReports && (
              <AuthenticatedRoute
                reportsData={reportsData}
                path="/:districtId/reports"
                Component={DistrictReports}
              />
            )} */}
            <AuthenticatedRoute
              hasPermissionToDashboard={hasPermissionToDashboard}
              path="/:districtId/dashboard"
              Component={DistrictDashboard}
              stickyContainerRef={stickyContainerRef}
            />
            <AuthenticatedRoute
              path="/:districtId/members/manage-committee-appointments/:localStorageId"
              riDistrictId={riDistrictId}
              Component={CommitteeAppointmentManager}
            />
            <AuthenticatedRoute
              path="/:districtId/members/manage-committee-appointments/:localStorageId/add-officer"
              riDistrictId={riDistrictId}
              Component={AddDistrictExecSecretary}
            />
            <AuthenticatedRoute
              path="/:districtId/members/manage-assistant-governors/:localStorageId"
              riDistrictId={riDistrictId}
              Component={ManageAssistantGovernors}
            />
            {hasPermissionToClubAssignments && (
              <AuthenticatedRoute
                path="/:dominoDistrictId/members/assistant-governors/club-assignments"
                termsToAssign={termsForClubAssignment}
                Component={DistrictClubAssignments}
              />
            )}
            {hasPermissionsToEditContactInfo && (
              <AuthenticatedRoute
                path="/:districtId/details/edit-contact"
                riDistrictId={riDistrictId}
                districtData={districtData}
                districtDataLoading={isDistrictLoading}
                error={error}
                Component={DistrictEditContact}
                setUpdateFlag={setUpdateFlag}
              />
            )}

            <>
              <AuthenticatedRoute
                accessLevelData={accessLevelData}
                hasPermissionToConference={hasPermissionToConference}
                path="/:districtId/conferences"
                Component={DistrictConferences}
              />
              <AuthenticatedRoute
                accessLevelData={accessLevelData}
                hasPermissionToConference={hasPermissionToConference}
                canCreateConference={canCreateConference}
                countriesData={countriesData}
                countriesLoading={countriesLoading}
                timeZones={timeZones}
                timeZonesLoading={timeZonesLoading}
                path="/:districtId/conferences/create"
                Component={DistrictCreateConference}
              />
              <AuthenticatedRoute
                accessLevelData={accessLevelData}
                hasPermissionToConference={hasPermissionToConference}
                // canEditConferenceFn={can}
                countriesData={countriesData}
                countriesLoading={countriesLoading}
                timeZones={timeZones}
                timeZonesLoading={timeZonesLoading}
                // isIndividualLoading={isIndividualLoading}
                path="/:districtId/conferences/:conferenceId/edit"
                Component={DistrictEditConference}
              />
              <AuthenticatedRoute
                path="/conferences/feedback/PR"
                Component={DistrictConferencesPresRep}
              />
              <AuthenticatedRoute
                path="/:districtId/conferences/:conferenceId/:relatedRotaryYear/feedback/:feedbackType/:feedbackId"
                Component={DistrictFeedback}
              />
              {hasPermissionToConference && (
                <AuthenticatedRoute
                  countriesData={countriesData}
                  countriesLoading={countriesLoading}
                  timeZones={timeZones}
                  timeZonesLoading={timeZonesLoading}
                  languagesData={languagesData}
                  languagesLoading={languagesLoading}
                  path="/:districtId/conferences/:conferenceId/details"
                  Component={DistrictConferenceDetails}
                />
              )}
              <AuthenticatedRoute
                path="/:districtId/conferences/:conferenceId/:relatedRotaryYear/feedback/:feedbackType"
                Component={DistrictCreateUpdateFeedback}
              />
            </>
          </Router>
        </DistrictContext.Provider>
      </OneColumn>
    </>
  );
};

export default District;
