// Libs
import React from 'react';

// Components
import TextField from '@components/Formik/TextField';

import { AddressSectionProps } from './AddressSection';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';

import { useTranslation } from '@external/react-i18next';

type AddressFieldsProps = Omit<AddressSectionProps, 'title'>;

const AddressFields: React.FC<AddressFieldsProps> = ({
  countryId,
  fieldNames,
  placeholders,
  labels,
  suffix,
  countriesData,
  countriesLoading,
  loadingStates,
  countryStatesData,
  fetchCountryStates,
}) => {
  const { t } = useTranslation();

  const defaultFieldNames = {
    venueName: `venueName`,
    lineOne: `${suffix}.lineOne`,
    lineTwo: `${suffix}.lineTwo`,
    lineThree: `${suffix}.lineThree`,
    country: `${suffix}.country`,
    city: `${suffix}.city`,
    state: `${suffix}.state`,
    hasStates: `${suffix}.hasStates`,
    postalCode: `${suffix}.postalCode`,
    internationalProvince: `${suffix}.internationalProvince`,
  };

  const defaultPlaceholders = {
    venueNamePlaceholder: t('create-conference.venue-name.placeholder', ''),
    lineOnePlaceholder: t('create-conference.lineOne.placeholder', ''),
    lineTwoPlaceholder: t('create-conference.lineTwo.placeholder', ''),
    lineThreePlaceholder: t('create-conference.lineThree.placeholder', ''),
    countryPlaceholder: t('create-conference.country.placeholder', ''),
    cityPlaceholder: t('create-conference.city.placeholder', ''),
    statePlaceholder: t('create-conference.state.placeholder', ''),
    postalCodePlaceholder: t('create-conference.postalCode.placeholder', ''),
  };

  const defaultLabels = {
    venueNameLabel: t('create-conference.venue-name.label', 'Venue Name'),
    lineOneLabel: t('form.address.address-label', 'Address'),
    lineTwoLabel: t('form.address.address-label-line-two', 'Address line two'),
    lineThreeLabel: t(
      'form.address.address-label-line-three',
      'Address line three'
    ),
    countryLabel: t('form.address.country-label', 'Country/Region'),
    cityLabel: t('form.address.city-label', 'City'),
    stateLabel: t('form.address.state-label', 'State/Province/Territory'),
    postalCodeLabel: t('form.address.postalCode-label', 'Postal Code'),
  };

  const {
    venueName: defaultVenueName,
    lineOne: defaultLineOne,
    lineTwo: defaultLineTwo,
    lineThree: defaultLineThree,
    country: defaultCountry,
    city: defaultCity,
    state: defaultState,
    hasStates: defaultHasStates,
    postalCode: defaultPostalCode,
    internationalProvince: defaultInternationalProvince,
  } = defaultFieldNames;

  const {
    venueNamePlaceholder: defaultVenueNamePlaceholder,
    lineOnePlaceholder: defaultLineOnePlaceholder,
    lineTwoPlaceholder: defaultLineTwoPlaceholder,
    lineThreePlaceholder: defaultLineThreePlaceholder,
    countryPlaceholder: defaultCountryPlaceholder,
    cityPlaceholder: defaultCityPlaceholder,
    statePlaceholder: defaultStatePlaceholder,
    postalCodePlaceholder: defaultPostalCodePlaceholder,
  } = defaultPlaceholders;

  const {
    venueNameLabel: defaultVenueNameLabel,
    lineOneLabel: defaultLineOneLabel,
    lineTwoLabel: defaultLineTwoLabel,
    lineThreeLabel: defaultLineThreeLabel,
    countryLabel: defaultCountryLabel,
    cityLabel: defaultCityLabel,
    stateLabel: defaultStateLabel,
    postalCodeLabel: defaultPostalCodeLabel,
  } = defaultLabels;

  const {
    venueName = defaultVenueName,
    lineOne = defaultLineOne,
    lineTwo = defaultLineTwo,
    lineThree = defaultLineThree,
    country = defaultCountry,
    city = defaultCity,
    state = defaultState,
    hasStates = defaultHasStates,
    postalCode = defaultPostalCode,
    internationalProvince = defaultInternationalProvince,
  } = fieldNames || {};

  const {
    venueNamePlaceholder = defaultVenueNamePlaceholder,
    lineOnePlaceholder = defaultLineOnePlaceholder,
    lineTwoPlaceholder = defaultLineTwoPlaceholder,
    lineThreePlaceholder = defaultLineThreePlaceholder,
    countryPlaceholder = defaultCountryPlaceholder,
    cityPlaceholder = defaultCityPlaceholder,
    statePlaceholder = defaultStatePlaceholder,
    postalCodePlaceholder = defaultPostalCodePlaceholder,
  } = placeholders || {};

  const {
    venueNameLabel = defaultVenueNameLabel,
    lineOneLabel = defaultLineOneLabel,
    lineTwoLabel = defaultLineTwoLabel,
    lineThreeLabel = defaultLineThreeLabel,
    countryLabel = defaultCountryLabel,
    cityLabel = defaultCityLabel,
    stateLabel = defaultStateLabel,
    postalCodeLabel = defaultPostalCodeLabel,
  } = labels || {};

  return (
    <>
      <TextField
        name={venueName}
        label={venueNameLabel}
        placeholder={venueNamePlaceholder}
      />
      <TextField
        name={lineOne}
        label={lineOneLabel}
        placeholder={lineOnePlaceholder}
      />
      <TextField
        name={lineTwo}
        label={lineTwoLabel}
        labelHidden={!labels?.lineTwoLabel}
        placeholder={lineTwoPlaceholder}
      />
      <TextField
        name={lineThree}
        label={lineThreeLabel}
        labelHidden={!labels?.lineThreeLabel}
        placeholder={lineThreePlaceholder}
      />
      <CountrySelect
        hasStatesName={hasStates}
        name={country}
        countriesData={countriesData}
        countriesLoading={countriesLoading}
        internationalProvinceName={internationalProvince}
        stateName={state}
        countryLabel={countryLabel}
        countryPlaceholder={countryPlaceholder}
        clearable
      />
      <TextField name={city} label={cityLabel} placeholder={cityPlaceholder} />
      {countryId && (
        <StateSelect
          label={stateLabel}
          countryCode={countryId}
          nameStateField={state}
          nameHasStatesField={hasStates}
          nameInternationalProvinceField={internationalProvince}
          loadingStates={loadingStates}
          statePlaceholder={statePlaceholder}
          countryStatesData={countryStatesData}
          fetchCountryStates={fetchCountryStates}
        />
      )}
      <div className="w-48">
        <TextField
          name={postalCode}
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
        />
      </div>
    </>
  );
};

export default AddressFields;
