// Libs
import React, { ReactElement } from 'react';

// Components
import DetailSection from '@presenters/web/components/DetailSection';

import DetailSectionField from './DetailSectionField';

// Constants
import { LOCATION } from '@domain/districts';

// Utils
import { getConferenceHeading } from '@use-cases/districts/conferences';

import { getAddress } from '@utils/getAddress';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { BaseAddress, Maybe, MeetingType } from '@typings/graphql';

interface Props {
  venueName?: Maybe<string>;
  venueType: string;
  conferenceAddress: BaseAddress;
  onlineLocation?: Maybe<string>;
  detailForJoiningRemotely?: Maybe<string>;
}

const LocationSection: React.FC<Props> = ({
  venueName,
  venueType,
  conferenceAddress,
  onlineLocation,
  detailForJoiningRemotely,
}) => {
  const { t } = useTranslation();

  const isHybrid = venueType === MeetingType.Hybrid;
  const isPhysical = venueType === MeetingType.Physical || isHybrid;
  const isOnline = venueType === MeetingType.Online || isHybrid;

  const address = getAddress(venueName, conferenceAddress);

  const onlineLocationLink: ReactElement<HTMLLinkElement> = (
    <a href={onlineLocation || '#'}>{onlineLocation}</a>
  );

  const isSectionShown = !!(
    address.length ||
    onlineLocation ||
    detailForJoiningRemotely
  );

  return isSectionShown ? (
    <DetailSection title={getConferenceHeading(t, LOCATION)}>
      {isPhysical && !!address.length && (
        <DetailSectionField
          title={t('conference-details.address', 'Address')}
          description={address}
          descriptionClassname="m-0"
        />
      )}

      {isOnline && (
        <>
          {onlineLocation && (
            <DetailSectionField title="URL" description={onlineLocationLink} />
          )}

          {detailForJoiningRemotely && (
            <DetailSectionField
              title={t(
                'conference-details.details-for-joining-remotely',
                'Details for joining remotely'
              )}
              description={detailForJoiningRemotely}
            />
          )}
        </>
      )}
    </DetailSection>
  ) : null;
};

export default LocationSection;
