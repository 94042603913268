// Constants
import { STORAGE_PREFIX, FORM_STATUSES } from '@domain/storage';

// Hooks
import { getStorageID } from '@use-cases/storage';
import { useParams } from '@reach/router';

// Types
import { BackURL } from '@domui-domain/districts';

interface LocalStorage<T> {
  getStorageData: (
    backUrl?: string
  ) => { data: T & BackURL; status?: string } | null;
  updateStorage: (
    newData: (T & { status?: string }) | BackURL,
    newLocalStorageId?: string
  ) => void;
  clearStorage: (key?: string) => void;
}

export const useLocalStorage = <T>(): LocalStorage<T> => {
  const { localStorageId } = useParams();

  return {
    getStorageData: (id?: string) => {
      const _id = id || localStorageId;
      const storageData = localStorage.getItem(getStorageID(_id));
      if (_id && storageData) {
        const { status = FORM_STATUSES.STARTED, ...rest } = JSON.parse(
          storageData
        );
        return {
          data: rest,
          status,
        };
      }
      return null;
    },
    updateStorage: (newData: T | BackURL, newLocalStorageId?: string) =>
      localStorage.setItem(
        getStorageID(newLocalStorageId || localStorageId),
        JSON.stringify(newData)
      ),
    clearStorage: (key?: string) => {
      if (key) {
        localStorage.removeItem(key);
      } else {
        Object.keys(localStorage).forEach(storageKey => {
          if (storageKey.includes(STORAGE_PREFIX)) {
            localStorage.removeItem(storageKey);
          }
        });
      }
    },
  };
};
