import React from 'react';

import { RouteComponentProps, useParams } from '@reach/router';

import ConferenceDurationSection from '@presenters/web/components/ConferenceDurationSection';

import ConferenceActions from './ConferenceActions';

import { getPresRepFullName } from '@domain/districts/mappers';

import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { ConferenceItemProps } from '@domui-domain/districts';
import { RecordList } from '@domui-domain/type';
import { getYearLabel } from '@domui-use-cases/districts';
import { ParticipatingDistricts } from '@domui-use-cases/districts/conferences/type';

import { useTranslation } from '@external/react-i18next';

import { ConferenceAddress, MeetingType } from '@typings/graphql';

const DesktopItem: React.FC<RouteComponentProps &
  ConferenceItemProps> = props => {
  const { conference, renderActionsButton } = props;
  const { t } = useTranslation();
  const { districtId } = useParams();

  const {
    relatedRotaryYear,
    startDate,
    endDate,
    conferenceAddress,
    presidentialRepresentative,
    participatingDistricts,
    venueType,
  }: RecordList = conference;

  const renderDesktopLocation = (
    venueType: MeetingType,
    conferenceAddress?: ConferenceAddress | null
  ) => {
    if (venueType === MeetingType.Online) {
      return <>{t('global.meetingtype.online', 'Online Only')}</>;
    }

    const physicalLocation = getPhysicalLocation(conferenceAddress, true);
    const { country } = conferenceAddress || {};
    if (physicalLocation || country) {
      return (
        <>
          {physicalLocation && <span>{physicalLocation}, </span>}
          {country && <span>{country}</span>}
        </>
      );
    }
    return <>&#8212;</>;
  };

  return (
    <div className="hidden tablet:flex flex-wrap flex-1 items-center text-xs leading-xs">
      {relatedRotaryYear ? (
        <div className="mr-2">{getYearLabel(relatedRotaryYear)}</div>
      ) : null}
      <ConferenceDurationSection
        startDate={startDate}
        endDate={endDate}
        classess="ml-2 flex-3 tablet:flex tablet:flex-col desktop-m:block"
      />
      <div className="flex-3 mr-1 ml-2 tablet:flex tablet:flex-col desktop-m:block">
        {venueType ? renderDesktopLocation(venueType, conferenceAddress) : null}
      </div>
      <div className="flex-2">
        {getPresRepFullName(presidentialRepresentative) || <>&#8212;</>}
      </div>
      <div className="flex-2 ml-4">
        {participatingDistricts
          ?.map((item: ParticipatingDistricts) => item?.riDistrictId)
          .join(', ') || <>&#8212;</>}
      </div>
      <div className="flex-3">
        <div className="pl-4 flex w-full items-center justify-end">
          <ConferenceActions
            {...props}
            districtId={districtId}
            renderButton={renderActionsButton}
            inProgressLabelClassName="ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopItem;
