import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Button } from '@components/Button';
import { IdentifyMemberResult } from '@components/IdentifyMemberResult';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import SearchForm from '@presenters/web/components/Forms/SearchForm';

import {
  IdentificationParameters,
  SearchFormValues,
} from '@domui-domain/clubs';

import { useClubAddMemberFilters } from '@domui-use-cases/clubs';
import { buildOfficersPageUrl } from '@domui-use-cases/districts';
import { useErrorHandling } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';
import { isTerminationBeforeCurrentDate } from '@utils/membershipUtils';

import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useAddMembersSearch } from '@domui-hooks/useAddMembersSearch';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { MembershipType } from '@typings/graphql';
import { constructFullName } from '@utils/constructFullName';

interface PrimaryEmail {
  email: string;
  type: string;
  isPrimary: boolean;
}

interface PrimaryPhone {
  phone: string;
  isFax: boolean;
  type: string;
  isPrimary: boolean;
}

interface PrimaryAddress {
  type: string;
  lineOne: string;
  lineTwo: string;
  lineThree: string | null;
  countryId: string | null;
  country: string;
  city: string;
  stateId: string | null;
  state: string;
  internationalProvince: string | null;
  postalCode: string;
}

interface ActiveMembership {
  id: string;
  clubId: string;
  clubName: string;
  riClubId: string;
  clubType: string;
  type: string;
  districtId: string;
  riDistrictId: string;
  admissionDate: string;
  terminationDate: string | null;
  state: string;
  country: string;
}

interface SharingPermission {
  __typename?: 'SharingPermissionResult';
  address: string;
  email: string;
  memberId: string;
  name: string;
  phone: string;
}

interface MemberResult {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  localizedName: string;
  prefix: string | null;
  suffix: string | null;
  photoUri: string | null;
  status: string;
  riIndividualId: string;
  gender: string;
  dob: string;
  language: string;
  primaryEmail: PrimaryEmail;
  primaryPhone: PrimaryPhone;
  primaryAddress: PrimaryAddress;
  ActiveMembership: ActiveMembership[];
  sharingPermission: SharingPermission;
}

const AssignDistrictExecutiveSecretary: React.FC<{
  riDistrictId: string;
  roleName: string;
  filters: IdentificationParameters;
  setFilters: (filters: IdentificationParameters) => void;
  submitHandler: (
    id: string | undefined | false,
    email: string | undefined,
    name: string | undefined
  ) => void;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
}> = ({
  riDistrictId,
  roleName,
  submitHandler,
  handleFormFieldsTouched,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const PAGE_SIZE = 5;
  const resultListRef = useRef<HTMLElement | null>(null);

  const { currentPage, setCurrentPage, handleSearch } = useClubAddMemberFilters(
    setFilters
  );

  const scrollToElement = (
    elementRef: MutableRefObject<HTMLElement | null>
  ) => {
    const currentElement = elementRef.current;

    const elementTopValue = currentElement?.getBoundingClientRect().top || 0;
    const elementOffsetHeightValue = currentElement?.offsetHeight || 0;

    window.scrollTo(
      0,
      elementTopValue + window.scrollY - elementOffsetHeightValue
    );
  };
  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
    scrollToElement(resultListRef);
  };

  const {
    data,
    loading: loadingSearch,
    error: errorSearch,
    addMembersSearch,
  } = useAddMembersSearch();

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      addMembersSearch(currentPage, PAGE_SIZE, {
        memberId: filters?.memberId,
        firstName: filters?.firstName,
        lastName: filters?.lastName,
        email: filters?.email,
        filters: {
          'country/region': [],
          clubTypes: [],
          clubs: [],
        },
      });
    }
  }, [filters, currentPage]);

  const [expandedResult, setExpandedResult] = useState<string | null>(null);

  const { error: errorCountries, fetchCountries } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleCollapse = (uniqueIdentifier: string) => {
    if (expandedResult !== uniqueIdentifier) {
      setExpandedResult(uniqueIdentifier);
    } else {
      setExpandedResult(null);
    }
  };
  const isError = Boolean(errorCountries || errorSearch);
  useErrorHandling(
    t(
      'mml.identify-member-form.temporary-error',
      'A temporary error occurred.'
    ),
    isError
  );
  const navigateToOfficersPage = () => {
    localizedNavigate(buildOfficersPageUrl(riDistrictId));
  };

  const handleCancelClick = (values: SearchFormValues) => {
    handleSearch(values);
  };

  const totalCount = data?.totalCount || 0;
  const results = data?.addMembersDetails;
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-2/5';

  return (
    <>
      <div className="mt-2">
        <h2>
          {t('add-des-form.title', 'Add New District Executive Secretary')}
        </h2>
      </div>
      <div className="mb-4 desktop:mb-10 desktop:w-5/5">
        <p className="mb-2">
          {t(
            'add-member.identify-member-form.info',
            'First, determine if the person is already in Rotary’s database. Enter any information you have for them — only one<br> field is required.<br/>'
          )}
        </p>
        <p className="font-bold mt-2">
          {t(
            'add-member.identify-member-form.latin-alert',
            'Note that this form accepts information only in the Latin alphabet.'
          )}
        </p>
      </div>

      <SearchForm
        classes={gridContainerClassName}
        filters={filters}
        loadingSearch={loadingSearch}
        searchHandler={handleCancelClick}
        handleFormFieldsTouched={handleFormFieldsTouched}
      />
      {loadingSearch && <Loading className={gridContainerClassName} />}
      {data === undefined && (
        <p className={gridContainerClassName}>
          <Button full text type="button" clickHandler={navigateToOfficersPage}>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
        </p>
      )}
      {data && (
        <ResultsList
          darkBackground
          summary={
            <span ref={resultListRef} data-cy="results-count" className="pr-4">
              {t(
                'add-member.identify-member-form.show_results-html',
                '<b>RESULTS ({{ totalCount }})</b>',
                { totalCount }
              )}
            </span>
          }
        >
          {totalCount === 0 && (
            <ResultsListItem key={0}>
              <span data-cy="no-results-message">
                {t(
                  'add-member.identify-member-form.no-results',
                  'Sorry, we couldn’t find a result to your search. Check the spelling of your search and try again. Or you can add the person as a new {{name}}.',
                  { name: roleName }
                )}
              </span>
            </ResultsListItem>
          )}
          {results?.map((result: MemberResult) => {
            const highlightedMembership =
              result.ActiveMembership.find(
                member =>
                  member.type === MembershipType.Member &&
                  !member.terminationDate
              ) || result.ActiveMembership.find(() => true);

            const uniqueIdentifier = result.primaryEmail?.email
              ? `${result.primaryEmail?.email}`
              : uuid();
            const isTerminated = isTerminationBeforeCurrentDate(
              highlightedMembership?.terminationDate
            );

            const activeClubName = result.ActiveMembership.find(
              res =>
                res.type ===
                  (MembershipType.Member || MembershipType.Honorary) &&
                !res.terminationDate
            )?.clubName;

            const name = constructFullName({
              prefix: result.prefix,
              firstName: result.firstName,
              middleName: result.middleName,
              lastName: result.lastName,
              suffix: result.suffix,
            });

            return (
              <ResultsListItem key={uniqueIdentifier}>
                <IdentifyMemberResult
                  uniqueIdentifier={uniqueIdentifier}
                  collapseHandler={handleCollapse}
                  name={name}
                  email={result.primaryEmail?.email || ''}
                  riIndividualId={result.riIndividualId}
                  address={result.primaryAddress || {}}
                  activeMemberships={result.ActiveMembership}
                  phoneNumber={result.primaryPhone?.phone || undefined}
                  photoUri={result.photoUri}
                  selectMember={() =>
                    submitHandler(
                      result.id,
                      result.primaryEmail?.email || '',
                      name
                    )
                  }
                  expanded={
                    data?.result?.totalCount === 1 ||
                    expandedResult === uniqueIdentifier
                  }
                  sharingPermission={result.sharingPermission || null}
                  isTerminated={isTerminated}
                  clubName={activeClubName || ''}
                  clubType={
                    DIS.ClubTypeEnum.RotaryClub || DIS.ClubTypeEnum.RotaractClub
                  }
                  accessLevel="District"
                />
              </ResultsListItem>
            );
          })}

          {totalCount > 5 && (
            <Pagination
              pageSize={PAGE_SIZE}
              page={currentPage}
              totalCount={data?.result?.totalCount}
              pageHandler={pageHandler}
            />
          )}
        </ResultsList>
      )}
      <div className={gridContainerClassName}>
        {data && (
          <>
            {totalCount > 0 && (
              <p className="mt-8" data-cy="not-found">
                {t(
                  'add-new-officer-assign.identify-member-form.not-found',
                  'If the person you searched for isnt in these results, check the spelling of their name and try again. You can also add the person as a new {{name}}.',
                  { name: roleName }
                )}
              </p>
            )}
            <Button
              secondary
              full
              className="mb-4 mt-4 desktop:mb-8 mt-11"
              clickHandler={() => submitHandler(false, '', '')}
            >
              {t('button.add-new-role', 'ADD NEW {{role}}', {
                role: roleName,
              })}
            </Button>
            <Button
              full
              text
              type="button"
              clickHandler={navigateToOfficersPage}
            >
              {t('add_member.identify_member_form.cancel_button', 'Cancel')}
            </Button>
          </>
        )}
      </div>
    </>
  );
};
export default AssignDistrictExecutiveSecretary;
