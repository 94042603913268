/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import { titleTemplateParams } from '@components/Title/util';
import ContainerWithLinks from '@presenters/web/pages/Districts/Members/ContainerWithLinks';

import AGList from './AGList';

import { getDistrictIdFromPath } from '@use-cases/districts';

import SelectRotaryYear from '@domui-components/Forms/Select/SelectRotaryYear';
// import { getCurrentDate } from '@domui-utils/datetime';
import { useDistrictAssistantGovernors } from '@domui-hooks/useDistrictAssistantGovernors';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import {
  buildAGsClubAssignmentsUrl,
  DistrictContext,
  getOfficerTermSelectOptions,
  // isCurrentTerm,
} from '@domui-use-cases/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { DistrictAG } from '@domui-domain/districts';

type LocationStateType = {
  isFromDetailsPage: boolean;
  path?: string;
};

type RotaryYearProps = {
  endDate: string;
  isCurrentRotaryYear: boolean;
  rotaryFullYear: string;
  rotaryYear: number;
  startDate: string;
};

interface Props extends RouteComponentProps {
  riDistrictId?: number | null;
  pageSize?: number;
}

const PAGE_SIZE = 10;

const AssistantGovernors: React.FC<Props> = ({
  riDistrictId,
  pageSize = PAGE_SIZE,
  location,
}) => {
  const { rotaryYear } = getClubDistrictFromLocal();
  const districtId = getDistrictIdFromPath(location?.pathname);
  const [
    {
      selectedInfo: { term },
      committeeManager: { isManager },
      defaultTerm,
    },
    setContext,
  ] = useContext(DistrictContext);

  const currentRotaryYear = rotaryYear.find(
    (year: RotaryYearProps) => year.isCurrentRotaryYear === true
  );

  function dateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    return year.toString();
  }
  const startDate = dateFormat(currentRotaryYear.startDate);
  const endDate = dateFormat(currentRotaryYear.endDate);
  const termValue = term || {
    startDate,
    endDate,
  };

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { nowTerm, afterNowTerm } = isManager;
  const showClubAssignments = nowTerm || afterNowTerm;

  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const {
    data: getDistrictAssistantGovernorsDetails,
    loading,
    // error,
    districtAssistantGovernors,
  } = useDistrictAssistantGovernors();

  const requestBody = {
    startDate: `${termValue?.startDate}-07-01`,
    endDate: `${termValue?.endDate}-06-30`,
    rotaryYear: null,
    riDistrictId,
  };

  useEffect(() => {
    if (districtId) {
      districtAssistantGovernors(
        districtId,
        currentPage,
        pageSize,
        requestBody
      );
    }
  }, [districtId, currentPage]);

  const pageHandler = (
    event: React.SyntheticEvent,
    pageNumber: number
  ): void => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const searchAGsByDate = (dateParam: string): void => {
    const RY = parseInt(dateParam, 10);
    const currentPage = 1;
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: {
          startDate: `${RY - 1}`,
          endDate: dateParam,
        },
      },
    }));
    const requestBody = {
      startDate: `${RY - 1}-07-01`,
      endDate: `${dateParam}-06-30`,
      rotaryYear: null,
      riDistrictId,
    };
    districtAssistantGovernors(districtId, currentPage, pageSize, requestBody);
    setCurrentPage(1);
  };

  const handleBack = () => {
    if (term?.startDate !== defaultTerm?.startDate) {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          term: defaultTerm,
        },
      }));
    }
  };

  if (!getDistrictAssistantGovernorsDetails || loading) {
    return <Loading />;
  }
  const transformedAGs = getDistrictAssistantGovernorsDetails?.districtAssistantGovernors.map(
    (ag: any) => ({
      ...ag,
      agId: ag.districtLeadership?.[0]?.id || null,
    })
  );

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        <LinkPrevious
          onClick={handleBack}
          path={
            (location?.state as LocationStateType)?.path ||
            `/domui/district/${districtId}/members`
          }
          label={t('back-link-text', 'Back')}
        />
        <ContainerWithLinks
          className="desktop:mb-4"
          isDistrictOfficer
          officersUrl={`/domui/district/${districtId}/members/officers`}
        />
        <Title className="text-4lg mt-2 desktop:m-0 leading-9">
          {t('assistant-governors', 'Assistant Governors')}
        </Title>
        <ContainerWithLinks
          className="desktop:mb-8"
          isClubAssignments={showClubAssignments}
          clubAssignmentsUrl={buildAGsClubAssignmentsUrl(districtId)}
        />
        <div className="visible desktop:hidden">
          <SelectRotaryYear
            handleChange={searchAGsByDate}
            options={getOfficerTermSelectOptions()}
            initialValue={termValue?.endDate}
          />
        </div>
        <AGList
          pageSize={pageSize}
          currentPage={currentPage}
          pageHandler={pageHandler}
          districtId={districtId}
          totalCount={getDistrictAssistantGovernorsDetails?.totalCount}
          districtAGs={transformedAGs}
          riDistrictId={riDistrictId}
          filters={
            <SelectRotaryYear
              initialValue={termValue?.endDate}
              options={getOfficerTermSelectOptions()}
              handleChange={searchAGsByDate}
            />
          }
        />
      </div>
    </>
  );
};

export default AssistantGovernors;
