// Libs
import React, { ReactElement } from 'react';

import classNames from 'classnames';

interface Props {
  title: string;
  description: string | string[] | ReactElement<HTMLLinkElement>;
  wrapperClassname?: string;
  descriptionClassname?: string;
}

const DetailSectionField: React.FC<Props> = ({
  title,
  description,
  wrapperClassname = 'mb-7',
  descriptionClassname,
}) => {
  const descriptionClass = classNames(
    'text-xs leading-xs',
    descriptionClassname
  );

  const renderSectionFieldContent = (
    description: string | string[] | ReactElement<HTMLLinkElement>
  ) => (
    <>
      {Array.isArray(description) ? (
        description.map((item, index) => (
          <p key={item + index} className={descriptionClass}>
            {item}
          </p>
        ))
      ) : (
        <p className={descriptionClass}>{description}</p>
      )}
    </>
  );

  return (
    <div className={classNames(wrapperClassname)}>
      <h5>{title}</h5>
      {renderSectionFieldContent(description)}
    </div>
  );
};

export default DetailSectionField;
