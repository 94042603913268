import { getRotaryYear } from '@utils/datetime';

import {
  AccessLevel,
  // GetAccessLevelsQuery,
} from '@domui-use-cases/districts/conferences/type';

export type GetAccessLevelsQuery = {
  data: Array<{
    level: AccessLevel;
    targetTermYear?: string | null | undefined;
  }>;
};

export const getManagementYear = (accessLevelData?: GetAccessLevelsQuery) =>
  accessLevelData?.data.find(({ level }) => level === AccessLevel.Manager)
    ?.targetTermYear;

export const getFutureManagementYear = (
  accessLevelData?: GetAccessLevelsQuery
) =>
  accessLevelData?.data.find(
    ({ level, targetTermYear }) =>
      level === AccessLevel.Manager &&
      targetTermYear === `${Number(getRotaryYear()) + 1}`
  )?.targetTermYear;
