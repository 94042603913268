// Types
import { ClubStatus, FetchAllDistrictClubsQuery } from './types';

export const hasActiveAgClubs = (
  clubId: string,
  districtClubsData?: FetchAllDistrictClubsQuery
) => {
  return districtClubsData?.Clubs.some(
    ({ id, status }) => clubId === id && status !== ClubStatus.Terminated
  );
};
