// Libs
import React from 'react';

import { useFormikContext } from 'formik';

// Components
import Select from '@components/Formik/Select';
import Loading from '@components/Loading';

// Utils
import { getSortedCountries } from '@domui-utils/getSortedCountries';

// Types
import { Country } from './types';

interface Props {
  countriesData: Country[];
  name: string;
  stateName: string;
  hasStatesName: string;
  internationalProvinceName: string;
  countriesLoading: boolean;
  countryLabel?: string;
  countryPlaceholder?: string;
  clearable?: boolean;
}

const CountrySelect: React.FC<Props> = ({
  name,
  stateName,
  hasStatesName,
  internationalProvinceName,
  countriesData,
  countriesLoading,
  countryLabel,
  countryPlaceholder,
  clearable,
}) => {
  const { setFieldValue } = useFormikContext();

  if (countriesLoading) {
    return <Loading inline small />;
  }

  const handleChange = (value: string) => {
    setFieldValue(stateName, '');
    setFieldValue(internationalProvinceName, '');
    if (value) {
      setFieldValue(name, value);
    } else {
      setFieldValue(hasStatesName, null);
    }
  };

  const generateOptions = (data: Country[]) =>
    data.map(({ countryName, id }) => ({ label: countryName, value: id }));

  return (
    <Select
      name={name}
      onChange={handleChange}
      searchable
      options={generateOptions(getSortedCountries(countriesData))}
      label={countryLabel}
      placeholder={countryPlaceholder}
      clearable={clearable}
    />
  );
};

export default CountrySelect;
