import React from 'react';

import { find } from 'lodash';
import moment from 'moment';

import DetailSectionField from './DetailSectionField';

import { DATES_AND_TIMES } from '@domain/districts';

import DetailSection from '@domui-presenters/web/components/DetailSection';
import { getConferenceHeading } from '@domui-use-cases/districts/conferences';

import { useTranslation } from '@external/react-i18next';

export type GetTimeZonesQuery = {
  id: string;
  value: string;
  timeZoneName: string;
  location: string;
  offset: string;
};

interface Props {
  timeZone?: string | null;
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  timeZones?: GetTimeZonesQuery;
}

interface TimeZone {
  id: string;
  value: string;
  timeZoneName: string;
  location: string;
  offset: string;
}

const DatesAndTimesSection: React.FC<Props> = ({
  startDate,
  startTime,
  endDate,
  endTime,
  timeZone,
  timeZones,
}) => {
  const { t } = useTranslation();

  const foundTimeZone = find(timeZones, ['timeZoneName', timeZone]);
  const timeZoneAbbr = (foundTimeZone as TimeZone | undefined)?.value || '';

  return startDate || startTime || endDate || endTime ? (
    <DetailSection title={getConferenceHeading(t, DATES_AND_TIMES)}>
      <div className="mb-7">
        {startDate && (
          <DetailSectionField
            title={t('conference-details.start-date', 'Start Date')}
            description={moment(startDate).format('DD MMM YYYY')}
            wrapperClassname="mb-0 mr-9 inline-block"
            descriptionClassname="mb-0"
          />
        )}
        {startTime && (
          <DetailSectionField
            title={t('conference-details.start-time', 'Start Time')}
            description={`${startTime} ${timeZoneAbbr}`}
            wrapperClassname="inline-block"
            descriptionClassname="mb-0"
          />
        )}
      </div>

      <div>
        {endDate && (
          <DetailSectionField
            title={t('conference-details.end-date', 'End Date')}
            description={moment(endDate).format('DD MMM YYYY')}
            wrapperClassname="mb-0 mr-9 inline-block"
            descriptionClassname="mb-0"
          />
        )}
        {endTime && (
          <DetailSectionField
            title={t('conference-details.end-time', 'End Time')}
            description={`${endTime} ${timeZoneAbbr}`}
            wrapperClassname="inline-block"
            descriptionClassname="mb-0"
          />
        )}
      </div>
    </DetailSection>
  ) : null;
};

export default DatesAndTimesSection;
