/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';

import CollapsibleHeader from '@components/CollapsibleHeader/';
import { ResultsListItem } from '@components/ResultsList';

import DistrictOfficerRoleItem from './DistrictOfficerRoleItem';

import { isRoleAssignedInFutureRY } from '@use-cases/districts';

import { OFFICERS_ROLES } from '@domui-domain/districts/constants';
import {
  getActiveDO,
  sortItemsByRequiredOrder,
} from '@domui-use-cases/districts';

const DistrictOfficerRolesList: React.FC<any> = ({
  title,
  notice,
  leadershipData,
  roles,
  officers,
  assignedFuture3yrTermRoleIds,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const isDistrictOfficerRoles = !!leadershipData;

  const totalCount = isDistrictOfficerRoles ? officers?.length : roles?.length;

  const filteredDistrictOfficers = isDistrictOfficerRoles
    ? officers?.filter(
        (individual: { districtLeadership: { roleId: string }[] }) =>
          individual.districtLeadership.some((leadership: { roleId: string }) =>
            sortItemsByRequiredOrder(
              getActiveDO(leadershipData),
              OFFICERS_ROLES,
              'role'
            ).some(
              (role: { roleId: string }) => role.roleId === leadership.roleId
            )
          )
      )
    : [];

  return (
    <div className="mt-10 first:mt-2">
      <CollapsibleHeader
        isOpen={isOpen}
        title={title}
        notice={notice}
        count={totalCount}
        toggleCollapse={() => setIsOpen(!isOpen)}
      />
      <Collapse
        isOpen={isOpen}
        className="collapse-css-transition"
        overflowOnExpanded
      >
        <ul>
          {/* Render District Officer Roles */}
          {isDistrictOfficerRoles &&
            filteredDistrictOfficers?.map(
              ({
                id,
                prefix,
                firstName,
                middleName,
                lastName,
                suffix,
                districtLeadership,
                primaryEmail,
                photoUri,
                primaryPhone,
                membership,
              }: any) => (
                <ResultsListItem key={id} columnLayout>
                  <DistrictOfficerRoleItem
                    prefix={prefix}
                    firstName={firstName}
                    middleName={middleName}
                    lastName={lastName}
                    suffix={suffix}
                    role={districtLeadership}
                    districtLeadership={districtLeadership}
                    membership={membership}
                    primaryEmail={primaryEmail}
                    primaryPhone={primaryPhone}
                    photoUri={photoUri}
                    assignedTerm={districtLeadership as any}
                    isAssignedInFutureRY={isRoleAssignedInFutureRY(
                      assignedFuture3yrTermRoleIds || [],
                      id
                    )}
                  />
                </ResultsListItem>
              )
            )}

          {/* Render Committee Appointments */}
          {!isDistrictOfficerRoles &&
            roles?.map(
              (appointment: {
                roleId: string;
                role: string;
                assignedMember: any;
              }) => {
                const { roleId, role, assignedMember } = appointment;
                return (
                  <ResultsListItem key={roleId} columnLayout>
                    {assignedMember ? (
                      <DistrictOfficerRoleItem
                        role={{ roleId, role }}
                        districtLeadership={
                          assignedMember ? [assignedMember] : []
                        }
                        assignedTerm={assignedMember ? appointment : undefined}
                        prefix={assignedMember?.prefix}
                        firstName={assignedMember?.firstName || ''}
                        middleName={assignedMember?.middleName}
                        lastName={assignedMember?.lastName || ''}
                        suffix={assignedMember?.suffix}
                        membership={assignedMember?.membership}
                        primaryEmail={assignedMember?.primaryEmail || ''}
                        primaryPhone={assignedMember?.primaryPhone || ''}
                        photoUri={assignedMember?.photoUri || ''}
                        isAssignedInFutureRY={isRoleAssignedInFutureRY(
                          assignedFuture3yrTermRoleIds || [],
                          appointment.roleId
                        )}
                      />
                    ) : (
                      <DistrictOfficerRoleItem
                        role={{ roleId, role }}
                        districtLeadership={[]}
                        unassignedTerm={appointment}
                        prefix={assignedMember?.prefix}
                        firstName={assignedMember?.firstName || ''}
                        middleName={assignedMember?.middleName}
                        lastName={assignedMember?.lastName || ''}
                        suffix={assignedMember?.suffix}
                        primaryEmail={assignedMember?.primaryEmail || ''}
                        primaryPhone={assignedMember?.primaryPhone || ''}
                        photoUri={assignedMember?.photoUri || ''}
                        isAssignedInFutureRY={isRoleAssignedInFutureRY(
                          assignedFuture3yrTermRoleIds || [],
                          appointment.roleId
                        )}
                      />
                    )}
                  </ResultsListItem>
                );
              }
            )}
        </ul>
      </Collapse>
    </div>
  );
};

export default DistrictOfficerRolesList;
