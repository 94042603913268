import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import { GetAllDistrictAGsByDateQuery } from '@domui-use-cases/districts/normalizers/types';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const usePrevYearAssistantDistrictGovernorsWithAssignedClubs = () => {
  const [data, setData] = useState<GetAllDistrictAGsByDateQuery>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  type assistantDGPrevYear = {
    organizationId: string;
    rotaryYear: string;
    isPaginated: boolean;
    page: number;
    pageSize: number;
  };

  const prevYearAssistantDistrictGovernorsWithAssignedClubs = async (
    requestData: assistantDGPrevYear
  ) => {
    try {
      setLoading(true);
      setError(null);
      const wfService = new Member();
      const response = await wfService.PrevYearAssistantDistrictGovernorsWithAssignedClubs(
        requestData
      );
      setData(response);
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    prevYearAssistantDistrictGovernorsWithAssignedClubs,
  };
};
