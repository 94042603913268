import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import SelectRotaryYear from '@components/Forms/Select/SelectRotaryYear';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Heading from '@presenters/web/components/DistrictSelectMemberStep/Heading';
import {
  // ASSISTANT_GOVERNOR,
  CommitteeAppointment,
  CommitteeMemberData,
  DistrictMember,
  isAG,
  // MEMBERSHIP_TYPE_MEMBER,
  Step,
  TERM_ALL,
} from '@domui-domain/districts';
import {
  DistrictContext,
  // getClubIdFilterOptions,
  getPreviousAGTermSelectOptions,
  getPriorToLatestManagedYear,
  //  getValuesFromOptions,
  isDateBetween,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useLocalStorage } from '@repositories/storage/hooks';
import { getYearsRange } from '@domui-utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';
import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import Filters from '@domui-presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import { getMemberTypesOptions } from '@domui-presenters/web/pages/Districts/filters/getFiltersOptions';
import MemberItem from '@domui-presenters/web/pages/Districts/ManageAssistantGovernors/SelectMemberStep/MemberItem';
import { processMembers } from '@domui-presenters/web/pages/Districts/Members/DistrictMembers';
import MembersList from '@domui-presenters/web/pages/Districts/Members/MembersList';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import { getBackUrl } from '@domui-use-cases/districts/helpers/committeeUrlManager';
import { useClubOptions } from '@domui-use-cases/districts/hooks/useClubOptions';
import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';
import { useDistrictOptions } from '@domui-use-cases/districts/hooks/useDistrictOptions';
import { useFetchPreviousOfficers } from '@domui-hooks/useFetchPreviousOfficers';
import { useTranslation } from '@external/react-i18next';
import { constructFullName } from '@utils/constructFullName';
import { useErrorHandling } from '@use-cases/notifications';

interface PreviousOfficer {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  prefix?: string;
  suffix?: string;
  localizedName?: string;
  photoUri?: string;
  primaryEmail?: { email: string };
  primaryPhone?: { phone: string };
  nameWithPrefixSuffix?: string;
  leadershipsFromThisClub?: thisDistrictLeadership[];
  membershipsFromThisClub?: activeMemberships[];
  associatedClubsInfo?: associatedClubsInfo[];
}

type thisDistrictLeadership = {
  roleId: string;
  id: string;
  startDate: string;
  endDate: string;
  role: string;
};

type activeMemberships = {
  type?: string;
  admissionDate?: string;
  terminationDate?: string;
};

type associatedClubsInfo = {
  clubId?: string;
  clubName?: string;
  clubType?: string;
  physicalLocation?: physicalLocation;
  __typename?: string;
};

type physicalLocation = {
  country?: string;
  city?: string;
  state?: string;
  internationalProvince?: string;
  __typename?: string;
};

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  stepCount?: number;
  isReplace?: boolean;
};

const PAGE_SIZE = 10;

const TAB_DISTRICT_MEMBERS = 'District Members';
const TAB_PREVIOUS_AG = 'Previous Assistant Governor';

const SelectMemberStep: React.FC<Props> = ({
  districtId,
  riDistrictId,
  stepCount = 3,
  isReplace = false,
}) => {
  const { t } = useTranslation();
  const [
    {
      operationType,
      selectedInfo: { role, term, unassignFrom },
      step,
    },
    setContext,
  ] = useContext(DistrictContext);

  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_DISTRICT_MEMBERS,
    scrollTo: 0,
  });

  const { clearStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    selectedValues: searchValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId);

  const [prevTerm, setPrevTerm] = useState<string>(TERM_ALL);

  const {
    options: districtClubsData,
    // loading1: districtClubsLoading,
  } = useClubOptions(districtId);
  const { options: districtOptions } = useDistrictOptions(districtId);

  const {
    data: previousAGData,
    loading: previousAGDataLoading,
    error,
    fetchPreviousOfficers: fetchPreviousDES,
  } = useFetchPreviousOfficers();

  const priorToLatestManagedYear = getPriorToLatestManagedYear(term?.startDate);

  const selectedTermValues =
    prevTerm === TERM_ALL
      ? getYearsRange(priorToLatestManagedYear, 5)
      : [Number(prevTerm)];

  const isEndDateInTerm = (endDate: string) =>
    selectedTermValues.some(year =>
      isDateBetween(
        endDate,
        { start: `${year - 1}-07-01`, end: `${year}-06-30` },
        null
      )
    );

  const handleBack = () => {
    if (step !== 1) {
      setContext(prevState => ({
        ...prevState,
        step: (step - 1) as Step,
      }));
      window.scrollTo(0, 0);
      return;
    }
    clearStorage();
    localizedNavigate(getBackUrl(operationType, districtId));
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: handleBack,
  });

  const {
    districtMembersSearchData: data,
    loading,
  } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    searchValues
  );

  useEffect(() => {
    if (districtId && riDistrictId && prevTerm) {
      const previousOfficersPayload = {
        roleId: role?.id,
        clubId: districtId,
      };
      fetchPreviousDES(currentPage, PAGE_SIZE, previousOfficersPayload);
    }
  }, [districtId, riDistrictId, currentPage, prevTerm, searchValues, role]);

  useErrorHandling(error?.message, !!error, 'Fetch-PreviousDES.error');

  const handleSelectAssignee = (
    assignee: DistrictMember | CommitteeMemberData
  ) => {
    setContext(prevState => {
      const newState = {
        ...prevState,
        step: (prevState.step + 1) as Step,
        selectedInfo: {
          ...prevState.selectedInfo,
          assignee,
        },
      };
      return newState;
    });
    window.scrollTo(0, 0);
  };

  const searchAGsByDate = (dateParam: string): void => {
    setPrevTerm(dateParam);
    setCurrentPage(1);
  };

  const handleChangeTab = (tab: SetStateAction<TabInfo>) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };
  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  if (loading || previousAGDataLoading) {
    return <Loading />;
  }
  if (!data || !data?.districtMembersDetails) {
    return null;
  }
  const memberTypesOptions = getMemberTypesOptions(t);

  const totalCount = data?.totalCount || 0;
  const total = `(${totalCount})`;
  const multiSelectOptions = {
    clubs: districtClubsData,
    memberTypes: memberTypesOptions,
    districtRoles: districtOptions,
  };

  const members = data?.districtMembersDetails;

  const processedMembers = processMembers(members);

  const membersTab = {
    name: TAB_DISTRICT_MEMBERS,
    title: `${t(
      'district-management.district-members',
      'District members'
    )} (${total || 0})`,
    component: (
      <MembersList
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={totalCount || 0}
        members={(processedMembers as DistrictMember[]) || []}
        memberItem={member => (
          <MemberItem member={member} selectHandler={handleSelectAssignee} />
        )}
        areTabsMultiple
        filters={
          <Filters
            multiSelectOptions={multiSelectOptions}
            initialFilterValues={searchValues}
            applyFilter={handleApplyFilters}
            resetFilter={handleResetFilters}
            filtersListToShow={[MEMBER_FILTERS.CLUB_NAMES]}
          />
        }
        showFiltersInContextMenu
        headerClassName="-m-2 px-14"
      />
    ),
  };

  const previousOfficersData =
    previousAGData?.PreviousOfficers?.map((officer: PreviousOfficer) => ({
      id: officer.id,
      name: constructFullName({
        prefix: officer.prefix,
        firstName: officer.firstName,
        middleName: officer.middleName,
        lastName: officer.lastName,
        suffix: officer.suffix,
      }),
      localizedName: officer.localizedName,
      email: officer.primaryEmail?.email,
      photoUri: officer.photoUri,
      phoneNumber: officer.primaryPhone?.phone,
      nameWithPrefixSuffix: constructFullName({
        prefix: officer.prefix,
        firstName: officer.firstName,
        middleName: officer.middleName,
        lastName: officer.lastName,
        suffix: officer.suffix,
      }),
      activeMemberships:
        officer.membershipsFromThisClub?.map(
          (membership: activeMemberships) => ({
            type: membership.type,
            admissionDate: membership.admissionDate,
            terminationDate: membership.terminationDate,
          })
        ) || [],
      thisDistrictLeadership:
        officer.leadershipsFromThisClub?.map(
          (leadership: thisDistrictLeadership) => ({
            id: leadership.id,
            roleId: role?.id,
            role: leadership.role,
            startDate: leadership.startDate,
            endDate: leadership.endDate,
          })
        ) || [],
      associatedClubsInfo:
        officer.associatedClubsInfo?.map(
          (associatedClubsInfo: associatedClubsInfo) => ({
            clubId: associatedClubsInfo?.clubId || '',
            clubName: associatedClubsInfo?.clubName || '',
            clubType: associatedClubsInfo?.clubType || '',
            physicalLocation: associatedClubsInfo?.physicalLocation || '',
            __typename: associatedClubsInfo?.__typename || '',
          })
        ) || [],
    })) || [];

  const previousAGList = previousOfficersData as DistrictMember[];

  const previousAGsCount = previousAGData?.totalCount || 0;

  const previousAGsTab = {
    name: TAB_PREVIOUS_AG,
    title: `${t(
      'district-management.district-previous-ag',
      'Previous Assistant Governors'
    )} (${previousAGsCount})`,
    component: (
      <MembersList
        filters={
          <SelectRotaryYear
            initialValue={prevTerm}
            options={getPreviousAGTermSelectOptions(
              priorToLatestManagedYear,
              t
            )}
            handleChange={searchAGsByDate}
          />
        }
        showFiltersInContextMenu
        totalCount={previousAGsCount}
        headerClassName="-m-2 px-14"
        areTabsMultiple
        members={previousAGList}
        memberItem={member => (
          <MemberItem
            member={{
              ...member,
              thisDistrictLeadership: member.thisDistrictLeadership.filter(
                ({ roleId, endDate }) =>
                  isAG(roleId) && isEndDateInTerm(endDate)
              ),
            }}
            originalDistrictLeadership={member.thisDistrictLeadership}
            key={member.id}
            selectHandler={handleSelectAssignee}
            isPreviousAG
          />
        )}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
      />
    ),
  };

  const tabs = [membersTab, previousAGsTab];
  const { id, photoUri, nameWithPrefixSuffix } = unassignFrom || {};

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      backHandler={handleBack}
      step={step}
      total={stepCount}
      navClassName="mt-6 text-small font-bold"
    >
      <Heading
        roleTitle={role?.name || ''}
        title={
          isReplace
            ? t('select-member-to-replace.title', 'Select a Member to Replace')
            : t('select-member-to-add.title', 'Select a Member to Add')
        }
        isSubTitleHidden={!isReplace}
        subTitle={
          isReplace ? (
            <IndividualListColumn
              fullName={nameWithPrefixSuffix || ''}
              photoUri={photoUri || ''}
              id={id || ''}
              associatedClubsInfo={[]}
            />
          ) : null
        }
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={handleChangeTab}
      />
    </PageSteps>
  );
};

export default SelectMemberStep;
