export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddressOptions = {
  venueName?: string;
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  country?: string;
  city?: string;
  state?: string;
  hasStates?: string;
  postalCode?: string;
  internationalProvince?: string;
};

export type AddressLabels = {
  venueNameLabel?: string;
  lineOneLabel?: string;
  lineTwoLabel?: string;
  lineThreeLabel?: string;
  countryLabel?: string;
  cityLabel?: string;
  stateLabel?: string;
  postalCodeLabel?: string;
};

export type PhoneLabels = {
  countryId?: string;
  number?: string;
};

export type AddressPlaceholder = {
  venueNamePlaceholder?: string;
  lineOnePlaceholder?: string;
  lineTwoPlaceholder?: string;
  lineThreePlaceholder?: string;
  countryPlaceholder?: string;
  cityPlaceholder?: string;
  statePlaceholder?: string;
  postalCodePlaceholder?: string;
  internationalProvincePlaceholder?: string;
};

export type DateTypeOptions = {
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
};

export type DateTypeLabels = {
  startDateLabel?: string;
  startTimeLabel?: string;
  endDateLabel?: string;
  endTimeLabel?: string;
};

export type DateTypePlaceholders = {
  startDatePlaceholder?: string;
  startTimePlaceholder?: string;
  endDatePlaceholder?: string;
  endTimePlaceholder?: string;
};

export type RemoteInfoLabels = {
  urlLabel?: string;
  detailsForJoiningRemotelyLabel?: string;
};

export type RemoteInfoPlaceholders = {
  urlPlaceholder?: string;
  detailsForJoiningRemotelyPlaceholder?: string;
};

export type RemoteInfoFields = {
  url: string;
  detailsForJoiningRemotely: string;
};

export type ParticipantOption = {
  id: string;
  value: number;
};

export type ParticipantDistrictType = {
  id: string;
  riDistrictId: number;
  name: string;
  status: string;
  nfKey: string;
};

export type TimeZone = {
  id: string;
  value: string;
  timeZoneName: string;
  location: string;
  offset: string;
};

export type State = {
  statePk: string;
  code: string;
  stateName: string;
  countryCode: string;
};

export type Country = {
  id: string;
  shortCode: string;
  countryName: string;
  RequireState: boolean;
};

export type PhoneInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DateTime = {
  startDate: string | Date | null;
  startTime: string | null;
  endDate: string | Date | null;
  endTime: string | null;
  timeZone: string | null;
};

export type InputConferenceAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ParticipantDistrict = {
  id: string;
  districtId: string;
  nfKey: string;
  isPrimary: boolean;
};

export type CreateConferenceFormResult = {
  districtId: string;
  venueType: string;
  dateTime: DateTime;
  conferenceAddress: InputConferenceAddress;
  venueName: string | null;
  language: string | null;
  participatingDistricts: ParticipantOption[] | null;
  onlineLocation: string;
  detailForJoiningRemotely: string;
  isPresRepRequested?: Maybe<boolean>;
  prAddress: InputConferenceAddress;
  prAccommodation: string;
  nfKey: string;
  rotaryYear: string | null;
  locationCityState: string;
  locationCountry: string;
  locationNameEng: string;
  locationCityStateEng: string;
  presRepresentativeHotel: string;
  presRepresentativeHotelAddress: string;
  presRespresntativeHotelPhone: string;
  prComment: string;
  govComment: string;
  locationNameOrDetail: string;
  participatingDistrict: ParticipantDistrict | null;
  presidentialRepresentativeId: string;
  // comments: string | null;
  accomodationPhone: PhoneInput;
};

export enum ConferenceType {
  Physical = 'Physical',
  Online = 'Online',
  Hybrid = 'Hybrid',
}
