// Libs
import React, { useContext } from 'react';

// Components
import ConfirmDetails from './ConfirmDetails';

// Types
import { OperationType } from '@domui-domain/districts';

import { DistrictContext } from '@domui-use-cases/districts';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  districtId: string;
  riDistrictId?: number | null;
};

const Step2: React.FC<Props> = ({ riDistrictId }) => {
  const [context] = useContext(DistrictContext);
  const { operationType } = context;

  if (
    [
      OperationType.ASSIGN_TO_AG,
      OperationType.REPLACE_AG,
      OperationType.REASSIGN_TO_AG,
    ].includes(operationType as OperationType)
  ) {
    return <ConfirmDetails riDistrictId={riDistrictId} />;
  }
  return null;
};

export default Step2;
