import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import { FetchAllDistrictClubsQuery } from '@domui-use-cases/districts/normalizers/types';
import Member from '@domui-utils/workflow/member';
// Custom hook to handle API calls

export const useAllActiveClubsByDistrictAndRotaryYearrequestData = () => {
  const [data, setData] = useState<FetchAllDistrictClubsQuery>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  type assistantDGAllActive = {
    organizationId: string;
    rotaryYear: string;
  };

  const fetchAllActiveClubsByDistrictAndRotaryYearrequestData = async (
    requestData: assistantDGAllActive
  ) => {
    try {
      setLoading(true);
      setError(null);
      const wfService = new Member();
      const response = await wfService.AllActiveClubsByDistrictAndRotaryYear(
        requestData
      );
      setData(response);
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    fetchAllActiveClubsByDistrictAndRotaryYearrequestData,
  };
};
