export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum ClubStatus {
  Active = 'Active',
  Terminated = 'Terminated',
  Suspended = 'Suspended',
}

export enum WeekdayFull {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

export enum ClubType {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}

export type FetchAllDistrictClubsQuery = {
  TotalCount: number;
  Clubs: Array<{
    id: string;
    riClubId: string;
    clubName: string;
    clubType: ClubType;
    status?: string | null | undefined;
    country?: string | null | undefined;
    state?: string | null | undefined;
    countryId: string;
    city?: string | null | undefined;
    stateId?: string | null | undefined;
    internationalProvince?: string | null | undefined;
  }>;
};

export type DistrictData = {
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type LocationData = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export type BaseAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export enum MeetingType {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical',
}

export type Meeting = {
  address?: Maybe<BaseAddress>;
  comment?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  languageId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  locationVaries: Scalars['Boolean'];
  longitude?: Maybe<Scalars['Float']>;
  meetingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  type: MeetingType;
  weekday: WeekdayFull;
};

export type DistrictClub = {
  activeMembersCount?: Maybe<Scalars['Int']>;
  district?: Maybe<DistrictData>;
  governor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  meetings?: Maybe<Array<Maybe<Meeting>>>;
  clubName: Scalars['String'];
  riClubId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  clubType: ClubType;
};

export type GetAllDistrictAGsByDateQuery = {
  totalCount: number;
  AssistantGovernors: Array<
    | {
        individualId: string;
        firstName: string;
        lastName: string;
        middleName?: string | null | undefined;
        prefix?: string | null | undefined;
        suffix?: string | null | undefined;
        localizedName?: string | null | undefined;
        clubAffiliations?:
          | Array<
              | {
                  clubId: string;
                  districtMappingId: string;
                  clubGroupingMappingId: string;
                  clubType: string;
                  clubName: string;
                  startDate: string;
                  endDate: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};
