import { useState } from 'react';

import { InputCreateConference } from '@domui-domain/districts/mappers/types';
import { DomuiError } from '@domui-domain/type';
import { useUpdateAdditionalParticipatingDistricts } from '@domui-hooks/conference/useUpdateAdditionalParticipatingDistricts';
import Conference from '@domui-utils/workflow/conference';

type ParticipatingDistrictsRequest = {
  id?: string | null;
  districtId: string;
  isPrimary: boolean;
  action: string;
};

type AdditionalParticipatingDistrictsType = {
  conferenceAdditionalParticipatingDistrictPk: string;
  conferencePk: string;
  organizationPk: string;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  status: string;
  nfKey: string;
  isPrimary: boolean;
};

type SelectParticipatingDistrictsType = {
  id: string;
  riDistrictId: string;
};

// Custom hook to handle API calls
export const useUpdateConference = () => {
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);

  function compareparticipatingDistricts(
    additionalParticipatingDistricts: AdditionalParticipatingDistrictsType[],
    selectParticipatingDistricts: SelectParticipatingDistrictsType[]
  ): ParticipatingDistrictsRequest[] {
    const selectedDistricts: ParticipatingDistrictsRequest[] = [];

    // Find Add actions
    selectParticipatingDistricts.forEach(participatingDistrict => {
      const districtExists = additionalParticipatingDistricts.some(
        additionalParticipatingDistrict =>
          additionalParticipatingDistrict.organizationPk ===
          participatingDistrict.id
      );
      if (!districtExists) {
        selectedDistricts.push({
          districtId: participatingDistrict.id,
          action: 'Add',
          isPrimary: false,
        });
      }
    });

    // Find Delete actions
    additionalParticipatingDistricts.forEach(
      additionalParticipatingDistrict => {
        const districtExists = selectParticipatingDistricts.some(
          participatingDistrict =>
            participatingDistrict.id ===
            additionalParticipatingDistrict.organizationPk
        );
        if (!districtExists) {
          if (additionalParticipatingDistrict.isPrimary === false) {
            selectedDistricts.push({
              id:
                additionalParticipatingDistrict.conferenceAdditionalParticipatingDistrictPk,
              districtId: additionalParticipatingDistrict.organizationPk,
              action: 'Delete',
              isPrimary: false,
            });
          }
        }
      }
    );

    return selectedDistricts;
  }

  const {
    selectUpdateAdditionalParticipatingDistricts,
  } = useUpdateAdditionalParticipatingDistricts();

  const updateConference = async (
    conferenceId: string,
    requestBody: InputCreateConference,
    additionalParticipatingDistricts: AdditionalParticipatingDistrictsType[]
  ) => {
    try {
      setLoading(true);
      setError(null);
      if (conferenceId) {
        if (
          additionalParticipatingDistricts?.length === 0 &&
          requestBody?.participatingDistricts?.length === 0
        ) {
          const newRequestBody = { ...requestBody };
          delete newRequestBody?.participatingDistricts;
          const wfService = new Conference();
          await wfService.updateConference(conferenceId, newRequestBody);
          setStatus(true);
          setLoading(false);
        } else {
          const { participatingDistricts } = requestBody;

          const getParticipatingDistricts = compareparticipatingDistricts(
            additionalParticipatingDistricts,
            (participatingDistricts as unknown) as SelectParticipatingDistrictsType[]
          );

          if (getParticipatingDistricts.length === 0) {
            const newRequestBody = { ...requestBody };
            delete newRequestBody?.participatingDistricts;

            const wfService = new Conference();
            await wfService.updateConference(conferenceId, newRequestBody);

            setStatus(true);
            setLoading(false);
          } else {
            const selectedDistricts = {
              participatingDistricts: getParticipatingDistricts,
            };

            const participatingDistrictsPayload = {
              conferenceId,
              selectedDistricts,
            };

            const newRequestBody = { ...requestBody };
            delete newRequestBody?.participatingDistricts;

            const wfService = new Conference();
            await wfService.updateConference(conferenceId, newRequestBody);
            await selectUpdateAdditionalParticipatingDistricts(
              participatingDistrictsPayload
            );
            setStatus(true);
            setLoading(false);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setError(error as DomuiError);
      setStatus(false);
      setLoading(false);
    } finally {
      setStatus(false);
      setLoading(false);
    }
  };

  return { status, loading, error, updateConference };
};
