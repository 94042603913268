/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { isEqual } from 'lodash';

// import { Option } from '@components/Formik/MultiSelectFilter/types';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { titleTemplateParams } from '@components/Title/util';

import ClubsList from './ClubsList';
import SearchClubsForm from './SearchClubsForm';

import { CLUB_FILTERS } from '@domain/districts';

import { useDistrictClubsSearch } from '@domui-hooks/useDistrictClubsSearch';
import {
  getClubTypesOptions,
  getDefaultFilters,
  // getValuesFromOptions,
  getDistrictIdFromPath,
} from '@domui-use-cases/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { useAppConfig } from '@hooks/appConfig';

import { FilterSearchFormValues as SearchValues } from './SearchClubsForm/types';

export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

type DistrictClub = {
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  nfKey: Scalars['String'];
  activeMembers?: Maybe<Scalars['Int']>;
  assistantGovernor?: Maybe<Scalars['String']>;
};

interface Option {
  id: string;
  value: string;
  label: string;
}

export type ClubFilter = {
  [x: string]: any;
  clubTypes: Option[];
};

interface Props extends RouteComponentProps {
  // orgId: string;
  // riDistrictId?: number | null;
}

const PAGE_SIZE = 10;

const INITIAL_FILTERS = {
  clubTypes: [],
};
const INITIAL_SEARCH = {
  name: '',
  location: '',
};

const DistrictClubs: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const orgId = getDistrictIdFromPath(location?.pathname);
  // const { user } = useAppConfig();

  const multiSelectOptions = {
    clubTypes: getClubTypesOptions(t),
  };

  // Set predefined filter value to default object
  const defaultFilters = getDefaultFilters(
    INITIAL_FILTERS,
    multiSelectOptions.clubTypes
  ) as ClubFilter;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<ClubFilter>(defaultFilters);
  const [search, setSearch] = useState<SearchValues>(INITIAL_SEARCH);

  const {
    data: districtClubSearchData,
    loading: isSearching,
    // error,
    districtClubsSearch,
  } = useDistrictClubsSearch();

  const getClubTypes = (value: any) => {
    if (value.length > 0) {
      return filter?.clubTypes?.map((item: any) => item.value);
    }
    return [];
  };

  useEffect(() => {
    // const fetchData = () => {
    if (orgId) {
      districtClubsSearch(currentPage, PAGE_SIZE, {
        orgId,
        filters: {
          name: search?.name,
          location: search?.location,
          clubTypes: getClubTypes(filter?.clubTypes),
        },
      });
    }
    // };
    // fetchData();
  }, [orgId, search, filter]);

  const filteredList = districtClubSearchData as DistrictClub[];
  const filteredCount = districtClubSearchData?.totalCount;

  if (isSearching || !districtClubSearchData) {
    return <Loading />;
  }

  const handleSelectPage = (event: React.SyntheticEvent, page: number) => {
    event.preventDefault();
    districtClubsSearch(page, PAGE_SIZE, {
      orgId,
      filters: {
        name: search?.name,
        location: search?.location,
        clubTypes: getClubTypes(filter?.clubTypes),
      },
    });
    setCurrentPage(page);
  };

  // const isPaginationActive = filteredList.length > 0 && !isSearching;

  const applyFilter = (values: Option[], name: CLUB_FILTERS) => {
    const updatedFilters = {
      ...filter,
      [name]: values,
    };

    if (!isEqual(filter, updatedFilters)) {
      setCurrentPage(1);
      setFilter({
        ...filter,
        [name]: values,
      });
    }
  };

  const resetFilter = (filterName?: string): void => {
    setCurrentPage(1);

    if (
      filterName &&
      Object.values(CLUB_FILTERS).includes(filterName as CLUB_FILTERS)
    ) {
      setFilter({ ...filter, [filterName]: [] });
    } else {
      setFilter(INITIAL_FILTERS);
    }
  };

  const handleClubSearch = (values: SearchValues) => {
    setCurrentPage(1);
    setSearch(values);
    resetFilter();
  };

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-clubs',
          '{{prefix}} District clubs {{suffix}}',
          {
            prefix,
            suffix,
          }
        )}
      />
      <SearchClubsForm
        loading={isSearching}
        onSubmit={handleClubSearch}
        initialValues={{
          ...search,
        }}
      />
      <ClubsList
        clubsList={filteredList}
        totalCount={filteredCount}
        isLoading={isSearching}
        filterProps={{
          initialFilterValues: { ...filter },
          multiSelectOptions: { ...multiSelectOptions },
          resetFilter,
          applyFilter,
        }}
        showFiltersInContextMenu
        contextMenuTitle={t('district.filters.filter-clubs', 'Filter Clubs')}
      />
      {/* {isPaginationActive && ( */}
      <Pagination
        pageSize={PAGE_SIZE}
        page={currentPage}
        totalCount={filteredCount}
        pageHandler={handleSelectPage}
      />
      {/* )} */}
    </>
  );
};

export default DistrictClubs;
